<template>
  <div class="btn my-1 py-1 px-2 border rounded text-center" :style="buttonStyle" @click="click">
    <div class="d-flex h-100 align-items-center">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "karnem-button",
  props: {
    text: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    click: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    buttonStyle() {
      const baseStyle = this.active ? this.theme.styles.activeButton : this.theme.styles.button;
      return {
        ...baseStyle,
        wordBreak: "break-all",
        width: "60px"
      };
    }
  }
};
</script>
