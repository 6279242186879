<template>
  <div class="d-flex align-items-center" :style="!legend && { minHeight: '66px' }" ref="progress">
    <div ref="icons" class="d-flex align-self-stretch align-items-center position-relative">
      <img
        :src="studentBlackSvg"
        v-for="(percent, i) in shadowPercents"
        :style="blackStudentStyle(percent)"
        :key="`shadow_${i}`"
      />
      <div
        class="arrows-container"
        v-if="showArrows && type !== EXAM_TYPES.YKS"
        :style="arrowsStyle"
      >
        <span>{{ top }}</span>
        <img src="@/assets/media/icons/arrows.png" />
        <span>%{{ percent.toFixed(2) }}</span>
      </div>
      <template v-if="type === EXAM_TYPES.YKS && !legend">
        <div class="d-flex flex-column align-items-center" :style="yksArrowStyle('ew')">
          <fa icon="caret-up"></fa>
          <span class="yks-percent">E.A. (%{{ yksPercents[0] }})</span>
        </div>
        <div class="d-flex flex-row align-items-center" :style="yksArrowStyle('social')">
          <span class="yks-percent">SÖZ. (%{{ yksPercents[1] }})</span>
          <fa icon="caret-right"></fa>
        </div>
        <div class="d-flex flex-column align-items-center" :style="yksArrowStyle('science')">
          <span class="yks-percent">SAY. (%{{ yksPercents[2] }})</span>
          <fa icon="caret-down"></fa>
        </div>
      </template>
      <img
        class="mr-1"
        v-for="(percent, i) in legend ? 9 : totalIcons"
        :src="icon(percent)"
        :data-percent="percent"
        :key="i"
        :style="iconStyle"
      />
    </div>
    <img
      :src="image"
      alt="school"
      class="img-fluid ml-2"
      :style="schoolIconStyle"
      :width="schoolIconWidth"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chroma from "chroma-js";

import { reFill, getColorForPercentage } from "@/common/utils";
import schoolSvg from "@/assets/media/icons/svg/Karnem/lgs/school.svg";
import yksSchoolSvg from "@/assets/media/icons/svg/Karnem/yks/school.svg";
import studentSvgJson from "@/assets/media/icons/svg/Karnem/lgs/student.svg.json";
import yksStudentSvgJson from "@/assets/media/icons/svg/Karnem/yks/student.svg.json";
import schoolSvgJson from "@/assets/media/icons/svg/Karnem/lgs/school.svg.json";
import studentChairSvgJson from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg.json";
import barsSvg from "@/assets/media/icons/svg/Karnem/lgs/bars.svg";
import studentBlackSvg from "@/assets/media/icons/svg/Karnem/lgs/student-black.svg";
import { EXAM_TYPES, ALLOWED_EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";

export default {
  name: "karnem-progress",
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.STUDENT,
      validator(value) {
        return Object.values(REPORT_VARIANTS).indexOf(value) !== -1;
      }
    },
    top: {
      type: [Number, String],
      default: undefined
    },
    percent: {
      type: Number,
      default: undefined
    },
    yksPercents: {
      type: Array, // [ea, soz, say]
      default: () => []
    },
    shadowPercents: {
      type: Array,
      default: () => []
    },
    legend: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: EXAM_TYPES.SBS,
      validator(value) {
        return ALLOWED_EXAM_TYPES.indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      EXAM_TYPES,
      studentBlackSvg,
      progressContainerWidth: 0,
      iconsContainerWidth: 0,
      isMounted: false
    };
  },
  mounted() {
    this.progressContainerWidth = this.$refs.progress.clientWidth - this.schoolIconWidth;
    this.isMounted = true;
    setTimeout(() => {
      // component mount olur olmaz genişlik hesaplamaya çalışınca 0 dönüyor
      this.iconsContainerWidth = this.$refs.icons.clientWidth;
    }, 750);
  },
  methods: {
    yksArrowStyle(scoreType) {
      let percent = 0;
      let top = 0;
      switch (scoreType) {
        case "ew":
          percent = this.yksPercents[0];
          top = this.variant === REPORT_VARIANTS.STUDENT ? 40 : 30;
          break;
        case "social":
          percent = this.yksPercents[1];
          top = this.variant === REPORT_VARIANTS.STUDENT ? 25 : 17;
          break;
        case "science":
          percent = this.yksPercents[2];
          top = this.variant === REPORT_VARIANTS.STUDENT ? -5 : -10;
          break;
        default:
      }

      percent = this.pixelizePercent(percent);
      return {
        position: "absolute",
        left: `${percent}px`,
        top: `${top}px`
      };
    },
    icon(percent) {
      const color = this.colorForPercentage(percent);
      let iconJson;
      switch (this.type) {
        case EXAM_TYPES.SBS: {
          if (this.variant === REPORT_VARIANTS.STUDENT) {
            iconJson = studentSvgJson;
          } else if (this.variant === REPORT_VARIANTS.CORP) {
            iconJson = schoolSvgJson;
          } else {
            iconJson = studentChairSvgJson;
          }
          break;
        }
        case EXAM_TYPES.TYT:
        case EXAM_TYPES.YKS: {
          if (this.variant === REPORT_VARIANTS.STUDENT) {
            iconJson = yksStudentSvgJson;
          } else if (this.variant === REPORT_VARIANTS.CORP) {
            iconJson = schoolSvgJson;
          } else {
            iconJson = studentChairSvgJson;
          }
          break;
        }
      }

      return reFill(iconJson.svg, color);
    },
    colorForPercentage(percent) {
      let p = percent;
      if (percent < 65 && this.legend) {
        p = 65;
      }
      let color = getColorForPercentage(p / 100);
      return chroma(color).set("lch.c", 70);
    },
    pixelizePercent(n) {
      // İkonlar 0'dan başlayıp 100'a gidiyor. Okula yakın taraf olan 100.
      // Ama kullanıcının yüzdesi ne kadar düşükse okul ikonuna o kadar yakın olması lazım
      // O yüzden gelen yüzde değerini okula yaklaştırmak için 100 den çıkarmamız lazım
      const percent = Math.round(100 - n);
      // user iconlarının dışına taşmasını engellemek için
      return (this.iconsContainerWidth * percent) / 100;
    },
    blackStudentStyle(percent) {
      const leftPx = this.pixelizePercent(percent) + 12; // ekleme olmazsa sola doğru taşıyor
      return {
        height: "20px",
        position: "absolute",
        opacity: 0.3,
        bottom: "3px",
        left: `${leftPx}px`
      };
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    image() {
      switch (this.type) {
        case EXAM_TYPES.SBS:
          return this.variant === REPORT_VARIANTS.STUDENT ? schoolSvg : barsSvg;
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          return this.variant === REPORT_VARIANTS.STUDENT ? yksSchoolSvg : barsSvg;
        default:
          return null;
      }
    },
    showArrows() {
      return !!this.top && this.percent >= 0;
    },
    iconStyle() {
      if (this.legend) {
        return { height: "17px" };
      }

      let h;
      switch (this.type) {
        case EXAM_TYPES.SBS:
          h = 20;
          break;
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          if (this.variant === REPORT_VARIANTS.STUDENT) {
            h = 22;
          } else {
            h = 20;
          }
          break;
        default:
      }

      return {
        height: `${h}px`
      };
    },
    arrowsStyle() {
      if (!this.isMounted) return { left: 0 };

      const leftPx = this.pixelizePercent(this.percent);
      return {
        left: `${leftPx}px`
      };
    },
    totalIcons() {
      let iconWidth;
      if (this.variant === "student") {
        iconWidth = 12;
      } else if (this.variant === "corp") {
        iconWidth = 22;
      } else {
        iconWidth = 17;
      }
      const totalIcons = Math.floor(this.progressContainerWidth / iconWidth);
      return Array(totalIcons)
        .fill(true)
        .map((n, i) => {
          const percent = ((i + 1) * 100) / totalIcons;
          return Math.round(percent);
        });
    },
    schoolIconWidth() {
      return this.legend ? 21 : 40;
    },
    schoolIconStyle() {
      return {
        marginTop: this.legend ? "-4px" : "-16px"
      };
    }
  }
};
</script>

<style lang="scss">
.arrows-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 252px;
  font-family: GothamCondensedBook;
  top: 50%;
  transform: translateY(-50%);
  span {
    line-height: 1;
  }
  img {
    height: 40px;
  }
}
.yks-percent {
  font-weight: bold;
  font-size: 9px !important;
}
</style>
