<template>
  <div class="d-flex flex-row">
    <div class="d-flex flex-row align-items-center mr-2" v-if="isVariant.student">
      <img :src="svgs.student" alt="student" :height="imageHeight" />
      <span :style="textStyle" class="ml-2">Öğrenci Net</span>
    </div>
    <div class="d-flex flex-row align-items-center mr-2" v-if="isVariant.class">
      <img
        src="@/assets/media/icons/svg/Karnem/lgs/student-chair-orange.svg"
        alt="classroom"
        :height="imageHeight"
      />
      <span :style="textStyle" class="ml-2">Sınıf Net Ortalaması</span>
    </div>
    <div class="d-flex flex-row align-items-center mr-2" v-if="isVariant.corp">
      <img
        src="@/assets/media/icons/svg/Karnem/lgs/school-orange.svg"
        alt="corp"
        :height="imageHeight"
      />
      <span :style="textStyle" class="ml-2">Kurum Net Ortalaması</span>
    </div>
    <div class="d-flex flex-row align-items-center mr-2">
      <img :src="svgs.students" alt="students" :height="imageHeight" />
      <span :style="textStyle" class="ml-2">Sınav Net Ortalaması</span>
    </div>
    <div class="d-flex flex-row align-items-center mr-2" v-if="!isVariant.student">
      <img src="@/assets/media/icons/svg/Karnem/lgs/bars.svg" alt="bar" :height="imageHeight" />
      <span :style="textStyle" class="ml-2">Sıralama</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { EXAM_TYPES, ALLOWED_EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";
import sbsStudentSvg from "@/assets/media/icons/svg/Karnem/lgs/student.svg";
import sbsStudentsSvg from "@/assets/media/icons/svg/Karnem/lgs/students.svg";
import yksStudentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import yksStudentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";

export default {
  name: "net-legend",
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.STUDENT,
      validator(value) {
        return Object.values(REPORT_VARIANTS).indexOf(value) !== -1;
      }
    },
    type: {
      type: Number,
      default: EXAM_TYPES.SBS,
      validator(value) {
        return ALLOWED_EXAM_TYPES.indexOf(value) !== -1;
      }
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    isVariant() {
      return {
        student: this.variant === REPORT_VARIANTS.STUDENT,
        corp: this.variant === REPORT_VARIANTS.CORP,
        class: this.variant === REPORT_VARIANTS.CLASS
      };
    },
    imageHeight() {
      return "17px";
    },
    textStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontFamily: "GothamCondensedBook",
        fontSize: "7pt",
        textTransform: "uppercase",
        lineHeight: 1
      };
    },
    svgs() {
      let student;
      let students;

      switch (this.type) {
        case EXAM_TYPES.SBS:
          student = sbsStudentSvg;
          students = sbsStudentsSvg;
          break;
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          student = yksStudentSvg;
          students = yksStudentsSvg;
          break;
        default:
      }

      return { student, students };
    }
  }
};
</script>
