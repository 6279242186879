<template>
  <div class="d-flex flex-column">
    <h3 :style="titleStyles">{{ title }}</h3>
    <Bar v-bind="{ ...$props, title: undefined }"></Bar>
  </div>
</template>
<script>
import Bar from "@/components/charts/bar.mixin.vue";
import RenameLessonMixin from "@/components/mixins/rename-lesson.mixin";

export default {
  mixins: [RenameLessonMixin],
  components: {
    Bar
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    titleStyle: {
      type: Object,
      default: null
    },
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    styles: {
      type: Object,
      default: undefined
    },
    stepSize: {
      type: Number,
      default: 5
    },
    max: {
      type: Number,
      default: 20
    },
    colors: {
      type: Array,
      default: null
    }
  },
  computed: {
    titleStyles() {
      return {
        ...this.titleStyle
      };
    }
  }
};
</script>

<style scoped>
h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: GothamCondensedBook;
  text-align: center;
  margin-bottom: 0;
}
</style>
