<template>
  <BaseReportTemplate hide-logo headerLeftTitle="Başarı İzleme Karnesi">
    <b-row class="mx-0">
      <!-- left -->
      <b-col col md="6" xl="4" class="vertical-border">
        <!-- DoughnutChart -->
        <b-row v-if="mainNetsByLesson.length" class="mx-0 px-3">
          <b-col
            cols="6"
            v-for="(result, i) of mainNetsByLesson"
            :key="i"
            style="margin-bottom:16px"
          >
            <DoughnutChart
              :chart-data="result.chartData()"
              :title="result.title"
              :titleStyle="{ whiteSpace: 'nowrap', textAlign: 'center' }"
              :cutout-percentage="60"
              :sliceFontSize="12"
              :innerFontSize="26"
              :innerFontStyle="'GothamCondensedBook'"
              :styles="{ height: '104px', position: 'relative', marginBottom: '7px' }"
            />
            <NetIcon
              :type="EXAM_TYPES.TYT"
              :student-net="result.studentNet"
              :average-net="result.averageNet"
            />
          </b-col>
        </b-row>
        <p v-else>
          Derslere ait netlerin listelenmesi için yukarıdaki formdan sınav seçimi yapmalısnız
        </p>
        <hr />
        <!-- Table -->
        <p v-if="!netsByLesson.length">
          Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.
        </p>
        <table v-else class="table-front" rules="all">
          <thead>
            <tr>
              <th></th>
              <th>SORU<br />SAYISI</th>
              <th><fa icon="square" :style="{ color: trueColor }"></fa></th>
              <th><fa icon="square" :style="{ color: wrongColor }"></fa></th>
              <th><fa icon="square"></fa></th>
              <th><Icon :src="studentSvg" :height="19"></Icon></th>
              <th><Icon :src="studentsSvg" :height="19"></Icon></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lesson, i) of netsByLesson" :key="i">
              <td>{{ lesson.lesson }}</td>
              <td>{{ lesson.total }}</td>
              <td>{{ lesson.correct }}</td>
              <td>{{ lesson.wrong }}</td>
              <td>{{ lesson.empty }}</td>
              <td>
                <b>{{ lesson.net }}</b>
              </td>
              <td>
                <b>{{ lesson.averageNet }}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="d-md-none" style="margin-top:12px; margin-bottom:12px" />
      </b-col>
      <!-- center -->
      <b-col col md="6" xl="4" class="vertical-border">
        <p v-if="!generalDoughnutModel">Önce sınav seçimi yapın</p>
        <template v-else>
          <DoughnutChart
            :chart-data="generalDoughnutModel.chartData()"
            :title="generalDoughnutModel.title"
            :cutout-percentage="63"
            :sliceFontSize="22.5"
            :innerFontSize="85"
            :stylesOuter="{ marginTop: '5px' }"
            :styles="{ height: '234px', position: 'relative', marginBottom: '26px' }"
          />
          <NetIcon
            variant="large"
            :type="EXAM_TYPES.TYT"
            :student-net="generalDoughnutModel.studentNet"
            :average-net="generalDoughnutModel.averageNet"
          />
          <Jumbotron background>
            <slot>
              <p>Sınavda;</p>
              <p>
                {{ generalDoughnutModel.wrong }} yanlış yaparak<br />
                <b>{{ score }}</b> puan aldın.
              </p>
              <p>
                Eğer, bu yanlışları yapmasaydın <br /><b>{{ futureScore }}</b> puan alabilirdin.
              </p>
              <p>
                <br />
                Unutma ki, milyonların girdiği bir sınavda binde birlik puanla 1000 kişiyi
                geçebilirsin.
              </p>
            </slot>
          </Jumbotron>
          <hr class="d-md-none" style="margin-top:25px; margin-bottom:35px" />
        </template>
      </b-col>
      <!-- right -->
      <b-col col xl="4" class="row mx-0">
        <b-col md="6" xl="12" class="px-0">
          <hr class="d-none d-md-block d-xl-none" style="margin-top:40px;margin-bottom:20px" />
          <b-row class="mx-0">
            <b-col>
              <Label
                left="SINAV ADI"
                :right="examName"
                :left-style="styles.labels.common.left"
                :right-style="styles.labels.common.right"
                :style="styles.labels.examName"
                :icon-props="iconProps.examName"
              ></Label>
              <Label
                left="1.OTURUM"
                :right="session"
                :left-style="styles.labels.common.left"
                :right-style="styles.labels.common.right"
                :style="styles.labels.session"
                :icon-props="iconProps.session"
              ></Label>
            </b-col>
            <Icon
              :style="{ marginRight: '15px' }"
              :src="printerSvg"
              :height="styles.printIcon"
            ></Icon>
          </b-row>
          <b-col>
            <Label
              class="w-100"
              :style="styles.labels.scores.container"
              size="large"
              color="orange"
              left="SINAV PUANIM"
              left-position="center"
              :left-style="styles.labels.scores.text"
              :right="score"
              :right-style="styles.labels.scores.text"
              right-position="center"
            ></Label>
            <Label
              :style="styles.labels.scores.container"
              class="w-100"
              size="large"
              color="blue"
              left="Genel Puan Ort."
              left-position="center"
              :left-style="styles.labels.scores.text"
              :right="averageScore"
              :right-style="styles.labels.scores.text"
              right-position="center"
            ></Label>
            <b-row style="margin-top:12px">
              <b-col xs="6" class="d-flex flex-column">
                <Label
                  v-for="(attendance, i) in attendances"
                  :key="`att_${i}`"
                  :left="attendance.left"
                  :right="attendance.right"
                  :left-style="attendance.leftStyle"
                  :right-style="attendance.rightStyle"
                  :style="{ marginBottom: '6px', height: '22.21px' }"
                  :right-position="attendance.rightPosition"
                  :color="attendance.color"
                  :icon-props="attendance.iconProps"
                  :rightSidePercent="30"
                ></Label>
              </b-col>
              <b-col xs="6" class="d-flex flex-column">
                <Label
                  v-for="(rank, i) in ranks"
                  :key="`rank_${i}`"
                  :left="rank.left"
                  :right="rank.right"
                  :left-style="rank.leftStyle"
                  :right-style="rank.rightStyle"
                  :style="{ marginBottom: '6px', height: '22.21px' }"
                  :right-position="rank.rightPosition"
                  :color="rank.color"
                  :icon-props="rank.iconProps"
                  :rightSidePercent="30"
                ></Label>
              </b-col>
            </b-row>
          </b-col>
          <hr style="margin-top:16px;margin-bottom:40px" class="d-none d-xl-block" />
        </b-col>
        <b-col md="6" xl="12" class="px-0">
          <hr class="d-none d-md-block d-xl-none ml-md-3" style="margin-top:40px;margin-bottom:36px" />
          <hr class="d-md-none" style="margin-top:25px;margin-bottom:30px" />
          <template v-if="prevExamBarModel">
            <BarChart
              title="Karşılaştırma Grafiği (Net)"
              :titleStyle="styles.comparisonChartTitle"
              :chart-data="prevExamBarModel.chartData()"
              :styles="styles.comparisonChart"
              :options="styles.comparisonChartOptions"
            ></BarChart>
          </template>
          <b-col v-else>
            Önceki sınav bilgisi varsa karşılaştırma grafiği görüntülenir.
          </b-col>
        </b-col>
      </b-col>
    </b-row>
    <div class="bottom-wrapper">
      <ProgressChart
        class="mb-1"
        :type="EXAM_TYPES.TYT"
        :percent="percent"
        :top="score"
      ></ProgressChart>
      <hr />
      <template>
        <b-col class="d-flex flex-wrap " style="padding:8px 0 10px">
          <TrueFalseLegend class="mr-auto mr-md-4 mb-3 mb-xl-0"></TrueFalseLegend>
          <NetLegend class="mr-md-4 mb-3 mb-xl-0" :type="EXAM_TYPES.TYT"></NetLegend>
          <ProgressLegend class="mb-md-3 mb-xl-0" :type="EXAM_TYPES.TYT"></ProgressLegend>
        </b-col>
      </template>
    </div>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Icon from "@/components/icons/icon.component.vue";
import Label from "@/components/label.component.vue";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import bookSvg from "@/assets/media/icons/svg/Karnem/lgs/book.svg";
import checkCircleSvg from "@/assets/media/icons/svg/Karnem/lgs/check-circle.svg";
import studentChairSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg";
import schoolWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/school-white.svg";
import studentsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/students-white.svg";
import barsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/bars-white.svg";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { DoughnutChartModel, BarChartModel } from "@/common/models";
import TytStudentService from "@/common/services/api-parsers/tyt/student.service";
import { byDeviceWidth } from "@/common/utils";
import { EXAM_TYPES } from "@/common/config";
import studentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";

export default {
  name: "tyt-front",
  mixins: [ptToPxMixin, FormStoreMixin],
  components: {
    BaseReportTemplate,
    BarChart,
    DoughnutChart,
    Jumbotron,
    Label,
    NetLegend,
    ProgressChart,
    ProgressLegend,
    NetIcon,
    TrueFalseLegend,
    Icon
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      studentSvg,
      studentsSvg,
      printerSvg,
      studentChairSvg,
      selectedExamResults: null,
      netsByLesson: [],
      mainNetsByLesson: [],
      score: 0,
      percent: 1,
      futureScore: 0,
      averageScore: 0,
      attendances: [],
      ranks: [],
      examName: "",
      session: "",
      prevExamBarModel: null,
      generalDoughnutModel: null
    };
  },
  methods: {
    renderMainNetsByLesson(mainNetsByLesson) {
      this.mainNetsByLesson = mainNetsByLesson.map(data => {
        return new DoughnutChartModel({
          title: data.lesson,
          correct: data.correct,
          wrong: data.wrong,
          empty: data.empty,
          correctnessPercent: data.correctnessPercent,
          studentNet: data.net,
          averageNet: data.averageNet
        });
      });
    },
    renderGeneralData(generalData) {
      this.generalDoughnutModel = new DoughnutChartModel({
        title: "Genel Başarı",
        correct: generalData.correct,
        wrong: generalData.wrong,
        empty: generalData.empty,
        studentNet: generalData.net,
        averageNet: generalData.averageNet,
        correctnessPercent: generalData.correctnessPercent
      });
    },
    renderAttendance(attendance) {
      const commonProps = {
        leftStyle: this.styles.labels.common.left2,
        rightStyle: this.styles.labels.common.right2,
        rightPosition: "right",
        color: "blue",
        iconProps: null
      };

      this.attendances = [
        {
          ...commonProps,
          left: "Sınıf Katılım",
          right: attendance.class,
          iconProps: this.iconProps.classAttendance
        },
        {
          ...commonProps,
          left: "Kurum Katılım",
          right: attendance.corp,
          iconProps: this.iconProps.corpAttendance
        },
        {
          ...commonProps,
          left: "Genel Katılım",
          right: attendance.general,
          iconProps: this.iconProps.generalAttendance
        }
      ];
    },
    renderRank(rank) {
      const commonProps = {
        leftStyle: this.styles.labels.common.left2,
        rightStyle: this.styles.labels.common.right2,
        rightPosition: "right",
        color: "orange",
        iconProps: this.iconProps.ranks
      };

      this.ranks = [
        { ...commonProps, left: "Sınıf Sırası", right: rank.class },
        { ...commonProps, left: "Kurum Sırası", right: rank.corp },
        { ...commonProps, left: "Genel Sırası", right: rank.general }
      ];
    },
    // KARŞILAŞTIRMA GRAFİĞİ (NET)
    renderNetComparison(netsByLesson, prevExamByLesson) {
      if (!prevExamByLesson) return;

      const barData = netsByLesson.map(selectedExamResult => {
        const prevExamResult = prevExamByLesson.find(
          lesson => lesson.lesson === selectedExamResult.lesson
        );

        return {
          title: selectedExamResult.lesson,
          dataset: [
            {
              label: prevExamResult.meta.SINAVADI,
              data: prevExamResult.net
            },
            {
              label: selectedExamResult.meta.SINAVAD,
              data: selectedExamResult.net
            },
            {
              label: "Genel",
              data: selectedExamResult.averageNet
            }
          ]
        };
      });
      this.prevExamBarModel = new BarChartModel(barData);
    },
    async makeRequests() {
      const { exam, student } = this.form;
      if (!exam) {
        return;
      }
      const {
        attendance,
        averageScore,
        examName,
        futureScore,
        generalNets,
        mainNetsByLesson,
        netsByLesson,
        percent,
        prevExamMainNetsByLesson,
        ranks,
        score,
        session
      } = await TytStudentService.followUpReport({
        userId: student,
        examId: exam
      });

      this.renderMainNetsByLesson(mainNetsByLesson);
      this.renderGeneralData(generalNets);
      this.netsByLesson = netsByLesson;
      this.score = score;
      this.averageScore = averageScore;
      this.futureScore = futureScore;
      this.percent = percent;
      this.examName = examName;
      this.session = session;
      this.renderAttendance(attendance);
      this.renderRank(ranks);
      this.renderNetComparison(mainNetsByLesson, prevExamMainNetsByLesson);
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    styles() {
      return {
        labels: {
          common: {
            left: {
              fontSize: "11px"
            },
            right: {
              fontSize: "13.5px",
              fontFamily: "GothamCondensedBold"
            },
            left2: {
              width: "70%",
              fontSize: "12.19px"
            },
            right2: {
              width: "30%",
              fontSize: "12.19px",
              fontFamily: "GothamCondensedBold"
            }
          },
          examName: {
            width: "148px",
            height: "20px",
            marginBottom: "9px"
          },
          session: {
            width: "117px",
            height: "20px",
            marginBottom: "19px"
          },
          scores: {
            container: {
              height: "27px",
              marginBottom: "6px"
            },
            text: {
              fontSize: "19px"
            }
          }
        },
        comparisonChartTitle: {
          fontSize: "10.89px",
          marginBottom: "5px"
        },
        comparisonChartOptions: {
          plugins: {
            datalabels: {
              anchor: "end",
              align: "end",
              color: "#6D6F72",
              rotation: -90,
              offset: 0,
              font: {
                size: 8.47,
                family: "GothamCondensedBook"
              }
            }
          },
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 7.57,
              fontFamily: "GothamCondensedBook",
              fontSize: 8.47
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: this.stepSize,
                  max: this.max,
                  min: 0,
                  fontSize: 8.47,
                  fontFamily: "GothamCondensedBook"
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  tickMarkLength: 0
                },
                ticks: {
                  padding: 7,
                  fontSize: 8.47,
                  fontFamily: "GothamCondensedBook"
                }
              }
            ]
          }
        },
        comparisonChart: byDeviceWidth({
          desktop: {
            height: "206px",
            width: "353px",
            position: "relative"
          },
          tablet: {
            height: "206px",
            width: "353px",
            position: "relative"
          },
          mobile: {
            height: "206px",
            width: "320px",
            position: "relative"
          }
        }),
        printIcon: byDeviceWidth({
          tablet: 30
        })
      };
    },
    iconProps() {
      return {
        examName: {
          src: bookSvg,
          height: 11.41
        },
        session: {
          src: checkCircleSvg,
          height: 13.38
        },
        classAttendance: {
          src: studentChairSvg,
          height: 15.68,
          width: 16
        },
        corpAttendance: {
          src: schoolWhiteSvg,
          height: 14.09,
          width: 16
        },
        generalAttendance: {
          src: studentsWhiteSvg,
          height: 15.91
        },
        ranks: {
          src: barsWhiteSvg,
          height: 11.47
        }
      };
    },
    trueColor() {
      const corp = this.$store.getters.form.corp;
      const color =
        (corp == 1 && this.theme.colors.KULTUR_FIRST) ||
        (corp == 3 && this.theme.colors.ERA_FIRST) ||
        this.theme.colors.BLUE;
      return color;
    },
    wrongColor() {
      const corp = this.$store.getters.form.corp;
      const color =
        (corp == 1 && this.theme.colors.KULTUR_SECOND) ||
        (corp == 3 && this.theme.colors.ERA_SECOND) ||
        this.theme.colors.GRAY;
      return color;
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  &-front {
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: center;
    color: #6d6f72;
    font-family: "GothamCondensedBook";
    margin-left: auto;
    margin-right: auto;
    border-color: #e9eaea;
    thead {
      font-size: 8px;
      line-height: 1;
      th {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
    tbody {
      font-size: 10.83px;
      td {
        &:first-of-type {
          text-align: left;
          min-width: 47px;
        }
        &:not(:first-of-type) {
          min-width: 33px;
        }
      }
    }

    b {
      font-weight: 400;
      font-family: "GothamCondensedBold";
    }
  }
}
hr {
  margin-top: 0;
  margin-bottom: 0;
  border-color: rgba(109, 111, 114, 0.25);
}
.vertical-border {
  @media (min-width: 1200px) {
    border-right: 1px solid rgba(109, 111, 114, 0.25);
  }
}
.jumbotron {
  margin-top: 47px;
}
.bottom-wrapper {
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    width: calc(100% - 50px);
  }
  @media (min-width: 1200px) {
    width: calc(100% - 100px);
  }
}
</style>
