<template>
  <BaseReportTemplate headerLeftTitle="Gelişim İzleme" hide-logo>
    <b-row>
      <b-col cols="12" class="mb-3">
        <p class="text-center">
          Listeleme formunda seçtiğiniz sınav ile kıyaslamak istediğiniz sınavları seçin.
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <Button
              v-for="exam in examList"
              style="height: 120px"
              class="mr-3"
              :key="exam.ID_SINAVBILGI"
              :text="exam.AD"
              :active="exam.active"
              :click="() => onClickExam(exam.ID_SINAVBILGI)"
            ></Button>
          </div>
        </div>
      </b-col>
      <b-col md="12" lg="6" class="border-right">
        <p class="text-center" v-if="!scoreBarChartData">
          Sınav seçimi yapıldıktan sonra puan kıyaslamaları görebilirsiniz.
        </p>
        <BarChart
          v-else
          title="PUAN"
          :step-size="50"
          :colors="barColors"
          :chart-data="scoreBarChartData"
          :options="generalBarOptions"
          :max="500"
          :styles="{ height: '270px', position: 'relative' }"
        ></BarChart>
      </b-col>
      <b-col md="12" lg="6">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="text-right">
                <Icon :src="printerSvg" :height="40"></Icon>
              </b-col>
              <b-col cols="12">
                <div class="d-flex flex-column align-items-end">
                  <Label
                    class="w-75 my-2"
                    size="medium"
                    left="Puan Ortalamam"
                    left-position="center"
                    :right="studentAverageScore"
                    right-position="center"
                    color="orange"
                  ></Label>
                  <Label
                    class="w-75"
                    size="medium"
                    left="Genel Puan Ort."
                    left-position="center"
                    :right="generalAverageScore"
                    right-position="center"
                    color="blue"
                  ></Label>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-end">
                  <div class="d-inline-flex">
                    <NetIcon
                      class="mt-3"
                      variant="large"
                      :type="EXAM_TYPES.TYT"
                      :student-net="
                        variant === REPORT_VARIANTS.STUDENT ? studentAverageNet : undefined
                      "
                      :class-net="variant === REPORT_VARIANTS.CLASS ? studentAverageNet : undefined"
                      :average-net="generalAverageNet"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-5">
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="align-items-center">
        <div v-if="percentAndScore">
          <ProgressChart
            :variant="variant"
            :type="EXAM_TYPES.TYT"
            :percent="percentAndScore.percent"
            :top="percentAndScore.score"
          ></ProgressChart>
        </div>
        <p v-else>Yüzdelik başarıyı görmek içn listeleme formundan sınav seçin.</p>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col v-if="!netsByMainLessons.length">
        <p>Derslere göre netleri kıyaslamak için yukarıdan sınav seçin.</p>
      </b-col>
      <b-col
        v-else
        cols="6"
        class="border-top py-5"
        :class="barContainerClass(index)"
        v-for="(data, index) in netsByMainLessons"
        :key="index"
      >
        <BarChart
          :title="data.lesson"
          :colors="barColors"
          :chart-data="data.model.chartData()"
          :options="generalBarOptions"
          :max="40"
        ></BarChart>
        <NetIcon
          class="mt-3"
          :type="EXAM_TYPES.TYT"
          :student-net="data.net"
          :average-net="data.averageNet"
        />
      </b-col>
    </b-row>
    <b-row class="border-top">
      <b-col cols="12" md="8" class="mt-3 mx-auto" v-if="tableHeader.length > 1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="border-right"></th>
              <th
                v-for="(col, i) in tableHeader"
                :key="i"
                class="text-center pb-0"
                :class="{ 'border-right': i !== tableHeader.length - 1 }"
              >
                <div class="mb-3">{{ col }}</div>
                <b-row>
                  <b-col class="border-right">
                    <Icon :src="yksStudentSvg" :height="tableIconHeight"></Icon>
                  </b-col>
                  <b-col>
                    <Icon :src="yksStudentsSvg" :height="tableIconHeight"></Icon>
                  </b-col>
                </b-row>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lesson, i) in netsByLesson" :key="i">
              <td class="border-right text-right" style="width: 130px">
                {{ lesson.lesson.substring(0, 15) }}
              </td>
              <td
                v-for="(exam, examIndex) in lesson.exams"
                :key="`td_${examIndex}`"
                class="text-center border-right"
              >
                <b-row>
                  <b-col class="border-right">{{ exam.studentNet }}</b-col>
                  <b-col>{{ exam.averageNet }}</b-col>
                </b-row>
              </td>
              <td class="text-center">
                <b-row>
                  <b-col class="border-right">{{ lesson.average.studentNet }}</b-col>
                  <b-col>{{ lesson.average.generalNet }}</b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row>
        <b-col md="12" lg="6" class="my-auto">
          <div class="d-flex flex-row">
            <NetLegend></NetLegend>
            <TrueFalseLegend variant="comparison"></TrueFalseLegend>
          </div>
        </b-col>
        <b-col md="12" lg="6">
          <div class="d-flex justify-content-end">
            <ProgressLegend :variant="variant" :type="EXAM_TYPES.TYT"></ProgressLegend>
          </div>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import Button from "@/components/buttons/button.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import Label from "@/components/label.component.vue";
import Icon from "@/components/icons/icon.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import barData from "@/common/mock/bar-comparison.json";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import FormStoreMixin from "@/components/mixins/form.mixin";
import TytStudentService from "@/common/services/api-parsers/tyt/student.service";
import TytClassService from "@/common/services/api-parsers/tyt/class.service";
import { BarChartModel } from "@/common/models";
import { EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";
import yksStudentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import yksStudentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";

export default {
  name: "tyt-comparison-report",
  mixins: [FormStoreMixin],
  props: {
    variant: {
      type: String,
      default: "student",
      validator(value) {
        const variants = [REPORT_VARIANTS.STUDENT, REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS];
        return variants.indexOf(value) !== -1;
      }
    }
  },
  components: {
    BaseReportTemplate,
    BarChart,
    Button,
    Icon,
    Label,
    NetIcon,
    NetLegend,
    ProgressLegend,
    ProgressChart,
    TrueFalseLegend
  },
  data() {
    return {
      barData,
      printerSvg,
      yksStudentSvg,
      yksStudentsSvg,
      EXAM_TYPES,
      REPORT_VARIANTS,
      tableIconHeight: 20,
      generalBarOptions: {
        legend: {
          display: false
        }
      },
      examList: [],
      studentAverageScore: 0,
      generalAverageScore: 0,
      studentAverageNet: 0,
      generalAverageNet: 0,
      scoreBarChartData: null,
      timeoutId: null,
      netsByMainLessons: [],
      netsByLesson: [],
      percentAndScore: null
    };
  },
  created() {
    this.listExams();
  },
  watch: {
    exams() {
      this.listExams();
    },
    async exam() {
      await this.makeRequests();
    }
  },
  methods: {
    listExams() {
      this.examList = [...this.exams].filter(
        exam => !exam.disabled && exam.ID_SINAVBILGI !== this.form.exam
      );
    },
    onClickExam(examId) {
      const examList = [...this.examList];

      const selectedExamId = examList.findIndex(exam => exam.ID_SINAVBILGI === examId);
      const selectedExam = examList[selectedExamId];
      examList[selectedExamId] = { ...selectedExam, active: !selectedExam.active };

      this.examList = examList;

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(this.makeRequests, 2000);
    },
    renderScoreBar(dataByExam) {
      const scoreBarChartData = dataByExam.map(examData => {
        return {
          title: examData.exam,
          dataset: [
            {
              label: "Öğrenci Puan",
              data: examData.score
            },
            {
              label: "Genel Puan",
              data: examData.averageScore
            }
          ]
        };
      });
      this.scoreBarChartData = new BarChartModel(scoreBarChartData).chartData();
    },
    renderScoreLabels({ studentAverageScore, generalAverageScore }) {
      this.studentAverageScore = studentAverageScore || 0;
      this.generalAverageScore = generalAverageScore || 0;
    },
    renderAverageNets({ studentAverageNet, generalAverageNet }) {
      this.studentAverageNet = studentAverageNet;
      this.generalAverageNet = generalAverageNet;
    },
    prepareNetBarDataByLesson(netsByLesson) {
      this.dataByLesson = netsByLesson.map(lessonData => {
        let chartData = lessonData.exams.map(exam => {
          return {
            title: exam.exam,
            dataset: [
              {
                label: "Öğrenci Net",
                data: exam.studentNet
              },
              {
                label: "Genel Net",
                data: exam.generalNet
              }
            ]
          };
        });
        chartData = new BarChartModel(chartData).chartData();
        return {
          lesson: lessonData.lesson,
          chartData,
          averageStudentNet: lessonData.averageStudentNet,
          averageGeneralNet: lessonData.averageGeneralNet
        };
      });
    },
    renderNetsByMainLesson(netsByMainLessons) {
      this.netsByMainLessons = netsByMainLessons.map(lessonData => {
        const chartData = lessonData.exams.map(exam => {
          return {
            title: exam.exam,
            dataset: [
              {
                label: "Net",
                data: exam.net
              },
              {
                label: "Genel Net",
                data: exam.averageNet
              }
            ]
          };
        });

        const model = new BarChartModel(chartData);
        return {
          lesson: lessonData.lesson,
          model,
          net: lessonData.net,
          averageNet: lessonData.averageNet
        };
      });
    },
    async makeRequests() {
      const selectedExams = this.examList.filter(
        exam => exam.active && exam.ID_SINAVBILGI !== this.form.exam
      );
      const examIdList = selectedExams.map(exam => exam.ID_SINAVBILGI);
      if (this.form.exam) {
        examIdList.push(this.form.exam);
      }

      let {
        score,
        net,
        averageScore,
        averageNet,
        averageScoresByExam,
        netsByMainLessons,
        netsByLesson,
        percentAndScore
      } = {};

      if (this.variant === REPORT_VARIANTS.STUDENT) {
        ({
          score,
          net,
          averageScore,
          averageNet,
          averageScoresByExam,
          netsByMainLessons,
          netsByLesson,
          percentAndScore
        } = await TytStudentService.comparison({
          userId: this.form.student,
          examIdList
        }));
      } else if (this.variant === REPORT_VARIANTS.CLASS) {
        ({
          score,
          net,
          averageScore,
          averageNet,
          averageScoresByExam,
          mainLessonNetsByExam: netsByMainLessons,
          netsByLesson,
          percentAndScore
        } = await TytClassService.comparison({
          classId: this.form.class,
          examIdList
        }));
      }

      this.renderScoreLabels({ studentAverageScore: score, generalAverageScore: averageScore });
      this.renderAverageNets({ studentAverageNet: net, generalAverageNet: averageNet });
      this.renderScoreBar(averageScoresByExam);
      this.percentAndScore = percentAndScore;
      this.renderNetsByMainLesson(netsByMainLessons);
      this.netsByLesson = netsByLesson;
    },
    barContainerClass(index) {
      const classList = [];
      if (index % 2 === 0) {
        classList.push("border-right");
      }
      return classList;
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    tableHeader() {
      const [netByLesson = { exams: [] }] = this.netsByLesson;
      const { exams } = netByLesson;
      return exams.map(exam => exam.exam).concat(["Ortalama"]);
    },
    exams() {
      return this.form.exams || [];
    },
    exam() {
      return this.form.exam;
    },
    barColors() {
      return this.theme.charts.comparisonBar.colors;
    }
  }
};
</script>

<style scoped></style>
