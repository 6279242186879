<template>
  <BaseReportTemplate headerLeftTitle="Soru Analizi" :headerLeftSubTitle="leftSubTitle">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" :md="i % 2 === 0 ? 7 : 5" v-for="(gainModel, i) in gainModels" :key="i">
            <GainsTable
              :title="gainModel.lessonName"
              :summary="gainModel.summary()"
              :variant="getVariant(i)"
              all-rectangle
            >
              <b-row>
                <b-col
                  cols="12"
                  :md="gainModel.gains.length > 1 ? 6 : 12"
                  :class="{ 'border-right': i === 0 && gainModel.gains.length > 1 }"
                  v-for="(gains, i) in gainModel.gains"
                  :key="`tbl_${i}`"
                >
                  <b-table small borderless :items="gains" :fields="fields">
                    <template v-slot:cell()="data">
                      <span :style="getCellStyle(data)">{{ data.value }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </GainsTable>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template slot="legend">
      <div class="d-flex flex-row mb-2">
        <span v-for="(legend, i) in legends" :key="i" class="mr-4" :style="legendStyle">
          <strong>{{ legend.label }}:</strong>
          {{ legend.description }}
        </span>
      </div>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import GainsTable from "@/components/charts/gains-table.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import TytClassService from "@/common/services/api-parsers/tyt/class.service";
import TytCorpService from "@/common/services/api-parsers/tyt/corp.service";
import { GainsTableModel } from "@/common/models";
import { REPORT_VARIANTS } from "@/common/config";

export default {
  name: "tyt-question-analyze",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    GainsTable
  },
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.CLASS,
      validator(value) {
        return [REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      REPORT_VARIANTS,
      attendance: 0,
      gainModels: [],
      fields: [
        { key: "index", label: "S.N." },
        { key: "a", label: "A" },
        { key: "b", label: "B" },
        { key: "c", label: "C" },
        { key: "d", label: "D" },
        { key: "e", label: "E" },
        { key: "empty", label: "Boş" },
        { key: "correctRatio", label: "Y.O.", formatter: value => `%${value.toFixed(1)}` },
        { key: "gain", label: "Konu Dağılımı", formatter: value => value.substring(0, 13) }
      ]
    };
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  created() {
    this.makeRequests();
  },
  methods: {
    async makeRequests() {
      if (!this.exam) return;
      let questionAnalyzeByLesson;
      let attendance;

      if (this.variant === REPORT_VARIANTS.CLASS) {
        ({ questionAnalyzeByLesson, attendance } = await TytClassService.questionAnalyze({
          examId: this.exam,
          examTypeId: this.form.examType,
          classId: this.form.class
        }));
      } else {
        ({ questionAnalyzeByLesson, attendance } = await TytCorpService.questionAnalyze({
          examId: this.exam,
          branchId: this.form.branch
        }));
      }

      this.renderGainTables(questionAnalyzeByLesson);
      this.attendance = attendance;
    },
    renderGainTables(questionAnalyzeByLesson) {
      this.gainModels = questionAnalyzeByLesson.map(lesson => {
        return new GainsTableModel({
          lessonName: lesson.lesson,
          correct: lesson.correct,
          wrong: lesson.wrong,
          empty: lesson.empty,
          net: lesson.net,
          gains: lesson.gains
        });
      });
    },
    getCellStyle(data) {
      const style = {
        fontSize: "0.7rem"
      };
      const isOption = data.field.key.indexOf("A", "B", "C", "D", "E") !== -1;

      if (isOption && data.value > 149) {
        return {
          ...style,
          fontWeight: "bold",
          fontStyle: "italic"
        };
      }

      return style;
    },
    getVariant(index) {
      const variants = ["blue", "orange", "red", "gray", "green", "lightBlue"];
      return variants[index] || variants[Math.floor(Math.random() * variants.length)];
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    leftSubTitle() {
      return `Katılım: ${this.attendance} Öğrenci`;
    },
    legends() {
      return [
        { label: "S.N.", description: "Soru Numarası" },
        { label: "Y.O.", description: "Yapılma Oranı" }
      ];
    },
    legendStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontFamily: "GothamNarrowBook",
        fontSize: "0.9rem"
      };
    }
  }
};
</script>
