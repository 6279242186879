<template>
  <div class="d-flex flex-row align-items-center">
    <ProgressChart :variant="variant" :type="type" legend></ProgressChart>
    <span :style="textStyle" class="ml-2">
      {{ text }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ProgressChart from "@/components/charts/progress.component.vue";
import { EXAM_TYPES, ALLOWED_EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";

export default {
  name: "progress-legend",
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.STUDENT,
      validator(value) {
        return Object.values(REPORT_VARIANTS).indexOf(value) !== -1;
      }
    },
    type: {
      type: Number,
      default: EXAM_TYPES.SBS,
      validator(value) {
        return ALLOWED_EXAM_TYPES.indexOf(value) !== -1;
      }
    }
  },
  components: {
    ProgressChart
  },
  computed: {
    ...mapGetters(["theme"]),
    text() {
      let text = "Sıralamadaki yerine bakarak hedefine ne kadar yaklaştığını görebilirsin.";
      if (this.variant === REPORT_VARIANTS.CORP) {
        text = "Sınavı uygulayan kurumlar arasındaki konumunuz.";
      } else if (this.variant === REPORT_VARIANTS.CLASS) {
        text = "Sınavı uygulayan sınıflar arasındaki konumunuz.";
      }
      return text;
    },
    textStyle() {
      return {
        fontFamily: "GothamCondensedBook",
        color: this.theme.colors.GRAY,
        fontSize: "8pt",
        lineHeight: 1
      };
    }
  }
};
</script>
