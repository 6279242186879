<template>
  <div class="d-flex flex-row justify-content-center">
    <div class="h-100 d-table mr-2">
      <img :src="studentChairOrangeSvg" alt="class" :style="iconStyle" />
      <div class="d-table-cell align-middle" :style="netStyle">{{ classRank }}</div>
    </div>
    <div class="h-100 d-table mr-2">
      <img :src="schoolOrangeSvg" alt="corp" :style="iconStyle" />
      <div class="d-table-cell align-middle" :style="netStyle">{{ corpRank }}</div>
    </div>
    <div class="h-100 d-table">
      <img :src="studentsSvg" alt="student" :style="iconStyle" />
      <span class="d-table-cell align-middle" :style="netStyle">{{ generalRank }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import schoolOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/school-orange.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";
import studentChairOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair-orange.svg";

export default {
  name: "rank",
  props: {
    classRank: {
      type: [Number, String],
      required: true
    },
    corpRank: {
      type: [Number, String],
      required: true
    },
    generalRank: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      schoolOrangeSvg,
      studentsSvg,
      studentChairOrangeSvg
    };
  },
  computed: {
    ...mapGetters(["theme"]),
    netStyle() {
      let fontSize;
      let paddingLeft;
      let paddingTop;

      paddingLeft = 5;
      fontSize = 12;
      paddingTop = 0;

      return {
        color: this.theme.colors.GRAY,
        fontFamily: "GothamCondensedBold",
        fontSize: `${fontSize}pt`,
        paddingLeft: `${paddingLeft}px`,
        paddingTop: `${paddingTop}px`
      };
    },
    iconStyle() {
      return {
        height: `17px`
      };
    }
  }
};
</script>
