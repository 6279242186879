<template>
  <BaseReportTemplate headerLeftTitle="Gelişim İzleme" hide-logo>
    <b-row>
      <b-col cols="12" class="mb-3">
        <p class="text-center">
          Listeleme formunda seçtiğiniz sınav ile kıyaslamak istediğiniz sınavları seçin.
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <Button
              v-for="exam in examList"
              style="height: 120px"
              class="mr-3"
              :key="exam.ID_SINAVBILGI"
              :text="exam.AD"
              :active="exam.active"
              :click="() => onClickExam(exam.ID_SINAVBILGI)"
            ></Button>
            <Button
              style="height: 30px; width: auto"
              class="mr-3"
              text="Listele"
              :click="() => onClickList()"
            ></Button>
          </div>
        </div>
      </b-col>
      <b-col md="6" lg="6" class="border-right">
        <p class="text-center" v-if="!scoreBarChartData">
          Sınav seçimi yapıldıktan sonra puan kıyaslamaları görebilirsiniz.
        </p>
        <BarChart
          v-else
          title="PUAN"
          :step-size="50"
          :colors="barColors"
          :chart-data="scoreBarChartData"
          :options="generalBarOptions"
          :max="500"
          :styles="{ height: '270px', position: 'relative' }"
        ></BarChart>
      </b-col>
      <b-col md="6" lg="6">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col lg="10" cols="10" order="1" order-lg="1">
                <div class="d-flex flex-column align-items-center">
                  <Label
                    class="my-2"
                    :class="scoreLabelClass"
                    size="medium"
                    left="Puan Ortalamam"
                    left-position="center"
                    :right="studentAverageScore"
                    right-position="center"
                    color="orange"
                  ></Label>
                  <Label
                    :class="scoreLabelClass"
                    size="medium"
                    left="Genel Puan Ort."
                    left-position="center"
                    :right="generalAverageScore"
                    right-position="center"
                    color="blue"
                  ></Label>
                </div>
              </b-col>
              <b-col lg="2" cols="2" order="2" order-lg="2">
                <Icon :src="printerSvg" :height="40"></Icon>
              </b-col>
              <b-col cols="10" lg="10" order="3">
                <NetIcon
                  class="mt-3"
                  :variant="averageNetLabelVariant"
                  :student-net="studentAverageNet"
                  :average-net="generalAverageNet"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mb-5">
            <hr />
          </b-col>
          <b-col md="12" class="align-items-center">
            <div v-if="selectedExamName">
              <ProgressChart
                :percent="selectedExamStudentPercent"
                :top="selectedExamName"
                :shadow-percents="shadowPercents"
              ></ProgressChart>
            </div>
            <p v-else>Yüzdelik başarıyı görmek içn listeleme formundan sınav seçin.</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col v-if="!dataByLesson.length">
        <p>Derslere göre netleri kıyaslamak için yukarıdan sınav seçin.</p>
      </b-col>
      <b-col
        v-else
        cols="12"
        md="6"
        lg="4"
        class="border-top py-2"
        :class="barContainerClass(index)"
        v-for="(data, index) in dataByLesson"
        :key="index"
        :order="data.orders.mobile"
        :order-md="data.orders.tablet"
        :order-lg="data.orders.desktop"
      >
        <BarChart
          :title="data.lesson"
          :colors="barColors"
          :max="data.max"
          :chart-data="data.chartData"
          :options="generalBarOptions"
          :styles="netByLessonChartStyle(data.max)"
        ></BarChart>
        <NetIcon
          class="mt-3"
          :student-net="data.averageStudentNet"
          :average-net="data.averageGeneralNet"
        />
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row>
        <b-col cols="12" md="5" class="d-flex align-items-center">
          <div class="d-inline-flex justify-content-center">
            <NetLegend></NetLegend>
          </div>
          <div class="d-inline-flex justify-content-center">
            <TrueFalseLegend variant="comparison"></TrueFalseLegend>
          </div>
        </b-col>
        <b-col cols="12" md="7" class="p-0">
          <div class="d-flex justify-content-end">
            <ProgressLegend></ProgressLegend>
          </div>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import Button from "@/components/buttons/button.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import Label from "@/components/label.component.vue";
import Icon from "@/components/icons/icon.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import barData from "@/common/mock/bar-comparison.json";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { SinavAPI } from "@/common/api";
import { BarChartModel } from "@/common/models";
import { byDeviceWidth } from "@/common/utils";

export default {
  name: "comparison-report",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    BarChart,
    Button,
    Icon,
    Label,
    NetIcon,
    NetLegend,
    ProgressLegend,
    ProgressChart,
    TrueFalseLegend
  },
  data() {
    return {
      barData,
      printerSvg,
      generalBarOptions: {
        legend: {
          display: false
        }
      },
      examList: [],
      studentAverageScore: 0,
      generalAverageScore: 0,
      studentAverageNet: 0,
      generalAverageNet: 0,
      scoreBarChartData: null,
      selectedExamName: "",
      selectedExamStudentPercent: 1,
      timeoutId: null,
      dataByLesson: [],
      shadowPercents: []
    };
  },
  created() {
    this.listExams();
  },
  watch: {
    exams() {
      this.listExams();
    }
  },
  methods: {
    listExams() {
      this.examList = [...this.exams].filter(exam => !exam.disabled);
    },
    onClickExam(examId) {
      const examList = [...this.examList];

      const selectedExamId = examList.findIndex(exam => exam.ID_SINAVBILGI === examId);
      const selectedExam = examList[selectedExamId];
      examList[selectedExamId] = { ...selectedExam, active: !selectedExam.active };

      this.examList = examList;
    },
    async onClickList() {
      await this.makeRequests();
    },
    prepareScoreBarData(dataByExam) {
      const scoreBarChartData = dataByExam.map(examData => {
        return {
          title: examData.exam,
          dataset: [
            {
              label: "Öğrenci Puan",
              data: examData.studentScore
            },
            {
              label: "Genel Puan",
              data: examData.generalScore
            }
          ]
        };
      });
      this.scoreBarChartData = new BarChartModel(scoreBarChartData).chartData();
    },
    prepareScoreLabels({ studentAverageScore, generalAverageScore }) {
      this.studentAverageScore = studentAverageScore || 0;
      this.generalAverageScore = generalAverageScore || 0;
    },
    prepareAverageNets({ studentAverageNet, generalAverageNet }) {
      this.studentAverageNet = studentAverageNet;
      this.generalAverageNet = generalAverageNet;
    },
    prepareNetBarDataByLesson(netsByLesson) {
      this.dataByLesson = netsByLesson.map(lessonData => {
        let chartData = lessonData.exams.map(exam => {
          return {
            title: exam.exam,
            dataset: [
              {
                label: "Öğrenci Net",
                data: exam.studentNet
              },
              {
                label: "Genel Net",
                data: exam.generalNet
              }
            ]
          };
        });
        chartData = new BarChartModel(chartData).chartData();
        return {
          lesson: lessonData.lesson,
          chartData,
          averageStudentNet: lessonData.averageStudentNet,
          averageGeneralNet: lessonData.averageGeneralNet,
          max: lessonData.totalQuestion,
          orders: lessonData.orders
        };
      });
    },
    async makeRequests() {
      const selectedExams = this.examList.filter(exam => exam.active);
      const examIdList = selectedExams.map(exam => exam.ID_SINAVBILGI);

      const {
        byExam,
        studentAverageScore,
        generalAverageScore,
        studentAverageNet,
        generalAverageNet,
        byLesson,
        scoresByExam
      } = await SinavAPI.comparisonScoreData({
        userId: this.form.student,
        examIdList
      });

      this.prepareScoreBarData(byExam);
      this.prepareScoreLabels({ studentAverageScore, generalAverageScore });
      this.prepareAverageNets({ studentAverageNet, generalAverageNet });
      this.prepareNetBarDataByLesson(byLesson);
      this.preparePercentSuccess(scoresByExam);
    },
    preparePercentSuccess(scoresByExam) {
      const [best = {}] = scoresByExam;
      this.selectedExamName = best.exam;
      this.selectedExamStudentPercent = best.percent;

      this.shadowPercents = scoresByExam.map(score => score.percent);
    },
    barContainerClass(index) {
      if (window.outerWidth <= 768) {
        return index % 2 === 0 ? "border-right" : "";
      }
      const classList = [];
      const colIndex = index + 1;
      if (colIndex === 0 || colIndex % 3 !== 0) {
        classList.push("border-right");
      }
      return classList;
    },
    netByLessonChartStyle(maxQuestion) {
      const baseStyle = { height: maxQuestion === 10 ? "220px" : "400px", position: "relative" };
      return byDeviceWidth(
        {
          desktop: {
            ...baseStyle,
            height: maxQuestion === 10 ? "140px" : "200px"
          },
          tablet: {
            ...baseStyle,
            height: maxQuestion === 10 ? "140px" : "200px"
          }
        },
        "tablet"
      );
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exams() {
      return this.form.exams || [];
    },
    exam() {
      return this.form.exam;
    },
    barColors() {
      return this.theme.charts.comparisonBar.colors;
    },
    scoreLabelClass() {
      return byDeviceWidth({
        desktop: ["w-75"],
        tablet: ["w-90"],
        mobile: ["w-90"]
      });
    },
    averageNetLabelVariant() {
      return byDeviceWidth({
        desktop: "large",
        tablet: "medium",
        mobile: "medium"
      });
    },
    progressLegendStyle() {
      return byDeviceWidth({
        desktop: {},
        tablet: { height: "30px" },
        mobile: {}
      });
    }
  }
};
</script>

<style scoped></style>
