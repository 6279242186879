<template>
  <div class="d-flex flex-column my-2" :style="containerStyle">
    <div class="header" :style="headerContainerStyle">
      <h1 :style="headerStyle">{{ title }}</h1>
      <b-row class="mx-0">
        <div class="circle-outer">
          <div class="circle">
            {{ summary.correct }}
          </div>
          <span class="circle-text">Doğru</span>
        </div>
        <div class="circle-outer">
          <div class="circle">
            {{ summary.wrong }}
          </div>
          <span class="circle-text">Yanlış</span>
        </div>
        <div class="circle-outer">
          <div class="circle">
            {{ summary.empty }}
          </div>
          <span class="circle-text">Boş</span>
        </div>
        <div class="circle-outer">
          <div class="circle rectangle">
            {{ summary.net }}
          </div>
          <span class="circle-text">Net</span>
        </div>
        <Icon :src="printerSvg" :height="30" class="ml-auto"></Icon>
      </b-row>
      <Icon :src="printerSvg" v-if="0 && !allRectangle"></Icon>
    </div>
    <div class="table-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/icons/icon.component.vue";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer-white.svg";

export default {
  name: "gains-table",
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    variant: {
      type: String,
      default: "gray",
      validator(value) {
        return ["gray", "blue", "red", "orange", "green", "lightBlue"].indexOf(value) !== -1;
      }
    },
    summary: {
      type: Object,
      required: true
    },
    allRectangle: {
      type: Boolean,
      default: false
    },
    headerStyles: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      printerSvg
    };
  },
  computed: {
    ...mapGetters(["theme"]),
    borderRadius() {
      return "15px";
    },
    getBackgroundColor() {
      const { colors } = this.theme;
      switch (this.variant) {
        case "gray":
          return colors.GRAY;
        case "blue":
          return colors.BLUE;
        case "red":
          return colors.RED;
        case "orange":
          return colors.ORANGE;
        case "green":
          return colors.GREEN;
        case "lightBlue":
          return colors.LIGHT_BLUE;
        default:
          return colors.GRAY;
      }
    },
    containerStyle() {
      const color = this.getBackgroundColor;
      return {
        background: `linear-gradient(0deg, #f3f3f5 30%, ${color} 30%)`,
        borderRadius: this.borderRadius
      };
    },
    headerStyle() {
      return {
        ...this.headerStyles
      };
    },
    headerContainerStyle() {
      return {
        backgroundColor: this.getBackgroundColor,
        borderTopLeftRadius: this.borderRadius,
        borderTopRightRadius: this.borderRadius
      };
    },
    getIconVariant() {
      if (!this.allRectangle) return null;
      return "rectangle";
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  padding-left: 33px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  h1 {
    font-family: "GothamNarrowBlack";
    color: white;
    font-size: 14.84px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 5px;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 24px;
    min-width: 24px;
    line-height: 1;
    font-size: 13.6px;
    font-family: GothamNarrowBlack;
    color: black;
    background-color: white;
    filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.3));
    border-radius: 50%;
    &.rectangle {
      border-radius: 3.14px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &-outer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
    }
    &-text {
      font-size: 6.8px;
      font-family: GothamNarrowBold;
      color: white;
      margin-top: 5px;
      line-height: 1;
    }
  }
}
</style>
<style>
.table-container {
  position: relative;
  border-radius: 15px;
  background-color: #f3f3f5;
  box-shadow: 0 -3px 0px 0px rgba(0, 0, 0, 0.3);
  padding: 7px 5px;
}
.table-container td {
  font-size: 0.9rem;
}
</style>
