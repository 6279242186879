<template>
  <BaseReportTemplate
    :hide-logo="hideLogo"
    :headerLeftTitle="headerLeftTitle"
    :headerLeftSubTitle="headerLeftSubTitle"
    :headerRightTitle="headerRightTitle"
  >
    <b-row>
      <b-col cols="12">
        <div class="d-flex flex-row">
          <div class="d-inline-flex flex-column" style="overflow: scroll; max-height: 500px">
            <Button
              v-for="exam in examList"
              :key="exam.ID_SINAVBILGI"
              :text="exam.AD"
              :active="exam.active"
              :click="() => onClickExamType(exam.ID_SINAVBILGI)"
            ></Button>
          </div>
          <div class="d-flex flex-column align-items-center mx-auto mx-md-0 my-auto pt-5 pl-lg-5">
            <template v-if="isVisibleGeneralData">
              <b-row>
                <b-col
                  cols="12"
                  :md="i === netsByCategory.length - 1 ? null : 6"
                  v-for="(netByCategory, i) in netsByCategory"
                  :key="i"
                >
                  <DoughnutChart
                    :chart-data="netByCategory.chartData()"
                    :title="netByCategory.title"
                    :styles="chartStyle"
                    :cutout-percentage="65"
                    :inner-font-size="ptToPx(40)"
                    :slice-font-size="22"
                  ></DoughnutChart>
                  <NetIcon
                    class="my-2"
                    :type="EXAM_TYPES.YKS"
                    :student-net="isVariant.student ? netByCategory.studentNet : undefined"
                    :corp-net="isVariant.corp ? netByCategory.net : undefined"
                    :class-net="isVariant.class ? netByCategory.studentNet : undefined"
                    :average-net="netByCategory.averageNet"
                  />
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <h3 class="ml-2">
                Sınavlara ait toplam net bilgileri ve kazanım ağacı için sol taraftan sınav seçin.
              </h3>
            </template>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col
            cols="12"
            md="4"
            v-for="(score, scoreIndex) in scores"
            :key="`score_${scoreIndex}`"
          >
            <Label
              :style="labelStyle"
              size="medium"
              color="orange"
              :left="score.title"
              :left-style="labelLeftStyle"
              left-position="center"
              :right-style="labelRightStyle"
              right-position="center"
              :right="score.score"
            ></Label>
            <NetIcon
              class="my-2"
              :type="EXAM_TYPES.YKS"
              :student-net="isVariant.student ? score.studentNet : undefined"
              :class-net="isVariant.class ? score.net : undefined"
              :corp-net="isVariant.corp ? score.net : undefined"
              :average-net="score.averageNet"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hr />
      </b-col>
    </b-row>
    <!--
    <b-row>
      <b-col>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <div class="form-check form-check-inline" v-for="(filter, i) in filters" :key="i">
              <input
                class="form-check-input mr-1"
                type="radio"
                name="levelFilter"
                @change="() => onChangeFilter(filter)"
                :id="i"
              />
              <label class="form-check-label" :for="i" :style="radioLabelStyle">
                {{ filter.text }}
              </label>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <Button text="Listele" active :style="{ width: '120px' }"></Button>
          </div>
        </div>
      </b-col>
    </b-row>
    -->
    <b-row class="mt-3" v-for="(treeData, i) of treeDataList" :key="i">
      <b-col cols="12" lg="5" class="mb-4 mb-sm-0">
        <div class="d-flex h-100 align-items-center">
          <Selectish class="w-100" :text="treeData.lesson"></Selectish>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <DoughnutChart
          :chart-data="getDoughnutDataFromTree(treeData)"
          :styles="{ height: '230px', position: 'relative' }"
          :cutout-percentage="65"
          :inner-font-size="70"
          :slice-font-size="22"
        ></DoughnutChart>
      </b-col>
      <b-col cols="12" lg="2" class="my-2 my-sm-0">
        <div class="d-flex h-100 align-items-center justify-content-center">
          <NetIcon
            class="align-self-center"
            variant="large"
            :type="EXAM_TYPES.YKS"
            :student-net="isVariant.student ? treeData.studentNet : undefined"
            :corp-net="isVariant.corp ? treeData.net : undefined"
            :class-net="isVariant.class ? treeData.net : undefined"
            :average-net="treeData.averageNet"
          />
        </div>
      </b-col>
      <b-col cols="12">
        <TreeTableChart :tree-data="treeData.subLessons"></TreeTableChart>
      </b-col>
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col cols="12" v-if="i === 0">
        <Jumbotron class="mt-5 w-100" :background="false">
          Kazanım ağacınızdaki açmış yeşil çiçekler, yaptıklarınızla doğru yolda olduğunuzu
          göstermektedir. Kuru kırmızı çiçekleri de yeşile çevirmek senin elinde. Konuları tekrar
          ederek ve yeterince soru çözerek, kazanım ağacını yemyeşil çiçeklerle donatabilirsin.
        </Jumbotron>
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row class="mb-2 align-items-center">
        <b-col cols="12" lg="6">
          <div class="d-flex flex-row">
            <div class="mr-3" :style="legendStyles.unit"><fa icon="plus-square"></fa> ÜNİTE</div>
            <div class="mr-3" :style="legendStyles.topic"><fa icon="plus-square"></fa> KONU</div>
            <div :style="legendStyles.gain"><fa icon="angle-double-right"></fa> KAZANIM</div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <GainTreeLabel is-legend></GainTreeLabel>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Button from "@/components/buttons/button.component.vue";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import GainTreeLabel from "@/components/reports/sbs/gains-tree-label.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import TreeTableChart from "@/components/charts/tree-table.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Label from "@/components/label.component.vue";
import Selectish from "@/components/selects/selectish.component.vue";
import doughnutLgsData from "@/common/mock/doughnut-lgs.json";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { REPORT_VARIANTS, EXAM_TYPES } from "@/common/config";
import { DoughnutChartModel } from "@/common/models";
import YksStudentService from "@/common/services/api-parsers/yks/student.service";
import YksClassService from "@/common/services/api-parsers/yks/class.service";
import YksCorpService from "@/common/services/api-parsers/yks/corp.service";

export default {
  name: "yks-gains-tree",
  mixins: [ptToPxMixin, FormStoreMixin],
  props: {
    variant: {
      type: String,
      default: "student",
      validator(value) {
        const variants = [REPORT_VARIANTS.STUDENT, REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS];
        return variants.indexOf(value) !== -1;
      }
    }
  },
  components: {
    BaseReportTemplate,
    Button,
    DoughnutChart,
    GainTreeLabel,
    Jumbotron,
    Label,
    NetIcon,
    Selectish,
    TreeTableChart
  },
  mounted() {
    const {
      screen: { width: screenWidth }
    } = window;
    let chartWidth;
    const labelFontFamily = "GothamNarrowBlack";

    if (innerWidth < 576) {
      chartWidth = screenWidth - 80;
      this.chartStyle = { height: "150px", width: `${chartWidth}px`, position: "relative" };
      this.labelStyle = { width: `240px` };
      this.labelLeftStyle = { fontSize: "1rem", fontFamily: labelFontFamily };
      this.labelRightStyle = { ...this.labelLeftStyle };
    } else {
      this.chartStyle = { height: "200px", position: "relative" };
      this.labelStyle = { width: `340px` };
      this.labelLeftStyle = { fontSize: "1.4rem", fontFamily: labelFontFamily };
      this.labelRightStyle = { ...this.labelLeftStyle };
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      chartStyle: null,
      labelStyle: null,
      labelLeftStyle: null,
      labelRightStyle: null,
      scores: [],
      netsByCategory: [],
      generalSuccessDoughnutChartModel: new DoughnutChartModel({}),
      treeDataList: [],
      doughnutLgsData,
      filters: [
        { text: "Ders", value: "lesson" },
        { text: "Ünite", value: "unit" },
        { text: "Konu", value: "topic" },
        { text: "Kazanım", value: "gain" }
      ],
      selectedFilter: null,
      examList: [],
      timeoutId: null
    };
  },
  methods: {
    async makeRequests() {
      await this.fetchExamList();
    },
    async fetchExamList() {
      if (this.isVariant.student) {
        this.examList = [...this.form.exams]
          .filter(exam => !exam.disabled)
          .map(exam => {
            return {
              ...exam,
              active: false
            };
          });
      } else {
        this.examList = [...this.form.exams];
      }
    },
    async fetchExamResult() {
      const { student, class: _class, branch } = this.form;
      const selectedExams = this.examList
        .filter(exam => exam.active)
        .map(exam => exam.ID_SINAVBILGI);

      let scores = [];
      let treeDataByMainLesson = [];
      let netsByCategory = [];

      if (this.isVariant.student) {
        ({ scores, treeDataByMainLesson, netsByCategory } = await YksStudentService.gainsTree({
          userId: student,
          examIdList: selectedExams
        }));
      } else if (this.isVariant.class) {
        ({ netsByCategory, scores, treeDataByMainLesson } = await YksClassService.gainsTree({
          classId: _class,
          examIdList: selectedExams
        }));
      } else {
        ({ scores, netsByCategory, treeDataByMainLesson } = await YksCorpService.gainsTree({
          branchId: branch,
          examIdList: selectedExams
        }));
      }

      this.scores = scores;
      this.treeDataList = treeDataByMainLesson;
      this.renderNetsByCategory(netsByCategory);
    },
    renderNetsByCategory(netsByCategory) {
      this.netsByCategory = netsByCategory.map(netByCategory => {
        return new DoughnutChartModel({
          title: netByCategory.category,
          correct: netByCategory.correct,
          wrong: netByCategory.wrong,
          empty: netByCategory.empty,
          correctnessPercent: netByCategory.correctnessPercent,
          studentNet: netByCategory.net,
          averageNet: netByCategory.averageNet
        });
      });
    },
    getDoughnutDataFromTree(data) {
      return new DoughnutChartModel({ ...data, title: "" }).chartData();
    },
    onChangeFilter(filter) {
      this.selectedFilter = filter;
    },
    onClickExamType(examId) {
      const examIndex = this.examList.findIndex(exam => exam.ID_SINAVBILGI === examId);
      this.$set(this.examList, examIndex, {
        ...this.examList[examIndex],
        active: !this.examList[examIndex].active
      });

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(this.fetchExamResult, 2000);
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    isVisibleGeneralData() {
      return !!this.examList.filter(exam => exam.active).length;
    },
    isVariant() {
      return {
        student: this.variant === REPORT_VARIANTS.STUDENT,
        corp: this.variant === REPORT_VARIANTS.CORP,
        class: this.variant === REPORT_VARIANTS.CLASS
      };
    },
    radioLabelStyle() {
      return this.theme.styles.radioLabel;
    },
    legendStyles() {
      const fontFamily = "GothamNarrowBook";
      return {
        unit: {
          color: this.theme.colors.GREEN,
          fontFamily
        },
        topic: {
          color: this.theme.colors.LIGHT_GREEN,
          fontFamily
        },
        gain: {
          color: this.theme.colors.GRAY,
          fontFamily
        }
      };
    },
    headerLeftTitle() {
      let name = "Kazanım Ağacı";
      if (this.variant === REPORT_VARIANTS.CORP || this.variant === REPORT_VARIANTS.CLASS) {
        name = this.form.selected.branch.AD;
      }
      return name;
    },
    headerLeftSubTitle() {
      if (this.variant === REPORT_VARIANTS.STUDENT) {
        return null;
      } else if (this.variant === REPORT_VARIANTS.CORP) {
        return this.form.selected.class.AD;
      } else {
        return `${this.form.selected.class.AD} / ${this.form.selected.class.DERSLIK}`;
      }
    },
    headerRightTitle() {
      if (this.variant === REPORT_VARIANTS.CORP || this.variant === REPORT_VARIANTS.CLASS) {
        return "Kazanım Ağacı";
      }
      return null;
    },
    hideLogo() {
      return this.variant === REPORT_VARIANTS.STUDENT;
    },
    averagePointText() {
      let text = "Puan Ortalaması";
      if (this.variant === REPORT_VARIANTS.STUDENT) {
        text = "Puan Ortalamam";
      }
      return text;
    }
  }
};
</script>
