<template>
  <BaseReportTemplate hide-logo headerLeftTitle="Kazanımlar">
    <b-row>
      <b-col cols="12">
        <b-row v-if="gainsByLesson.length">
          <b-col cols="12" md="6" lg="4" v-for="(gainByLesson, i) in gainsByLesson" :key="i">
            <GainsTable
              :title="gainByLesson.lesson"
              :summary="gainByLesson.summary"
              :variant="getVariant(i)"
            >
              <b-table
                small
                borderless
                :items="gainByLesson.gains"
                :fields="fields"
                class="gains-inner-table"
              >
                <template v-slot:cell(gain)="data">
                  <span v-b-tooltip.hover :title="data.value">{{ truncate(data.value) }}</span>
                </template>
              </b-table>
            </GainsTable>
          </b-col>
        </b-row>
        <b-row v-else>
          <h3>
            Derslere ait kazanımların listelenmesi için yukarıdaki formdan sınav seçimi yapmalısnız
          </h3>
        </b-row>
      </b-col>
    </b-row>
    <template slot="legend">
      <div class="d-flex flex-row mb-2">
        <span v-for="(legend, i) in legends" :key="i" class="mr-4" :style="legendStyle">
          <strong>{{ legend.label }}:</strong>
          {{ legend.description }}
        </span>
      </div>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import GainsTable from "@/components/charts/gains-table.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { GainsTableModel } from "@/common/models";
import SbsStudentService from "@/common/services/api-parsers/sbs/student.service";

export default {
  name: "lgs-back",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    GainsTable
  },
  data() {
    return {
      fields: [
        { key: "index", label: "S.N." },
        { key: "isCorrect", label: "C", formatter: value => (value ? "+" : "-") },
        { key: "answer", label: "A" },
        { key: "correctRatio", label: "Y.O.", formatter: value => `%${value}` },
        { key: "gain", label: "Konu Dağılımı" }
      ],
      gainsByLesson: []
    };
  },
  watch: {
    exam() {
      this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  methods: {
    async makeRequests() {
      const { student, exam } = this.form;
      if (!exam) return [];
      const gainsByLesson = await SbsStudentService.backExamReport({
        userId: student,
        examId: exam
      });

      this.renderGains(gainsByLesson);
    },
    async renderGains(gainsByLesson) {
      this.gainsByLesson = gainsByLesson.map(gainByLesson => {
        return new GainsTableModel({
          lessonName: gainByLesson.title,
          correct: gainByLesson.correct,
          wrong: gainByLesson.wrong,
          empty: gainByLesson.empty,
          net: gainByLesson.studentNet,
          gains: gainByLesson.gains
        }).chartData();
      });
    },
    getVariant(index) {
      const variants = ["gray", "blue", "red", "orange", "green", "lightBlue"];
      return variants[index] || variants[Math.floor(Math.random() * variants.length)];
    },
    truncate(s) {
      let limit = 52;
      if (s.length < limit) {
        return s;
      }

      const words = s.split(" ");
      limit = 52 - words.length;

      const newWords = [];
      let total = 0;
      words.forEach(word => {
        total += word.length;
        if (total < limit) {
          newWords.push(word);
        }
      });

      const newWord = newWords.join(" ");
      return `${newWord}...`;
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    legends() {
      return [
        { label: "S.N.", description: "Soru Numarası" },
        { label: "C", description: "Öğrenci Cevabı" },
        { label: "A", description: "Cevap Anahtarı" },
        { label: "Y.O.", description: "Yapılma Oranı" }
      ];
    },
    legendStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontFamily: "GothamNarrowBook",
        fontSize: "0.9rem"
      };
    }
  }
};
</script>

<style>
.gains-inner-table {
  margin-bottom: 0;
}

.gains-inner-table > thead > tr > th,
.gains-inner-table > tbody > tr > td {
  font-size: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

.gains-inner-table > tbody > tr > td:last-child {
  white-space: nowrap;
}

.gains-inner-table > thead > tr > th:not(:last-child),
.gains-inner-table > tbody > tr > td:not(:last-child) {
  text-align: center;
}

.gains-inner-table th:nth-child(1),
.gains-inner-table th:nth-child(2),
.gains-inner-table th:nth-child(3),
.gains-inner-table th:nth-child(4) {
  width: 20px;
}

.gains-inner-table th:last-child {
  width: auto;
}
</style>
