<template>
  <table class="table table-borderless table-sm h-100">
    <thead>
      <tr>
        <th></th>
        <th scope="col">S.N.</th>
        <th scope="col">C</th>
        <th scope="col">A</th>
        <th scope="col">Y.O.</th>
        <th scope="col">Konu Dağılımı</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(gainsByLesson, lessonIndex) of gains">
        <tr v-for="(gain, i) of gainsByLesson.gains" :key="`${gain.RN}_${gain.DERSAD}`">
          <th
            scope="row"
            class="rotate"
            v-if="i === 0"
            :rowspan="gainsByLesson.gains.length"
            :class="{ 'border-bottom': lessonIndex !== gains.length - 1 }"
          >
            <div>
              <span>{{ gain.DERSAD }}</span>
            </div>
          </th>
          <th>{{ gain.RN }}</th>
          <td>{{ gain.D }}</td>
          <td>-</td>
          <td>%1</td>
          <td>{{ gain.KAZANIM }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "g-table",
  props: {
    gains: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  vertical-align: middle;
}
th.rotate > div {
  transform: rotate(270deg);
  width: 30px;
}
</style>
