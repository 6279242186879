<template>
  <BaseReportTemplate hide-logo headerLeftTitle="LGS Başarı İzleme Karnesi">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col md="6" lg="4">
            <b-row v-if="doughnutLgsData.length">
              <b-col cols="6" class="mb-3" v-for="(result, i) of doughnutLgsData" :key="i">
                <DoughnutChart
                  :chart-data="result.chartData()"
                  :title="result.title"
                  :cutout-percentage="60"
                  :styles="{ height: '130px', position: 'relative' }"
                />
                <NetIcon
                  class="mt-2"
                  :student-net="result.studentNet"
                  :average-net="result.averageNet"
                />
              </b-col>
            </b-row>
            <b-row v-else>
              <h4 class="px-3">
                Derslere ait netlerin listelenmesi için yukarıdaki formdan sınav seçimi yapmalısnız
              </h4>
            </b-row>
          </b-col>
          <b-col md="6" lg="4" :class="generalDataBorder">
            <p v-if="!generalDoughnutModel">Önce sınav seçimi yapın</p>
            <template v-else>
              <DoughnutChart
                :chart-data="generalDoughnutModel.chartData()"
                :title="generalDoughnutModel.title"
                :cutout-percentage="65"
                :inner-font-size="ptToPx(70)"
                :slice-font-size="22"
                :styles="{ height: '250px', position: 'relative' }"
              />
              <NetIcon
                class="mt-5"
                variant="large"
                :student-net="generalDoughnutModel.studentNet"
                :average-net="generalDoughnutModel.averageNet"
              />
              <template v-if="showInfoBoxUnderGeneralData">
                <Jumbotron background class="mt-5">
                  <slot>
                    <p>Sınavda;</p>
                    <p>
                      {{ generalDoughnutModel.wrong }} yanlış yaparak<br />
                      <b>{{ studentScore }}</b> puan aldın.
                    </p>
                    <p>
                      Eğer, bu yanlışları yapmasaydın <br /><b>{{ studentFutureScore }}</b> puan
                      alabilirdin.
                    </p>
                    <p>
                      <br />
                      Unutma ki, milyonların girdiği bir sınavda binde birlik puanla 1000 kişiyi
                      geçebilirsin.
                    </p>
                  </slot>
                </Jumbotron>
              </template>
            </template>
          </b-col>
          <b-col cols="12" class="d-none d-md-block d-lg-none">
            <hr class="w-90 mb-5 mt-3" />
          </b-col>
          <b-col md="12" lg="4">
            <hr class="w-100 mx-auto mt-2 mb-3 d-block d-md-none" />
            <b-row>
              <b-col md="6" lg="12" :class="labelContainerClass">
                <b-row>
                  <b-col cols="10">
                    <Label
                      left="SINAV ADI"
                      :style="styles.labels.examName"
                      :left-style="styles.labels.common.left"
                      :right-style="styles.labels.common.right"
                      :right="examName"
                      :right-side-percent="50"
                      :icon-props="iconProps.examName"
                    ></Label>
                  </b-col>
                  <b-col cols="2">
                    <Icon :src="printerSvg" :height="styles.printIcon"></Icon>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="6">
                    <Label
                      class="w-100"
                      left="1.OTURUM"
                      :left-style="styles.labels.common.left"
                      :right-style="styles.labels.common.right"
                      :right="session1"
                      :icon-props="iconProps.session"
                      :style="styles.labels.examName"
                    ></Label>
                  </b-col>
                  <b-col cols="6">
                    <Label
                      class="w-100"
                      left="2.OTURUM"
                      :left-style="styles.labels.common.left"
                      :right-style="styles.labels.common.right"
                      :right="session2"
                      :icon-props="iconProps.session"
                      :style="styles.labels.examName"
                    ></Label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <Label
                      class="mt-3 w-100"
                      :style="styles.labels.scores.container"
                      size="large"
                      color="orange"
                      left="SINAV PUANIM"
                      left-position="center"
                      :left-style="styles.labels.scores.text"
                      :right="studentScore"
                      :right-style="styles.labels.scores.text"
                      right-position="center"
                    ></Label>
                  </b-col>
                  <b-col cols="12">
                    <Label
                      class="w-100"
                      :style="styles.labels.scores.container"
                      size="large"
                      color="blue"
                      left="Genel Puan Ort."
                      left-position="center"
                      :left-style="styles.labels.scores.text"
                      :right="averageScore"
                      :right-style="styles.labels.scores.text"
                      right-position="center"
                    ></Label>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="6">
                    <div class="d-flex flex-column">
                      <Label
                        v-for="(attendance, i) in attendances"
                        :key="`att_${i}`"
                        :class="attendance.class"
                        :left="attendance.left"
                        :left-style="attendance.leftStyle"
                        :right="attendance.right"
                        :right-style="attendance.rightStyle"
                        :right-position="attendance.rightPosition"
                        :color="attendance.color"
                        :icon-props="attendance.iconProps"
                        :style="{ marginBottom: '6px', height: '22.21px' }"
                      ></Label>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="d-flex flex-column">
                      <Label
                        v-for="(rank, i) in ranks"
                        :key="`rank_${i}`"
                        :class="rank.class"
                        :left="rank.left"
                        :left-style="rank.leftStyle"
                        :right="rank.right"
                        :right-style="rank.rightStyle"
                        :right-position="rank.rightPosition"
                        :color="rank.color"
                        :icon-props="rank.iconProps"
                        :style="{ marginBottom: '6px', height: '22.21px' }"
                      ></Label>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" v-if="showInfoBoxAboveCompBar">
                <div class="d-flex justify-content-center">
                  <InfoBox
                    class="mb-0"
                    :wrong="generalDoughnutModel && generalDoughnutModel.wrong"
                    :score="studentScore"
                    :future-score="studentFutureScore"
                  ></InfoBox>
                </div>
              </b-col>
              <b-col md="6" lg="12" class="p-0">
                <b-row class="d-none d-lg-block d-xl-none">
                  <b-col>
                    <hr class="w-90 mt-3" />
                  </b-col>
                </b-row>
                <b-row class="h-100">
                  <hr class="w-90 mx-auto mb-1 d-block d-md-none d-lg-block" />
                  <b-col class="h-100 d-flex align-items-end">
                    <BarChart
                      title="Karşılaştırma Grafiği (Net)"
                      :chart-data="barLgsData"
                      :styles="styles.comparisonChart"
                    ></BarChart>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mt-5">
        <ProgressChart :percent="studentPercent" :top="studentScore"></ProgressChart>
      </b-col>
    </b-row>
    <hr />
    <template slot="legend">
      <b-row class="align-items-center">
        <b-col cols="12" md="auto" class="mb-2">
          <TrueFalseLegend></TrueFalseLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <NetLegend></NetLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <ProgressLegend></ProgressLegend>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Icon from "@/components/icons/icon.component.vue";
import Label from "@/components/label.component.vue";
import barLgsData from "@/common/mock/bar-lgs.json";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import bookSvg from "@/assets/media/icons/svg/Karnem/lgs/book.svg";
import checkCircleSvg from "@/assets/media/icons/svg/Karnem/lgs/check-circle.svg";
import studentChairSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg";
import schoolWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/school-white.svg";
import studentsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/students-white.svg";
import barsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/bars-white.svg";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { DoughnutChartModel, BarChartModel } from "@/common/models";
import SbsStudentService from "@/common/services/api-parsers/sbs/student.service";
import { byDeviceWidth } from "@/common/utils";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";

export default {
  name: "lgs-front",
  mixins: [ptToPxMixin, FormStoreMixin],
  components: {
    BaseReportTemplate,
    BarChart,
    DoughnutChart,
    Label,
    NetLegend,
    ProgressChart,
    ProgressLegend,
    NetIcon,
    TrueFalseLegend,
    Icon,
    Jumbotron
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      printerSvg,
      studentChairSvg,
      selectedExamResults: null,
      doughnutLgsData: [],
      studentScore: 0,
      studentPercent: 1,
      studentFutureScore: 0,
      averageScore: 0,
      attendances: [],
      ranks: [],
      examName: "",
      session1: "",
      session2: "",
      barLgsData,
      generalDoughnutModel: null
    };
  },
  methods: {
    renderNetsByLesson(netsByLesson) {
      this.doughnutLgsData = netsByLesson.map(data => {
        return new DoughnutChartModel(data);
      });
    },
    renderGeneralData(generalData) {
      this.generalDoughnutModel = new DoughnutChartModel({
        title: "Genel Başarı",
        correct: generalData.correct,
        wrong: generalData.wrong,
        empty: generalData.empty,
        studentNet: generalData.studentNet,
        averageNet: generalData.averageNet,
        correctnessPercent: generalData.correctnessPercent
      });
    },
    renderAttendance(attendance) {
      const commonProps = {
        class: "mb-1 w-100",
        left: "",
        leftStyle: this.styles.labels.common.font,
        right: "",
        rightStyle: this.styles.labels.attendance.rightText,
        rightPosition: "right",
        color: "blue",
        iconProps: null
      };

      this.attendances = [
        {
          ...commonProps,
          left: "Sınıf Katılım",
          right: attendance.class,
          iconProps: this.iconProps.classAttendance
        },
        {
          ...commonProps,
          left: "Kurum Katılım",
          right: attendance.corp,
          iconProps: this.iconProps.corpAttendance
        },
        {
          ...commonProps,
          left: "Genel Katılım",
          right: attendance.general,
          iconProps: this.iconProps.generalAttendance
        }
      ];
    },
    renderRank(rank) {
      const commonProps = {
        class: "mb-1 w-100",
        left: "",
        leftStyle: this.styles.labels.common.font,
        right: "",
        rightStyle: this.styles.labels.attendance.rightText,
        rightPosition: "right",
        color: "orange",
        iconProps: this.iconProps.ranks
      };

      this.ranks = [
        { ...commonProps, left: "Sınıf Sırası", right: rank.class },
        { ...commonProps, left: "Kurum Sırası", right: rank.corp },
        { ...commonProps, left: "Genel Sırası", right: rank.general }
      ];
    },
    renderNetComparison(netsByLesson, prevExamByLesson) {
      if (!prevExamByLesson) return;

      let barLgsData = netsByLesson.map(selectedExamResult => {
        const prevExamResult = prevExamByLesson.find(
          lesson => lesson.title === selectedExamResult.title
        );

        return {
          title: selectedExamResult.title,
          dataset: [
            {
              label: prevExamResult.meta.SINAVADI,
              data: prevExamResult.studentNet
            },
            {
              label: selectedExamResult.meta.SINAVAD,
              data: selectedExamResult.studentNet
            },
            {
              label: "Genel",
              data: selectedExamResult.averageNet
            }
          ]
        };
      });
      barLgsData = new BarChartModel(barLgsData).chartData();
      this.barLgsData = barLgsData;
    },
    async makeRequests() {
      const { exam, student } = this.form;
      if (!exam) {
        return;
      }
      const {
        examName,
        averageScore,
        netsByLesson,
        studentScore,
        studentFutureScore,
        studentPercent,
        general,
        sessions,
        attendance,
        rank,
        prevExamByLesson
      } = await SbsStudentService.frontExamReport({
        userId: student,
        examId: exam
      });

      this.examName = examName;
      this.averageScore = averageScore;
      this.studentScore = studentScore;
      this.studentFutureScore = studentFutureScore;
      this.studentPercent = studentPercent;
      this.session1 = sessions[0] || "";
      this.session2 = sessions[1] || "";

      this.renderGeneralData(general);
      this.renderNetsByLesson(netsByLesson);
      this.renderAttendance(attendance);
      this.renderRank(rank);
      this.renderNetComparison(netsByLesson, prevExamByLesson);
    }
  },
  computed: {
    exam() {
      return this.form.exam;
    },
    styles() {
      return {
        labels: {
          common: {
            left: {
              fontSize: "14px"
            },
            right: {
              fontSize: "15px",
              fontFamily: "GothamCondensedBold"
            }
          },
          examName: {
            width: "220px",
            height: "25px"
          },
          session: {
            width: "160px",
            height: "25px"
          },
          attendance: {
            rightText: byDeviceWidth({
              desktop: {
                fontSize: "13px"
              }
            })
          },
          scores: {
            container: {
              height: "27px",
              marginBottom: "6px"
            },
            text: {
              fontSize: "19px"
            }
          }
        },
        comparisonChart: byDeviceWidth({
          desktop: {
            height: "300px",
            width: "348px",
            position: "relative"
          },
          tablet: {
            height: "300px",
            width: "320px",
            position: "relative"
          },
          mobile: {
            height: "320px",
            width: "348px",
            position: "relative"
          }
        }),
        printIcon: byDeviceWidth({
          desktop: 30,
          tablet: 30,
          mobile: 25
        })
      };
    },
    iconProps() {
      const heights = {
        desktop: 15
      };
      return {
        examName: byDeviceWidth(
          {
            desktop: {
              src: bookSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        session: byDeviceWidth(
          {
            desktop: {
              src: checkCircleSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        classAttendance: byDeviceWidth(
          {
            desktop: {
              src: studentChairSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        corpAttendance: byDeviceWidth(
          {
            desktop: {
              src: schoolWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        generalAttendance: byDeviceWidth(
          {
            desktop: {
              src: studentsWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        ranks: byDeviceWidth(
          {
            desktop: {
              src: barsWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        )
      };
    },
    generalDataBorder() {
      return byDeviceWidth({
        desktop: "border-left border-right",
        tablet: "border-left"
      });
    },
    showInfoBoxUnderGeneralData() {
      return byDeviceWidth({
        desktop: true,
        tablet: true,
        mobile: false
      });
    },
    showInfoBoxAboveCompBar() {
      return !this.showInfoBoxUnderGeneralData;
    },
    labelContainerClass() {
      return byDeviceWidth({
        desktop: [],
        tablet: ["border-right"],
        mobile: []
      });
    }
  }
};
</script>
