<template>
  <table class="table table-borderless" :style="tableStyle">
    <tbody>
      <tr v-for="(item, i) in items" :key="i">
        <td :style="rankStyle" class="text-center">{{ item.rank }}</td>
        <td>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col :style="nameStyle" class="ml-3 border-bottom">
                  {{ item.name }}
                </b-col>
                <b-col class="text-right border-bottom" :style="nameStyle">
                  {{ item.score }}
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <span :style="schoolStyle" class="ml-3">
                {{ item.school }}
              </span>
            </b-col>
          </b-row>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "podium-table",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    tableStyle() {
      return {
        fontFamily: "GothamCondensedBold",
        color: this.theme.colors.GRAY
      };
    },
    nameStyle() {
      return {
        fontSize: "2.2em"
      };
    },
    rankStyle() {
      return {
        verticalAlign: "middle",
        backgroundColor: this.theme.colors.GRAY,
        color: this.theme.colors.WHITE,
        fontSize: "4em",
        borderRadius: "7px"
      };
    },
    schoolStyle() {
      return {
        fontFamily: "GothamNarrowBook",
        fontSize: "1.1em"
      };
    }
  }
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 20px;
}
td {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
