import { SinavAPI } from "@/common/api";

function sortGains(a, b) {
  if (a.RN < b.RN) return -1;
  return 1;
}

function mapLesson({ index, data }) {
  const pairs = {
    correct: `BL${index}D`,
    wrong: `BL${index}Y`,
    empty: `BL${index}B`,
    correctnessPercent: `BL${index}ZN`,
    studentNet: `BL${index}N`,
    averageNet: `BL${index}NG`
  };

  const result = {};
  Object.keys(pairs).forEach(pairKey => {
    const dataKey = pairs[pairKey];
    result[pairKey] = data[dataKey];
  });

  return result;
}

function parseNetData(studentData, generalData) {
  const lessonList = studentData.Dersler.map(lesson => {
    const { AD, BOLUMNO } = lesson;

    return {
      title: AD,
      index: BOLUMNO
    };
  });

  const data = { ...studentData, ...generalData };

  return lessonList.map(lesson => {
    const lessonData = mapLesson({ index: lesson.index, data });
    return {
      ...lessonData,
      title: lesson.title,
      correctnessPercent: parseInt(lessonData.correctnessPercent),
      meta: data,
      section: lesson.index
    };
  });
}

function getAnswer(studentAnswerBooks, gainObj) {
  const book = studentAnswerBooks.find(b => b.KITAPCIK === gainObj.KITAPCIK) || {};
  const index = gainObj.RN - 1;
  let answerList = "";

  switch (gainObj.BOLUM) {
    case 1:
      answerList = book.BL1A;
      break;
    case 2:
      answerList = book.BL2A;
      break;
    case 3:
      answerList = book.BL3A;
      break;
    case 4:
      answerList = book.BL4A;
      break;
    case 5:
      answerList = book.BL5A;
      break;
    case 6:
      answerList = book.BL6A;
      break;
    default:
  }

  return answerList[index];
}

export default {
  async netsByLesson({ userId, examId }) {
    const [studentData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);

    return parseNetData(studentData, generalData);
  },

  async frontExamReport({ userId, examId }) {
    const [studentData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);
    const [prevExamData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 2);
    const [session1, session2] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 4);

    const {
      GNLPUAN700,
      GNLPUAN700_YD,
      TD,
      TY,
      TB,
      TN,
      TZ,
      KATILIMOKUL,
      KATILIMSINIF,
      GNLPUAN700SIS,
      GNLPUAN700OKS,
      GNLPUAN700GES,
      GNLPUAN700_YANLISSIZ
    } = studentData;
    const { GN, SINAVAD, GNLPUAN700GEO, SINAV_KATILIM } = generalData;
    const netsByLesson = parseNetData(studentData, generalData);
    // const prevExamByLesson = prevExamData && prevExamData.ID_SINAV ? parseNetData(prevExamData, {}) : null;
    const prevExamByLesson = prevExamData.ID_SINAV ? parseNetData(prevExamData, {}) : null;


    return {
      examName: SINAVAD,
      netsByLesson,
      prevExamByLesson,
      studentScore: GNLPUAN700,
      studentFutureScore: GNLPUAN700_YANLISSIZ,
      studentPercent: GNLPUAN700_YD,
      averageScore: GNLPUAN700GEO,
      general: {
        correct: TD,
        wrong: TY,
        empty: TB,
        studentNet: TN,
        correctnessPercent: TZ,
        averageNet: GN
      },
      sessions: [session1.KITAPCIK, session2.KITAPCIK],
      attendance: {
        class: KATILIMSINIF,
        corp: KATILIMOKUL,
        general: SINAV_KATILIM
      },
      rank: {
        class: GNLPUAN700SIS,
        corp: GNLPUAN700OKS,
        general: GNLPUAN700GES
      }
    };
  },

  async backExamReport({ userId, examId }) {
    const nets = await this.netsByLesson({ userId, examId });
    const gains = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 6);
    const studentAnswerBooks = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 4);

    const gainsByLesson = nets.map(lessonNet => {
      return {
        ...lessonNet,
        gains: gains
          .filter(gain => {
            return gain.BOLUM === lessonNet.section;
          })
          .sort(sortGains)
          .map(gain => {
            return {
              index: gain.RN,
              isCorrect: !!gain.D,
              answer: getAnswer(studentAnswerBooks, gain),
              correctRatio: gain.D ? 100 : 0,
              gain: gain.KAZANIM
            };
          })
      };
    });

    return gainsByLesson.sort((a, b) => b.gains.length - a.gains.length);
  }
};
