<template>
  <b-form>
    <b-tabs small v-model="tabIndex">
      <b-tab title="Öğrenci Karneleri">
        <template v-if="isAllowedTo(ROLES.MANAGER)">
          <b-form-group label="Dönem:" label-for="donem">
            <b-form-select
              id="donem"
              :value="form.donem"
              :options="donems"
              :value-field="fields.donem.value"
              :text-field="fields.donem.text"
              @input="onInputDonem"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>{{
                  firstSelectOptionMsg
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Kurum:" label-for="company1">
            <b-form-select
              id="company1"
              :value="form.corp"
              :options="corps"
              :value-field="fields.corp.value"
              :text-field="fields.corp.text"
              @input.native="onInputCorp($event)"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Şube:" label-for="branch">
            <b-form-select
              id="branch"
              :value="form.branch"
              :options="branches"
              :value-field="fields.branch.value"
              :text-field="fields.branch.text"
              @input.native="onInputBranch($event)"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Sınıf:" label-for="class">
            <b-form-select
              id="class"
              :value="form.class"
              :options="classes"
              :value-field="fields.class.value"
              :text-field="fields.class.text"
              @input.native="onInputClass($event)"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </template>
        <template v-if="isAllowedTo(ROLES.MANAGER, ROLES.PARENT)">
          <b-form-group label="Öğrenci:" label-for="student">
            <b-form-select
              id="student"
              :value="form.student"
              :options="students"
              :value-field="fields.student.value"
              :text-field="fields.student.text"
              @input="onInputStudent"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </template>
        <template v-if="isAllowedTo(ROLES.MANAGER, ROLES.PARENT, ROLES.STUDENT)">
          <b-form-group label="Sınav Türü:" label-for="exam-type">
            <b-form-select
              id="exam-type"
              :value="form.examType"
              :options="examTypes"
              :value-field="fields.examType.value"
              :text-field="fields.examType.text"
              @input.native="onInputExamType($event)"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Sınav:"
            label-for="exam"
            description="Girilmeyen sınavlar seçilemez."
          >
            <b-form-select
              id="exam"
              :value="form.exam"
              :options="exams"
              :value-field="fields.exam.value"
              :text-field="fields.exam.text"
              @input.native="onInputExam($event)"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  {{ firstSelectOptionMsg }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </template>
      </b-tab>
      <b-tab title="Sınıf Karneleri" v-if="isAllowedTo(ROLES.MANAGER)">
        <b-form-group label="Dönem:" label-for="donem">
          <b-form-select
            id="donem"
            :value="form.donem"
            :options="donems"
            :value-field="fields.donem.value"
            :text-field="fields.donem.text"
            @input="onInputDonem"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{
                firstSelectOptionMsg
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Kurum:" label-for="company2">
          <b-form-select
            id="company2"
            :value="form.corp"
            :options="corps"
            :value-field="fields.corp.value"
            :text-field="fields.corp.text"
            @input.native="onInputCorp($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Şube:" label-for="branch">
          <b-form-select
            id="branch"
            :value="form.branch"
            :options="branches"
            :value-field="fields.branch.value"
            :text-field="fields.branch.text"
            @input.native="onInputBranch($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Sınıf:" label-for="class">
          <b-form-select
            id="class"
            :value="form.class"
            :options="classes"
            :value-field="fields.class.value"
            :text-field="fields.class.text"
            @input.native="onInputClass($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Sınav Türü:" label-for="exam-type">
          <b-form-select
            id="exam-type"
            :value="form.examType"
            :options="examTypes"
            :value-field="fields.examType.value"
            :text-field="fields.examType.text"
            @input.native="onInputExamType($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Sınav:" label-for="exam">
          <b-form-select
            id="exam"
            :value="form.exam"
            :options="exams"
            :value-field="fields.exam.value"
            :text-field="fields.exam.text"
            @input.native="onInputExam($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-tab>
      <b-tab title="Kurum Karneleri" v-if="isAllowedTo(ROLES.MANAGER)">
        <b-form-group label="Dönem:" label-for="donem">
          <b-form-select
            id="donem"
            :value="form.donem"
            :options="donems"
            :value-field="fields.donem.value"
            :text-field="fields.donem.text"
            @input="onInputDonem"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{
                firstSelectOptionMsg
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Kurum:" label-for="company3">
          <b-form-select
            id="company3"
            :value="form.corp"
            :options="corps"
            :value-field="fields.corp.value"
            :text-field="fields.corp.text"
            @input.native="onInputCorp($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Şube:" label-for="branch">
          <b-form-select
            id="branch"
            :value="form.branch"
            :options="branches"
            :value-field="fields.branch.value"
            :text-field="fields.branch.text"
            @input.native="onInputBranch($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Sınav Türü:" label-for="exam-type">
          <b-form-select
            id="exam-type"
            :value="form.examType"
            :options="examTypes"
            :value-field="fields.examType.value"
            :text-field="fields.examType.text"
            @input.native="onInputExamType($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Sınav:" label-for="exam">
          <b-form-select
            id="exam"
            :value="form.exam"
            :options="exams"
            :value-field="fields.exam.value"
            :text-field="fields.exam.text"
            @input.native="onInputExam($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ firstSelectOptionMsg }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";

import { ROLES, REPORT_VARIANTS } from "@/common/config";
import FormStoreMixin, { EventBus } from "@/components/mixins/form.mixin";

import Cookie from "js-cookie";

export default {
  name: "ListReportFormComponent",
  mixins: [FormStoreMixin],
  data() {
    return {
      ROLES,
      REPORT_VARIANTS,
      tabIndex: 0,
      firstSelectOptionMsg: "Lütfen seçim yapın",
      fields: {
        donem: {
          value: "DONEMKOD",
          text: "AD"
        },
        corp: {
          value: "ID_KURUM",
          text: "AD"
        },
        branch: {
          value: "ID_SUBE",
          text: "AD"
        },
        class: {
          value: "ID_SINIF",
          text: "AD"
        },
        student: {
          value: "ID_KULLANICI",
          text: "ADSOYAD"
        },
        examType: {
          value: "ID_SINAVTUR",
          text: "KISAAD"
        },
        exam: {
          value: "ID_SINAVBILGI",
          text: "AD"
        }
      },
      selected: {
        donem: null,
        corp: null,
        branch: null,
        class: null,
        student: null,
        examType: null,
        exam: null
      }
    };
  },
  async created() {
    await this.startRequestByRole();
  },
  watch: {
    async currentUser() {
      // for development purpose
      // reload form when current user is changed
      this.startRequestByRole();
    },
    async tabIndex(index) {
      let data;

      if (index === 0) {
        data = REPORT_VARIANTS.STUDENT;
      } else if (index === 1) {
        data = REPORT_VARIANTS.CLASS;
      } else {
        data = REPORT_VARIANTS.CORP;
      }

      await this.startRequestByRole();
      this.setForm({ key: "variant", data });
    }
  },
  methods: {
    clearForm() {
      this.resetForm();
    },
    startRequestByRole() {
      this.clearForm();

      if (this.role === ROLES.STUDENT) {
        this.onInputStudent(this.currentUser.ID_KULLANICI);
        return;
      }

      if (this.role === ROLES.PARENT) {
        this.setForm({ key: "student", data: null });
        let students = this.currentUser.VelininOgrencileri || [];
        students = students.map(student => {
          const { OGRENCI_KID, OGRENCIAD, OGRENCISOYAD } = student;
          return {
            ID_KULLANICI: OGRENCI_KID,
            ADSOYAD: `${OGRENCIAD} ${OGRENCISOYAD}`,
            ...student
          };
        });
        this.setForm({ key: "students", data: students });
        return;
      }

      let AKTIF_DONEM = Cookie.get("AKTIF_DONEM") || "2020";
      this.setForm({ key: "donem", data: AKTIF_DONEM });

      // If role is MANAGER
      this.listDonems();
      this.listCorps();
    },
    isAllowedTo(...allowedRoles) {
      return allowedRoles.indexOf(this.role) !== -1;
    },
    listDonems() {
      this.listForm({ key: "donems" });
    },
    listCorps() {
      this.listForm({ key: "corps" });
    },
    onInputDonem(value) {
      Cookie.set("AKTIF_DONEM", value);
      this.setForm({ key: "corp", data: null });
      this.setForm({ key: "donem", data: value });
    },
    onInputCorp(event) {
      this.setForm({ key: "branch", data: null });
      this.setForm({ key: "corp", data: parseInt(event.target.value) });
      if (!this.form.corp) return;
      this.listForm({ key: "branches", params: { corpId: parseInt(event.target.value) } });
    },
    onInputBranch(event) {
      this.setForm({ key: "branch", data: parseInt(event.target.value) });
      switch (this.form.variant) {
        case REPORT_VARIANTS.STUDENT:
        case REPORT_VARIANTS.CLASS: {
          this.setForm({ key: "class", data: null });
          if (!event.target.value) return;
          this.listForm({ key: "classes", params: { branchId: parseInt(event.target.value) } });
          break;
        }
        case REPORT_VARIANTS.CORP: {
          if (!event.target.value) return;
          this.listForm({ key: "examTypes", params: { branchId: parseInt(event.target.value) } });
          break;
        }
      }
    },
    onInputClass(event) {
      this.setForm({ key: "class", data: parseInt(event.target.value) });
      switch (this.form.variant) {
        case REPORT_VARIANTS.STUDENT: {
          this.setForm({ key: "student", data: null });
          if (!event.target.value) return;
          this.listForm({ key: "students", params: { classId: parseInt(event.target.value) } });
          break;
        }
        case REPORT_VARIANTS.CLASS: {
          this.setForm({ key: "examType", data: null });
          if (!event.target.value) return;
          this.listForm({ key: "examTypes", params: { classId: parseInt(event.target.value) } });
          break;
        }
      }
    },
    onInputStudent(value) {
      this.setForm({ key: "examType", data: null });
      this.setForm({ key: "student", data: value });

      if (!this.form.student) return;
      this.listForm({ key: "examTypes", params: { userId: value } });
    },
    onInputExamType(event) {
      this.setForm({ key: "examType", data: parseInt(event.target.value) });
      this.setForm({ key: "exam", data: null });
      const examTypeId = parseInt(event.target.value);

      switch (this.form.variant) {
        case REPORT_VARIANTS.STUDENT: {
          if (!this.form.student) return;
          this.listForm({
            key: "exams",
            params: { userId: this.form.student, examTypeId }
          });
          break;
        }
        case REPORT_VARIANTS.CLASS: {
          if (!this.form.examType) return;
          this.listForm({
            key: "exams",
            params: { classId: this.form.class, examTypeId, branchId: this.form.branch }
          });
          break;
        }
        case REPORT_VARIANTS.CORP: {
          if (!this.form.examType) return;
          this.listForm({
            key: "exams",
            params: { examTypeId, branchId: this.form.branch }
          });
          break;
        }
      }
    },
    onInputExam(event) {
      this.setForm({ key: "exam", data: event.target.value });
    },
    onClickList() {
      EventBus.$emit("ON_SUBMIT");
    }
  },
  computed: {
    ...mapGetters(["role", "currentUser"])
  }
};
</script>
