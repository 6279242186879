<template>
  <table class="table table-borderless table-sm gains-inner-table">
    <thead>
      <tr :class="double && 'table-double-row'">
        <th></th>
        <th scope="col">S.N.</th>
        <th scope="col">C</th>
        <th scope="col">A</th>
        <th scope="col">Y.O.</th>
        <th scope="col">Konu Dağılımı</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(gainsByLesson, lessonIndex) of gains">
        <tr v-for="(gain, i) of gainsByLesson.gains" :key="`${gain.RN}_${gain.DERSAD}`">
          <th
            scope="row"
            class="rotate"
            v-if="i === 0"
            :rowspan="gainsByLesson.gains.length"
            :class="{ 'border-bottom': lessonIndex !== gains.length - 1 }"
          >
            <div>
              <span>{{ gain.DERSAD }}</span>
            </div>
          </th>
          <td>{{ gain.RN }}</td>
          <td>{{ (gain.D && "+") || (gain.Y && "-") || (gain.B && "-") }}</td>
          <td>{{ gain.ANSWER }}</td>
          <td>%{{ gain.YO.toFixed(0) }}</td>
          <td class="treedot">
            <p :data-title="gain.KAZANIM">
              <span>{{ truncate(gain.KAZANIM) }}</span>
            </p>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { byDeviceWidth } from "@/common/utils";

export default {
  name: "g-table",
  props: {
    double: {
      type: Boolean,
      required: false
    },
    gains: {
      type: Array,
      required: true
    }
  },
  methods: {
    truncate(s) {
      let limit = byDeviceWidth({
        desktop: 95,
        tablet: 90,
        mobile: 70
      });
      if (s.length < limit) {
        return s;
      }

      const words = s.split(" ");
      limit = limit - words.length;

      const newWords = [];
      let total = 0;
      words.forEach(word => {
        total += word.length;
        if (total < limit) {
          newWords.push(word);
        }
      });

      const newWord = newWords.join(" ");
      return `${newWord}...`;
    }
  }
};
</script>

<style lang="scss" scoped>
.treedot {
  span {
    white-space: nowrap;
  }
  p {
    position: relative;
    margin-bottom: 0;
    width: 100%;
    &:before {
      content: attr(data-title);
      position: absolute;
      bottom: 100%;
      left: 0%;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 6px;
      z-index: 1;
      opacity: 0;
      transition: all 250ms;
      padding: 4px;
      color: white;
      text-align: center;
      white-space: normal;
      pointer-events: none;
      font-size: 12px;
      line-height: 1.2;
    }
    &:after {
      z-index: 1;
      opacity: 0;
      content: "";
      position: absolute;
      left: 10px;
      bottom: 100%;
      transform: translateY(100%);
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid rgba(0, 0, 0, 0.8);
    }
  }
  &:hover {
    p::after,
    p::before {
      opacity: 1;
    }
  }
}
table.table-sm {
  width: max-content;
  th,
  td {
    font-family: "GothamCondensedBook";
    font-size: 8.66px;
    color: black;
    padding: 0 1px !important;
    height: auto;
    vertical-align: middle;
    width: 13px;
    &:last-child {
      padding-left: 15px !important;
    }
  }
  th {
    font-family: "GothamCondensedBold";
    font-weight: 400;
    &.rotate {
      white-space: nowrap;
      vertical-align: middle;

      & > div {
        transform: rotate(270deg);
        width: 25px;
      }
    }
  }
  tbody {
    th {
      text-align: center;
    }
  }

  @media (max-width: 767.99px) {
    tr.table-double-row {
      opacity: 0;
    }
  }
}
</style>
