<script>
import { Bar } from "vue-chartjs";
import { mapGetters } from "vuex";

import { ptToPx } from "@/common/utils";

export default {
  extends: Bar,
  props: {
    title: {
      type: String,
      default: ""
    },
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    stepSize: {
      type: Number,
      default: 5
    },
    max: {
      type: Number,
      default: 20
    },
    colors: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.formattedChartData, this.fullOptions);
  },
  watch: {
    chartData() {
      this.renderChart(this.formattedChartData, this.fullOptions);
    }
  },
  methods: {
    mapChartData(data) {
      let colors = this.colors ? this.colors : this.theme.charts.bar.colors;
      const { datasets = [] } = data;
      const newDatasets = datasets.map((dataset, i) => {
        return {
          ...dataset,
          barPercentage: .8,
          categoryPercentage:.9,
          backgroundColor: colors[i] || colors[0]
        };
      });

      return {
        ...data,
        datasets: newDatasets
      };
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    fullOptions() {
      return {
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            color: this.theme.colors.GRAY,
            rotation: -90,
            font: {
              size: 11,
              family: "GothamNarrowBook"
            }
          }
        },
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 15,
            fontFamily: "GothamCondensedBook",
            fontSize: ptToPx(11, true)
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          padding: 0,
          text: ""
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: this.stepSize,
                max: this.max,
                min: 0
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 13,
                fontFamily: "GothamCondensedBook"
              }
            }
          ]
        },
        ...this.options
      };
    },
    formattedChartData() {
      return this.chartData ? this.mapChartData(this.chartData) : this.chartData;
    }
  }
};
</script>
