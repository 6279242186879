<template>
  <b-jumbotron :class="background && 'info'" :style="background && style">
    <slot></slot>
  </b-jumbotron>
</template>

<script>
import info from "@/assets/media/icons/info.svg";

export default {
  name: "jumbotron",
  props: {
    background: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      return {
        backgroundImage: `url(${info})`
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.jumbotron {
  font-family: "GothamNarrowBook";
  color: rgb(128, 129, 132);
  &.info {
    background-position: calc(100% - 6px) 8px;
    background-size: 40px;
  }
}
</style>
