<template>
  <b-tabs class="kt-hide-tabs">
    <b-tab
      title="Sınav Sonuç Karnesi (Ön)"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.checkupFront)"
      @click="() => showTab(TABS.student.checkupFront)"
    >
      <StudentLGSFrontReport v-if="isActiveTab(TABS.student.checkupFront)"></StudentLGSFrontReport>
    </b-tab>
    <b-tab
      title="Sınav Sonuç Karnesi (Arka)"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.checkupBack)"
      @click="() => showTab(TABS.student.checkupBack)"
    >
      <StudentLGSBackReport v-if="isActiveTab(TABS.student.checkupBack)"></StudentLGSBackReport>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.gainsTree)"
      @click="() => showTab(TABS.student.gainsTree)"
    >
      <GainsTreeReport v-if="isActiveTab(TABS.student.gainsTree)"></GainsTreeReport>
    </b-tab>
    <b-tab
      title="Gelişim İzleme"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.comparison)"
      @click="() => showTab(TABS.student.comparison)"
    >
      <ComparisonReport v-if="isActiveTab(TABS.student.comparison)"></ComparisonReport>
    </b-tab>
    <b-tab
      title="Sınav Raporu"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.examReport)"
      @click="() => showTab(TABS.corporation.examReport)"
    >
      <CExamReport v-if="isActiveTab(TABS.corporation.examReport)"></CExamReport>
    </b-tab>
    <b-tab
      title="Soru Analizi"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.questionAnalyze)"
      @click="() => showTab(TABS.corporation.questionAnalyze)"
    >
      <CQuestionAnalyze
        v-if="isActiveTab(TABS.corporation.questionAnalyze)"
        :variant="REPORT_VARIANTS.CORP"
      ></CQuestionAnalyze>
    </b-tab>
    <b-tab
      title="Sınav Raporu (Ön)"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelExamReportFront)"
      @click="() => showTab(TABS.corporation.classLevelExamReportFront)"
    >
      <CExamReport
        variant="class"
        v-if="isActiveTab(TABS.corporation.classLevelExamReportFront)"
      ></CExamReport>
    </b-tab>
    <b-tab
      title="Sınav Raporu (Arka)"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelExamReportBack)"
      @click="() => showTab(TABS.corporation.classLevelExamReportBack)"
    >
      <CQuestionAnalyze
        v-if="isActiveTab(TABS.corporation.classLevelExamReportBack)"
      ></CQuestionAnalyze>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.gainsTree)"
      @click="() => showTab(TABS.corporation.gainsTree)"
    >
      <GainsTreeReport
        v-if="isActiveTab(TABS.corporation.gainsTree)"
        variant="corp"
      ></GainsTreeReport>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelGainsTree)"
      @click="() => showTab(TABS.corporation.classLevelGainsTree)"
    >
      <GainsTreeReport
        v-if="isActiveTab(TABS.corporation.classLevelGainsTree)"
        variant="class"
      ></GainsTreeReport>
    </b-tab>
    <b-tab
      title="Podyum (İlk 10)"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.podium10)"
      @click="() => showTab(TABS.corporation.podium10)"
    >
      <PodiumReport v-if="isActiveTab(TABS.corporation.podium10)" :items="10"></PodiumReport>
    </b-tab>
    <b-tab
      title="Podyum (İlk 3)"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.podium3)"
      @click="() => showTab(TABS.corporation.podium3)"
    >
      <PodiumReport v-if="isActiveTab(TABS.corporation.podium3)"></PodiumReport>
    </b-tab>
    <b-tab
      title="Periyodik Doğru Tablosu"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.periodicTable)"
      @click="() => showTab(TABS.corporation.periodicTable)"
    >
      <CPeriodicTable v-if="isActiveTab(TABS.corporation.periodicTable)"></CPeriodicTable>
    </b-tab>
  </b-tabs>
</template>

<script>
import StudentLGSFrontReport from "@/components/reports/sbs/student/lgs-front.component.vue";
import StudentLGSBackReport from "@/components/reports/sbs/student/lgs-back.component.vue";
// import GainsTreeReport from "@/components/reports/sbs/common/gains-tree.component.vue";
import GainsTreeReport from "@/components/reports/gains/gains-tree.vue";
import ComparisonReport from "@/components/reports/sbs/student/comparison.component.vue";
import CExamReport from "@/components/reports/sbs/corporation/exam-report.component.vue";
import CQuestionAnalyze from "@/components/reports/sbs/corporation/question-analyze.component.vue";
import CPeriodicTable from "@/components/reports/sbs/corporation/periodic-table.component.vue";
import PodiumReport from "@/components/reports/sbs/common/podium.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { REPORT_VARIANTS } from "@/common/config";
import podiumData from "@/common/mock/podium.json";

const TABS = {
  student: {
    checkupFront: "checkupFront",
    checkupBack: "checkupBack",
    gainsTree: "gainsTree",
    comparison: "comparison"
  },
  corporation: {
    examReport: "corpExamReport",
    questionAnalyze: "corpQuestionAnalyze",
    classLevelExamReportBack: "corpClassLevelExamReportBack",
    classLevelExamReportFront: "corpClassLevelExamReportFront",
    gainsTree: "corpGainsTree",
    classLevelGainsTree: "corpClassLevelGainsTree",
    podium3: "podium3",
    podium10: "podium10",
    periodicTable: "periodicTable"
  }
};

export default {
  name: "lgs-container",
  mixins: [FormStoreMixin],
  components: {
    StudentLGSFrontReport,
    StudentLGSBackReport,
    GainsTreeReport,
    ComparisonReport,
    CExamReport,
    CQuestionAnalyze,
    PodiumReport,
    CPeriodicTable
  },
  created() {
    this.resetTab();
  },
  watch: {
    formVariant() {
      setTimeout(this.resetTab, 100);
    }
  },
  data() {
    return {
      TABS,
      REPORT_VARIANTS,
      activeTab: TABS.student.checkupFront,
      podium3: [podiumData[0], podiumData[1], podiumData[2]],
      podium10: podiumData
    };
  },
  methods: {
    isVariant(variant) {
      return this.form.variant === variant;
    },
    resetTab() {
      const variant = this.formVariant;

      if (variant === REPORT_VARIANTS.STUDENT) {
        this.showTab(TABS.student.checkupFront);
      } else if (variant === REPORT_VARIANTS.CLASS) {
        this.showTab(TABS.corporation.classLevelExamReportFront);
      } else {
        this.showTab(TABS.corporation.examReport);
      }
    },
    showTab(tabId) {
      this.activeTab = tabId;
    },
    isActiveTab(tabId) {
      return tabId === this.activeTab;
    }
  },
  computed: {
    formVariant() {
      return this.form.variant;
    }
  }
};
</script>
