<template>
  <BaseReportTemplate :headerLeftTitle="headerLeftTitle" headerLeftSubTitle="Genel">
    <template v-if="!exam">
      <b-row>
        <b-col cols="12">
          <p>Podyumu görüntülemek için sınav seçin.</p>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12" class="pt-5">
          <div class="d-flex justify-content-center align-items-end">
            <PodiumItem
              class="mr-2"
              v-for="(student, i) in podiumItems"
              :key="i"
              :height="student.height"
              :rank="student.rank"
              :name="student.name"
              :score="student.score"
              :school="student.school"
            ></PodiumItem>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-5">
          <div class="d-flex justify-content-center">
            <PodiumTable style="width: 600px" :items="tableItems"></PodiumTable>
          </div>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import PodiumItem from "@/components/reports/sbs/podium-item.component.vue";
import PodiumTable from "@/components/reports/sbs/podium-table.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import SbsCorpService from "@/common/services/api-parsers/sbs/corp.service.js";

export default {
  name: "podium",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    PodiumItem,
    PodiumTable
  },
  props: {
    items: {
      type: Number,
      default: 3
    }
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      podiumItems: [],
      tableItems: []
    };
  },
  methods: {
    bestThree(podium) {
      const bestThree = podium.slice(0, 3);
      return bestThree.map(data => {
        let height;
        if (data.rank === 1) {
          height = 370;
        } else if (data.rank === 2) {
          height = 300;
        } else {
          height = 270;
        }
        return {
          ...data,
          height
        };
      });
    },
    async makeRequests() {
      if (!this.exam) return;

      const podium = await SbsCorpService.podium({ examId: this.form.exam });

      const bestThree = this.bestThree([...podium]);
      this.podiumItems = [bestThree[1], bestThree[0], bestThree[2]];

      if (this.items > 3) {
        const remaining = this.items - 3;
        const [, , , ...tableItems] = podium;
        this.tableItems = tableItems
          .map((item, i) => {
            return {
              ...item,
              rank: i + 4
            };
          })
          .slice(0, remaining);
      }
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    headerLeftTitle() {
      return `Podyum İlk ${this.items}`;
    }
  }
};
</script>
