<template>
  <div class="d-flex flex-row py-2 px-3 rounded text-white" :style="selectStyle" @click="click">
    <div class="w-100">{{ text }}</div>
    <div class="flex-shrink-1">
      <fa icon="caret-down"></fa>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "select-ish",
  props: {
    text: {
      type: String,
      required: true
    },
    click: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    selectStyle() {
      return this.theme.styles.select;
    }
  }
};
</script>
