<template>
  <div class="kcontainer px-0">
    <KTPortlet>
      <template slot="title">
        <h3 class="kt-portlet__head-title cursor-pointer" @click="toggleListingForm">
          [Listeleme Formu]
          <fa :icon="toggleIcon" />
        </h3>
      </template>
      <template v-slot:body>
        <b-collapse :visible="isListingFormVisible">
          <ListReportForm />
        </b-collapse>
      </template>
    </KTPortlet>
    <KTPortlet v-bind:title="'Karneler'">
      <template v-slot:body>
        <LGSContainer v-if="form.examType === EXAM_TYPES.SBS"></LGSContainer>
        <TYTContainer v-if="form.examType === EXAM_TYPES.TYT"></TYTContainer>
        <YKSContainer v-if="form.examType === EXAM_TYPES.YKS"></YKSContainer>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ListReportForm from "@/components/list-report-form.component.vue";
import LGSContainer from "@/views/pages/karnem/reports/lgs.vue";
import TYTContainer from "@/views/pages/karnem/reports/tyt.vue";
import YKSContainer from "@/views/pages/karnem/reports/yks.vue";
import ToastService from "@/common/services/toast.service";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { EXAM_TYPES } from "@/common/config";

export default {
  name: "home",
  mixins: [FormStoreMixin],
  components: {
    KTPortlet,
    ListReportForm,
    LGSContainer,
    TYTContainer,
    YKSContainer
  },
  data() {
    return {
      EXAM_TYPES,
      isListingFormVisible: true
    };
  },
  mounted() {
    ToastService.onError(this.onErrorToast);
  },
  methods: {
    toggleListingForm() {
      this.isListingFormVisible = !this.isListingFormVisible;
    },
    onErrorToast({ content, options }) {
      this.$bvToast.toast(content, options);
    }
  },
  computed: {
    toggleIcon() {
      return this.isListingFormVisible ? "chevron-circle-up" : "chevron-circle-down";
    }
  }
};
</script>
