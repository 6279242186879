/*
Parse and format API for student level reports for YKS
 */
import * as _ from "lodash";

import { SinavAPI } from "@/common/api";

function parseNetsByLesson(dataByLesson, generalData) {
  const lessons = [
    "Türk Dili ve Edebiyatı",
    "Tarih-1",
    "Coğrafya-1",
    "Matematik",
    "Geometri",
    "Tarih-2",
    "Coğrafya-2",
    "Felsefe",
    "Din Kültürü ve Ahlak Bilgisi",
    "Fizik",
    "Kimya",
    "Biyoloji",
    "Yabancı Dil"
  ];

  return lessons.map((lessonName, index) => {
    const i = index + 1;
    const correctKey = `BL${i}D`;
    const wrongKey = `BL${i}Y`;
    const emptyKey = `BL${i}B`;
    const netKey = `BL${i}N`;
    const generalNetKey = `BL${i}GN`;
    const percentKey = `BL${i}Z`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      total: dataByLesson[correctKey] + dataByLesson[wrongKey] + dataByLesson[emptyKey]
    };
  });
}

function parseMainNets(dataByLesson, generalData) {
  const pairs = {
    SOS_1: {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      generalNetKey: "SOS_1"
    },
    SOS_2: {
      lesson: "Sosyal Bilimler-2",
      generalNetKey: "SOS_2"
    },
    MAT_: {
      lesson: "Matematik",
      generalNetKey: "MAT"
    },
    FEN_: {
      lesson: "Fen Bilimleri",
      generalNetKey: "FEN"
    }
  };

  return Object.keys(pairs).map(key => {
    const { lesson: lessonName } = pairs[key];
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const percentKey = `${key}Z`;

    let { generalNetKey } = pairs[key];
    generalNetKey = `${generalNetKey}GN`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      meta: { ...dataByLesson, ...generalData }
    };
  });
}

function parseScoresAndRanks(data) {
  const scienceRanks = {
    class: data.SAYSIS,
    corp: data.SAYOKLS,
    general: data.SAYGES
  };

  const socialRanks = {
    class: data.SOZSIS,
    corp: data.SOZOKLS,
    general: data.SOZGES
  };

  const equalWeightRanks = {
    class: data.EASIS,
    corp: data.EAOKLS,
    general: data.EAGES
  };

  return [
    { title: "Sayısal", score: data.SAYPUAN, ranks: scienceRanks },
    { title: "Eşit A.", score: data.EAPUAN, ranks: socialRanks },
    { title: "Sözel", score: data.SOZPUAN, ranks: equalWeightRanks }
  ];
}

function parseAttendance(data) {
  return [
    { title: "Sınıf Katılım", att: data.KATILIMSINIF },
    { title: "Kurum Katılım", att: data.KATILIMOKUL },
    { title: "Genel Katılım", att: data.SINAV_KATILIM }
  ];
}

function parseNetComparison(data) {
  return {
    turkish: data.SOS_1N,
    social: data.SOS_2N,
    math: data.MAT_N,
    science: data.FEN_N
  };
}

function chunkGains(gains) {
  return _.chunk(gains, 20);
}

function parseScoresFromGainsTree(studentData, averageData) {
  const scores = [
    {
      title: "Sayısal",
      key: "SAY"
    },
    {
      title: "Sözel",
      key: "SOZ"
    },
    {
      title: "Eşit A.",
      key: "EA"
    }
  ];

  return scores.map(score => {
    const scoreKey = `${score.key}PUAN`;
    const studentNetKey = `${score.key}N`;
    const averageNetKey = `${score.key}_GN`;

    return {
      title: score.title,
      score: studentData[scoreKey],
      studentNet: studentData[studentNetKey],
      averageNet: averageData[averageNetKey]
    };
  });
}

function parseNetsFromGainsTree(studentData, averageData) {
  const mainProps = {
    correct: 0,
    wrong: 0,
    empty: 0,
    correctnessPercent: 0
  };

  return [
    {
      ...mainProps,
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      studentNet: studentData.TRK_SecilenSNVGNLN,
      averageNet: averageData.TRK_GN
    },
    {
      ...mainProps,
      lesson: "Sosyal Bilimler-2",
      studentNet: studentData.TAR_SecilenSNVGNLN,
      averageNet: averageData.TAR_GN
    },
    {
      ...mainProps,
      lesson: "Matematik",
      studentNet: studentData.MAT_SecilenSNVGNLN,
      averageNet: averageData.MAT_GN
    },
    {
      ...mainProps,
      lesson: "Fen Bilimleri",
      studentNet: studentData.FEN_SecilenSNVGNLN,
      averageNet: averageData.FEN_GN
    }
  ];
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue.D;
        const total = accumulator.total + currentValue.SS;
        const percent = Math.floor((100 * correct) / total);

        return {
          id: currentValue[idKey],
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue.Y,
          empty: accumulator.empty + currentValue.B,
          percent,
          unitId: currentValue.ID_UNITE,
          topicId: currentValue.ID_KONU,
          gainId: currentValue.ID_KAZANIM
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(gains) {
  const gainsMappedByLesson = _.groupBy(gains, "DERSAD");

  return Object.keys(gainsMappedByLesson).map(lessonName => {
    const gainsBelongToLesson = gains.filter(gain => gain.DERSAD === lessonName);
    let units = _.groupBy(gainsBelongToLesson, "ID_UNITE");
    let topics = _.groupBy(gainsBelongToLesson, "ID_KONU");
    let _gains = _.groupBy(gainsBelongToLesson, "ID_KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ID_UNITE",
      titleKey: "UNITE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "ID_KONU",
      titleKey: "KONU"
    });
    _gains = mergeGainsBy({
      data: _gains,
      idKey: "ID_KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: _gains.filter(gain => gain.topicId === topic.id)
      };
    });
    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic.unitId === unit.id)
      };
    });

    const total = units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
    const correct = units.reduce(
      (accumulator, currentValue) => accumulator + currentValue.correct,
      0
    );
    const correctnessPercent = Math.floor((100 * correct) / total);

    return {
      lesson: lessonName,
      total,
      correct,
      wrong: units.reduce((accumulator, currentValue) => accumulator + currentValue.wrong, 0),
      empty: units.reduce((accumulator, currentValue) => accumulator + currentValue.empty, 0),
      correctnessPercent,
      units
    };
  });
}

function groupTreeDataByMainLesson(treeDataByLesson) {
  const mainLessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      subLessons: ["Türk Dili ve Edebiyatı", "Tarih-1", "Coğrafya-1"]
    },
    {
      lesson: "Sosyal Bilimler-2",
      subLessons: ["Tarih-2", "Coğrafya-2", "Felsefe", "Din Kül. ve Ahlak Bilgisi"]
    },
    {
      lesson: "Matematik",
      subLessons: ["Matematik", "Geometri"]
    },
    {
      lesson: "Fen Bilimleri",
      subLessons: ["Fizik", "Kimya", "Biyoloji"]
    }
  ];

  return mainLessons.map(mainLesson => {
    return {
      lesson: mainLesson.lesson,
      subLessons: treeDataByLesson.filter(
        treeData => mainLesson.subLessons.indexOf(treeData.lesson) !== -1
      )
    };
  });
}

function mergeTreeDataAndAverageNets(treeDataByMainLesson, averageNetsByMainLesson) {
  return treeDataByMainLesson.map(treeData => {
    const nets = averageNetsByMainLesson.find(
      netByLesson => netByLesson.lesson === treeData.lesson
    );

    return {
      ...treeData,
      ...nets
    };
  });
}

function parseScoresFromComparison(studentAverageScores, averageScoreData) {
  return {
    science: {
      score: studentAverageScores.SAYPUAN,
      studentNet: studentAverageScores.SAYNET,
      averageNet: averageScoreData.SAYGN
    },
    social: {
      score: studentAverageScores.SOZPUAN,
      studentNet: studentAverageScores.SOZNET,
      averageNet: averageScoreData.SOZGN
    },
    equal: {
      score: studentAverageScores.EAPUAN,
      studentNet: studentAverageScores.EANET,
      averageNet: averageScoreData.EAGN
    }
  };
}

function parseAverageScoresFromComparison(studentDataByExam, averageDataByExam) {
  return studentDataByExam.map(studentData => {
    const averageData = averageDataByExam.find(
      averageData => averageData.ID_SINAVBILGI === studentData.ID_SINAVBILGI
    );
    return {
      exam: studentData.SINAVAD,
      scienceScore: {
        student: studentData.SAYPUAN,
        average: averageData.SAYGEO
      },
      socialScore: {
        student: studentData.SOZPUAN,
        average: averageData.SOZGEO
      },
      equalScore: {
        student: studentData.EAPUAN,
        average: averageData.EAGEO
      }
    };
  });
}

function parseMainLessonNetsFromComparison(studentDataByExam, averageDataByExam) {
  const mainLessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      studentNetKey: "TRK_N",
      averageNetKey: "TRK_GN"
    },
    {
      lesson: "Sosyal Bilimler-2",
      studentNetKey: "TAR_N",
      averageNetKey: "TAR_GN"
    },
    {
      lesson: "Matematik",
      studentNetKey: "MAT_N",
      averageNetKey: "MAT_GN"
    },
    {
      lesson: "Fen Bilimleri",
      studentNetKey: "FEN_N",
      averageNetKey: "FEN_GN"
    }
  ];

  return mainLessons.map(mainLesson => {
    const exams = studentDataByExam.map(studentData => {
      const averageData = averageDataByExam.find(
        data => data.ID_SINAVBILGI === studentData.ID_SINAVBILGI
      );

      return {
        exam: studentData.SINAVAD,
        studentNet: studentData[mainLesson.studentNetKey],
        averageNet: averageData[mainLesson.averageNetKey]
      };
    });

    return {
      lesson: mainLesson.lesson,
      exams
    };
  });
}

function parseMainLessonAverageNetsFromComparison(studentAverageScores, averageScoreData) {
  const mainLessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      studentNetKey: "TRK_SecilenSNVGNLN",
      averageNetKey: "TRK_GN"
    },
    {
      lesson: "Sosyal Bilimler-2",
      studentNetKey: "TAR_SecilenSNVGNLN",
      averageNetKey: "TAR_GN"
    },
    {
      lesson: "Matematik",
      studentNetKey: "MAT_SecilenSNVGNLN",
      averageNetKey: "MAT_GN"
    },
    {
      lesson: "Fen Bilimleri",
      studentNetKey: "FEN_SecilenSNVGNLN",
      averageNetKey: "FEN_GN"
    }
  ];

  return mainLessons.map(lesson => {
    return {
      lesson: lesson.lesson,
      averageStudentNet: studentAverageScores[lesson.studentNetKey],
      averageGeneralNet: averageScoreData[lesson.averageNetKey]
    };
  });
}

function mergeMainLessonNetsFromComparison(mainLessons, averageNets) {
  return mainLessons.map(lesson => {
    const averageData = averageNets.find(averageData => averageData.lesson === lesson.lesson);
    return {
      ...lesson,
      ...averageData
    };
  });
}

function parseNetsByCategory(dataByLesson, generalNets) {
  const categories = [
    {
      category: "Sayısal Genel Başarı",
      key: "SAYISAL"
    },
    {
      category: "Eşit Ağırlık Genel Başarı",
      key: "EA"
    },
    {
      category: "Sözel Genel Başarı",
      key: "SOZEL"
    }
  ];

  return categories.map(category => {
    const correctKey = `${category.key}D`;
    const wrongKey = `${category.key}Y`;
    const emptyKey = `${category.key}B`;
    const correctnessPercentKey = `${category.key}Z`;
    const netKey = `${category.key}N`;
    const averageNetKey = `${category.key}GN`;

    return {
      category: category.category,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      correctnessPercent: dataByLesson[correctnessPercentKey],
      net: dataByLesson[netKey],
      averageNet: generalNets[averageNetKey]
    };
  });
}

function parsePercents(dataByLesson) {
  // [ea, soz, say]
  const { SAYYZ, SOZYZ, EAYZ } = dataByLesson;
  return [EAYZ, SOZYZ, SAYYZ];
}

function parseNetsByCategoryFromGainsTree(studentAverageData, averageData) {
  const categories = [
    {
      category: "Sayısal Genel Başarı",
      key: "SAY"
    },
    {
      category: "Eşit Ağırlık Genel Başarı",
      key: "EA"
    },
    {
      category: "Sözel Genel Başarı",
      key: "SOZ"
    }
  ];

  return categories.map(category => {
    const correctKey = `${category.key}D`;
    const wrongKey = `${category.key}Y`;
    const emptyKey = `${category.key}B`;
    const correctnessPercentKey = `${category.key}Z`;
    const netKey = `${category.key}N`;
    const averageNetKey = `${category.key}_GN`;

    return {
      category: category.category,
      correct: studentAverageData[correctKey],
      wrong: studentAverageData[wrongKey],
      empty: studentAverageData[emptyKey],
      correctnessPercent: studentAverageData[correctnessPercentKey],
      net: studentAverageData[netKey],
      averageNet: averageData[averageNetKey]
    };
  });
}

function parseNetsByLessonFromComparison({
  studentDataByExam,
  studentAverageScores,
  averageDataByExam,
  averageScoreData
}) {
  const lessons = [
    {
      lesson: "Türk Dili ve Edebiyatı",
      studentNetKey: "N_Trk",
      studentAverageNetKey: "Trk_GN",
      averageNetKey: "SNV_Trk_GN",
      generalAverageNetKey: "SNVTrk_GN"
    },
    {
      lesson: "Tarih-1",
      studentNetKey: "N_Tar_1",
      studentAverageNetKey: "Tar_1GN",
      averageNetKey: "SNV_Tar_1GN",
      generalAverageNetKey: "SNVTar_1GN"
    },
    {
      lesson: "Coğrafya-1",
      studentNetKey: "N_Cog_1",
      studentAverageNetKey: "Cog_1GN",
      averageNetKey: "SNV_Cog_1GN",
      generalAverageNetKey: "SNVCog_1GN"
    },
    {
      lesson: "Matematik",
      studentNetKey: "N_Mat",
      studentAverageNetKey: "MatGN",
      averageNetKey: "SNV_MatGN",
      generalAverageNetKey: "SNVMatGN"
    },
    {
      lesson: "Geometri",
      studentNetKey: "N_Geo",
      studentAverageNetKey: "GeoGN",
      averageNetKey: "SNV_GeoGN",
      generalAverageNetKey: "SNVGeoGN"
    },
    {
      lesson: "Tarih-2",
      studentNetKey: "N_Tar_2",
      studentAverageNetKey: "Tar_2GN",
      averageNetKey: "SNV_Tar_2GN",
      generalAverageNetKey: "SNVTar_2GN"
    },
    {
      lesson: "Coğrafya-2",
      studentNetKey: "N_Cog_2",
      studentAverageNetKey: "Cog_2GN",
      averageNetKey: "SNV_Cog_2GN",
      generalAverageNetKey: "SNVCog_2GN"
    },
    {
      lesson: "Felsefe",
      studentNetKey: "N_Fel",
      studentAverageNetKey: "FelGN",
      averageNetKey: "SNV_FelGN",
      generalAverageNetKey: "SNVFelGN"
    },
    {
      lesson: "Din Kültürü ve Ahlak Bilgisi",
      studentNetKey: "N_Din",
      studentAverageNetKey: "DinGN",
      averageNetKey: "SNV_DinGN",
      generalAverageNetKey: "SNVDinGN"
    },
    {
      lesson: "Fizik",
      studentNetKey: "N_Fiz",
      studentAverageNetKey: "FizGN",
      averageNetKey: "SNV_FizGN",
      generalAverageNetKey: "SNVFizGN"
    },
    {
      lesson: "Kimya",
      studentNetKey: "N_Kim",
      studentAverageNetKey: "KimGN",
      averageNetKey: "SNV_KimGN",
      generalAverageNetKey: "SNVKimGN"
    },
    {
      lesson: "Biyoloji",
      studentNetKey: "N_Biy",
      studentAverageNetKey: "BiyGN",
      averageNetKey: "SNV_BiyGN",
      generalAverageNetKey: "SNVBiyGN"
    }
  ];

  return lessons.map(lesson => {
    const {
      studentNetKey,
      averageNetKey,
      studentAverageNetKey,
      generalAverageNetKey,
      lesson: lessonName
    } = lesson;

    const exams = studentDataByExam.map(studentData => {
      const averageData = averageDataByExam.find(
        avg => avg.ID_SINAVBILGI === studentData.ID_SINAVBILGI
      );
      return {
        exam: studentData.SINAVAD,
        studentNet: studentData[studentNetKey],
        averageNet: averageData[averageNetKey]
      };
    });

    const average = {
      studentNet: studentAverageScores[studentAverageNetKey],
      generalNet: averageScoreData[generalAverageNetKey]
    };

    return {
      lesson: lessonName,
      exams,
      average
    };
  });
}

export default {
  async followUpReport({ examId, userId }) {
    const [dataByLesson] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalNets] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);
    let [prevExam] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 2);

    const netsByLesson = parseNetsByLesson(dataByLesson, generalNets);
    const mainNetsByLesson = parseMainNets(dataByLesson, generalNets);
    const netsByCategory = parseNetsByCategory(dataByLesson, generalNets);
    const scoresAndRanks = parseScoresAndRanks(dataByLesson);
    const attendances = parseAttendance({ ...dataByLesson, ...generalNets });
    const netComparison = parseNetComparison(dataByLesson);
    const percents = parsePercents(dataByLesson);
    const prevExamMainNetsByLesson =
      prevExam.AD === "Önceki Sinav Bilgisi Yok" ? null : parseMainNets(prevExam, {});

    return {
      netsByLesson,
      mainNetsByLesson,
      netsByCategory,
      percents,
      examName: dataByLesson.SINAVADI,
      sessionOne: dataByLesson.KITAPCIK,
      scoresAndRanks,
      attendances,
      netComparison,
      prevExamMainNetsByLesson
    };
  },

  async gains({ examId, userId }) {
    const [dataByLesson] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalNets] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);
    const gains = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 6);

    const mainNetsByLesson = parseMainNets(dataByLesson, generalNets);

    const gainsByMainLesson = mainNetsByLesson.map(mainNetByLesson => {
      const { lesson } = mainNetByLesson;
      let subLessons;
      switch (lesson) {
        case "Türk Dili ve Edebiyatı Sosyal Bilimler - 1":
          subLessons = ["Türk Dili ve Edebiyati", "Tarih-1", "Cografya-1"];
          break;
        case "Sosyal Bilimler-2":
          subLessons = ["Tarih-2", "Cografya-2", "Felsefe", "Din Kül. ve Ahlak Bilgisi"];
          break;
        case "Matematik":
          subLessons = ["Matematik", "Geometri"];
          break;
        case "Fen Bilimleri":
          subLessons = ["Fizik", "Kimya", "Biyoloji"];
          break;
      }

      subLessons = gains.filter(gain => subLessons.indexOf(gain.DERSAD) > -1);
      subLessons = subLessons.map((lesson, i) => {
        return {
          ...lesson,
          RN: i + 1
        };
      });
      subLessons = _.groupBy(subLessons, "ID_DERS");
      subLessons = Object.keys(subLessons).map(subLessonId => {
        return {
          lesson: subLessons[subLessonId][0].DERSAD,
          gains: subLessons[subLessonId]
        };
      });

      const left = [];
      const right = [];
      subLessons.forEach(subLesson => {
        // dersleri iki parçaya böl (left, right)
        // her bir parçanın içinde yer alan derslerin eleman sayısı maks 20 olmalı
        // örn: [[1, 2, ..., 15], [1, 2, 3, 4, 5]]
        // yukarıdaki dizide ilk dersin 15 elemanı var. İkinci dersin 5 elemanı olmalı
        const { gains, lesson } = subLesson;
        if (gains.length > 20) {
          const [firstChunk, secondChunk] = chunkGains(gains);
          left.push({ lesson, gains: firstChunk });
          right.push({ lesson, gains: secondChunk });
          return;
        }

        let leftTotalLen = 0;
        left.forEach(l => {
          leftTotalLen += l.gains.length;
        });
        if (leftTotalLen < 20) {
          if (gains.length < 20 - leftTotalLen) {
            left.push(subLesson);
          } else {
            const need = gains.slice(0, 20 - leftTotalLen);
            const remaining = gains.slice(need.length, gains.length);
            left.push({ lesson, gains: need });
            right.push({ lesson, gains: remaining });
          }
        } else {
          right.push(subLesson);
        }
      });

      return {
        ...mainNetByLesson,
        left,
        right
      };
    });

    return { gainsByMainLesson };
  },

  async gainsTree({ examIdList, userId }) {
    const [studentAverageData] = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 2
    });
    let [averageData] = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 4
    });
    const allGains = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 7
    });

    const scores = parseScoresFromGainsTree(studentAverageData, averageData);
    const averageNetsByMainLesson = parseNetsFromGainsTree(studentAverageData, averageData);

    const treeDataByLesson = generateTreeData(allGains);
    let treeDataByMainLesson = groupTreeDataByMainLesson(treeDataByLesson);
    treeDataByMainLesson = mergeTreeDataAndAverageNets(
      treeDataByMainLesson,
      averageNetsByMainLesson
    );

    treeDataByMainLesson = treeDataByMainLesson.map(lesson => {
      const subLessons = lesson.subLessons || [];
      const correct = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.correct,
        0
      );
      const wrong = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.wrong,
        0
      );
      const empty = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.empty,
        0
      );
      const total = correct + wrong + empty;
      const correctnessPercent = (correct * 100) / total;

      return {
        ...lesson,
        correct,
        wrong,
        empty,
        correctnessPercent
      };
    });

    const netsByCategory = parseNetsByCategoryFromGainsTree(studentAverageData, averageData);

    return {
      scores,
      netsByCategory,
      treeDataByMainLesson
    };
  },

  async comparison({ userId, examIdList }) {
    const { data: studentDataByExam } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 4,
      section: 1
    });

    let { data: studentAverageScores } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 4,
      section: 2
    });
    [studentAverageScores] = studentAverageScores;

    const { data: averageDataByExam } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 4,
      section: 3
    });

    let { data: averageScoreData } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 4,
      section: 4
    });
    [averageScoreData] = averageScoreData;

    const studentScoresAndNets = parseScoresFromComparison(studentAverageScores, averageScoreData);
    const averageScoresByExam = parseAverageScoresFromComparison(
      studentDataByExam,
      averageDataByExam
    );
    const mainLessonAverageNets = parseMainLessonAverageNetsFromComparison(
      studentAverageScores,
      averageScoreData
    );
    let mainLessonNetsByExam = parseMainLessonNetsFromComparison(
      studentDataByExam,
      averageDataByExam
    );
    mainLessonNetsByExam = mergeMainLessonNetsFromComparison(
      mainLessonNetsByExam,
      mainLessonAverageNets
    );

    const netsByLesson = parseNetsByLessonFromComparison({
      studentDataByExam,
      studentAverageScores,
      averageDataByExam,
      averageScoreData
    });

    return {
      studentScoresAndNets,
      averageScoresByExam,
      mainLessonNetsByExam,
      netsByLesson
    };
  }
};
