<template>
  <BaseReportTemplate hide-logo headerLeftTitle="Kazanımlar">
    <b-row>
      <b-col cols="12" md="6" v-for="(gainByLesson, i) in gainsByMainLesson" :key="i">
        <GainsTable
          :title="gainByLesson.model.lessonName"
          :summary="gainByLesson.model.summary()"
          :variant="getVariant(i)"
        >
          <b-row>
            <b-col cols="6" class="border-right">
              <CustomTable :gains="gainByLesson.left"></CustomTable>
            </b-col>
            <b-col cols="6">
              <CustomTable :gains="gainByLesson.right"></CustomTable>
            </b-col>
          </b-row>
        </GainsTable>
      </b-col>
    </b-row>
    <template slot="legend">
      <div class="d-flex flex-row mb-2">
        <span v-for="(legend, i) in legends" :key="i" class="mr-4" :style="legendStyle">
          <strong>{{ legend.label }}:</strong>
          {{ legend.description }}
        </span>
      </div>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import YksStudentService from "@/common/services/api-parsers/yks/student.service";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import { EXAM_TYPES } from "@/common/config";
import GainsTable from "@/components/charts/gains-table.component.vue";
import { GainsTableModel } from "@/common/models";
import HelperTable from "@/components/reports/yks/student/gains-helper-table.component.vue";

export default {
  name: "gains",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    GainsTable,
    CustomTable: HelperTable
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      printerSvg,
      gainsByMainLesson: []
    };
  },
  methods: {
    getVariant(index) {
      const variants = ["gray", "blue", "red", "orange", "green", "lightBlue"];
      return variants[index] || variants[Math.floor(Math.random() * variants.length)];
    },
    async makeRequests() {
      const { exam, student } = this.form;
      if (!exam) return;

      const { gainsByMainLesson } = await YksStudentService.gains({
        examId: exam,
        userId: student
      });

      this.gainsByMainLesson = gainsByMainLesson.map(gainByLesson => {
        const model = new GainsTableModel({
          lessonName: gainByLesson.lesson,
          correct: gainByLesson.correct,
          wrong: gainByLesson.wrong,
          empty: gainByLesson.empty,
          net: gainByLesson.net
        });
        const { left, right } = gainByLesson;

        return { model, right, left };
      });
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    legends() {
      return [
        { label: "S.N.", description: "Soru Numarası" },
        { label: "C", description: "Öğrenci Cevabı" },
        { label: "A", description: "Cevap Anahtarı" },
        { label: "Y.O.", description: "Yapılma Oranı" }
      ];
    },
    legendStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontFamily: "GothamNarrowBook",
        fontSize: "0.9rem"
      };
    }
  }
};
</script>
