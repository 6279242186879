<template>
  <BaseReportTemplate
    :headerLeftTitle="leftTitle"
    :headerLeftSubTitle="leftSubTitle"
    headerRightTitle="Periyodik Doğru Tablosu"
  >
    <b-row>
      <b-col cols="12" class="my-3">
        <div class="d-flex flex-row">
          <div class="flex-grow-1" :style="headerStyle">Dersler bazında doğru sayıları</div>
          <div>
            <Label
              style="width: 170px"
              color="orange"
              left="Öğrenci Sayısı"
              :right="attendance"
            ></Label>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <PeriodicTableByLesson :items="periodicTableByLesson"></PeriodicTableByLesson>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div :style="headerStyle">toplam bazda doğru sayıları</div>
      </b-col>
      <b-col cols="12">
        <PeriodicTableByTotal :items="periodicTableByTotal"></PeriodicTableByTotal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div :style="headerStyle">hızlı liste alma</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" lg="7">
        <b-row>
          <b-col v-for="(filter, i) in filters" :key="i">
            <div class="d-flex flex-row h-100 align-items-center">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input mr-1"
                  type="radio"
                  name="levelFilter"
                  @change="() => {}"
                  :id="i"
                />
                <label class="form-check-label" :for="i" :style="theme.styles.radioLabel">
                  {{ filter.text }}
                </label>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" lg="5">
        <div class="d-flex justify-content-lg-end">
          <div class="form-inline mr-3">
            <div class="form-group align-self-center">
              <label class="mr-2">ARALIK</label>
              <input type="text" class="mr-2 form-control form-control-sm" />
              <input type="text" class="form-control form-control-sm" />
            </div>
          </div>
          <ListButton></ListButton>
        </div>
      </b-col>
    </b-row>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Label from "@/components/label.component.vue";
import PeriodicTableByLesson from "@/components/charts/periodic-table-by-lesson.component.vue";
import PeriodicTableByTotal from "@/components/charts/periodic-table-by-total.component.vue";
import ListButton from "@/components/buttons/periodic-table-list.component.vue";
import SbsCorpService from "@/common/services/api-parsers/sbs/corp.service.js";
import FormStoreMixin from "@/components/mixins/form.mixin.js";

export default {
  name: "periodic-table",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    Label,
    PeriodicTableByLesson,
    PeriodicTableByTotal,
    ListButton
  },
  data() {
    return {
      attendance: 0,
      periodicTableByLesson: [],
      periodicTableByTotal: [],
      filters: [
        { text: "türkçe", value: "1" },
        { text: "sosyal bilgiler", value: "2" },
        { text: "din kültürü", value: "3" },
        { text: "yabancı dil", value: "4" },
        { text: "matematik", value: "5" },
        { text: "fen bilimleri", value: "6" },
        { text: "toplam", value: "7" }
      ],
      selectedFilter: null
    };
  },
  created() {
    this.makeRequests();
  },
  watch: {
    exam() {
      this.makeRequests();
    }
  },
  methods: {
    async makeRequests() {
      if (!this.exam) return;

      const { byLesson, byTotal, studentAttendance } = await SbsCorpService.periodicTable({
        branchId: this.form.branch,
        examId: this.exam
      });

      this.periodicTableByLesson = byLesson;
      this.periodicTableByTotal = byTotal;
      this.attendance = studentAttendance;
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    leftTitle() {
      return this.form.selected.branch.AD;
    },
    leftSubTitle() {
      return this.form.selected.exam.AD;
    },
    headerStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontSize: "2em",
        fontFamily: "GothamNarrowBook",
        textTransform: "uppercase"
      };
    }
  }
};
</script>
