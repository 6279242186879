import * as _ from "lodash";
import md5 from "crypto-js/md5";

import { SinavAPI } from "@/common/api";
import { YKS_PERIODIC_TABLE_CATEGORIES } from "@/common/config";

function parseMainNets(dataByLesson, averageDataByLesson) {
  const lessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      key: "SOS_1",
      averageKey: "SOS_1",
      rankKey: ""
    },
    {
      lesson: "Sosyal Bilimler-2",
      key: "SOS_2",
      averageKey: "SOS_2",
      rankKey: ""
    },
    {
      lesson: "Matematik",
      key: "MAT_",
      averageKey: "MAT",
      rankKey: ""
    },
    {
      lesson: "Fen Bilimleri",
      key: "FEN_",
      averageKey: "FEN",
      rankKey: ""
    }
  ];

  return lessons.map(lesson => {
    const { lesson: lessonName, key, averageKey } = lesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const percentKey = `${key}Z`;
    const averageNetKey = `${averageKey}GN`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: averageDataByLesson[averageNetKey],
      correctnessPercent: dataByLesson[percentKey],
      rank: 1, // TODO
      meta: { ...dataByLesson, ...averageDataByLesson }
    };
  });
}

// eslint-disable-next-line no-unused-vars
function parseNetsByCategory(dataByLesson, averageDataByLesson) {
  const categories = [
    {
      category: "Sayısal Genel Başarı",
      key: "SAYISAL",
      averageKey: "",
      rankKey: ""
    },
    {
      category: "Eşit Ağırlık Genel Başarı",
      key: "SOZEL",
      averageKey: "",
      rankKey: ""
    },
    {
      category: "Sözel Genel Başarı",
      key: "EA",
      averageKey: "",
      rankKey: ""
    }
  ];

  return categories.map(category => {
    const { category: name, key } = category;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const correctnessPercentKey = `${key}Z`;
    // const averageNetKey = "";
    // const rankKey = "";

    return {
      category: name,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      correctnessPercent: dataByLesson[correctnessPercentKey],
      averageNet: 0, // TODO
      rank: 0 // TODO
    };
  });
}

function parseAttendances({ averageDataByLesson, attendanceData }) {
  return {
    general: averageDataByLesson.KATILIM,
    corp: 0, // TODO
    class: attendanceData.OKULSINIFKATILIM
  };
}

function parseCounts({ averageDataByLesson }) {
  return {
    class: averageDataByLesson.SINIFSAYISI,
    corp: averageDataByLesson.OKULSAYISI
  };
}

function parseScoresAndRanks(data) {
  return [
    {
      title: "Sayısal",
      score: data.SAYPUAN_SNV,
      rank: data.SAYPUAN_GES
    },
    {
      title: "Eşit A",
      score: data.EAPUAN_SNV,
      rank: data.EAPUAN_GES
    },
    {
      title: "Sözel",
      score: data.SOZPUAN_SNV,
      rank: data.SOZPUAN_GES
    }
  ];
}

function parsePercents(data) {
  return [data.EAZ, data.SOZELZ, data.SAYISALZ];
}

function parseNetsByLesson({ dataByLesson, averageDataByLesson, allCorrectData }) {
  const lessons = [
    {
      lesson: "Türk Dili ve Edebiyatı",
      key: "BL1",
      rankKey: "",
      allCorrectKey: "TRK"
    },
    {
      lesson: "Tarih-1",
      key: "BL2",
      rankKey: "",
      allCorrectKey: "TAR1"
    },
    {
      lesson: "Coğrafya-1",
      key: "BL3",
      rankKey: "",
      allCorrectKey: "COG1"
    },
    {
      lesson: "Matematik",
      key: "BL4",
      rankKey: "",
      allCorrectKey: "MAT"
    },
    {
      lesson: "Geometri",
      key: "BL5",
      rankKey: "",
      allCorrectKey: "GEO"
    },
    {
      lesson: "Tarih-2",
      key: "BL6",
      rankKey: "",
      allCorrectKey: "TAR2"
    },
    {
      lesson: "Coğrafya-2",
      key: "BL7",
      rankKey: "",
      allCorrectKey: "COG2"
    },
    {
      lesson: "Felsefe",
      key: "BL8",
      rankKey: "",
      allCorrectKey: "FEL"
    },
    {
      lesson: "Din Kültürü ve Ahlak Bilgisi",
      key: "BL9",
      rankKey: "",
      allCorrectKey: "DIN"
    },
    {
      lesson: "Fizik",
      key: "BL10",
      rankKey: "",
      allCorrectKey: "FEN"
    },
    {
      lesson: "Kimya",
      key: "BL11",
      rankKey: "",
      allCorrectKey: "KIM"
    },
    {
      lesson: "Biyoloji",
      key: "BL12",
      rankKey: "",
      allCorrectKey: "BIY"
    }
  ];

  return lessons.map(lesson => {
    let { lesson: lessonName, key, allCorrectKey } = lesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const averageNetKey = `${key}GN`;
    const totalKey = `${key}SS`;
    allCorrectKey = `${allCorrectKey}TAM`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: averageDataByLesson[averageNetKey],
      total: dataByLesson[totalKey],
      allCorrect: allCorrectData[allCorrectKey],
      rank: 0 // TODO
    };
  });
}

async function getPrevExamData({ examId, branchId }) {
  let { data: examData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
    examId,
    branchId,
    action: 2
  });

  if (!examData) return [];
  [examData = {}] = examData;

  let { data: generalDataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
    examId: examData.ID_SINAVBILGI,
    branchId,
    action: 3
  });
  [generalDataByLesson = {}] = generalDataByLesson;

  return parseMainNets(examData, generalDataByLesson);
}

function parseQuestionAnalyze(questionAnalyzeData, netsByLesson) {
  const mainLessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      key: "SOS_1",
      subLessons: [
        { lesson: "Türk Dili ve Edebiyatı", sectionNo: 1 },
        { lesson: "Tarih-1", sectionNo: 2 },
        { lesson: "Coğrafya-1", sectionNo: 3 }
      ]
    },
    {
      lesson: "Sosyal Bilimler-2",
      key: "SOS_2",
      subLessons: [
        { lesson: "Tarih-2", sectionNo: 6 },
        { lesson: "Coğrafya-2", sectionNo: 7 },
        { lesson: "Felsefe", sectionNo: 8 },
        { lesson: "Din Kül. ve Ahlak Bilgisi", sectionNo: 9 }
      ]
    },
    {
      lesson: "Matematik",
      key: "MAT_",
      subLessons: [
        { lesson: "Matematik", sectionNo: 4 },
        { lesson: "Geometri", sectionNo: 5 }
      ]
    },
    {
      lesson: "Fen Bilimleri",
      key: "FEN_",
      subLessons: [
        { lesson: "Fizik", sectionNo: 10 },
        { lesson: "Kimya", sectionNo: 11 },
        { lesson: "Biyoloji", sectionNo: 12 }
      ]
    }
  ];

  return mainLessons.map(mainLesson => {
    const { lesson: mainLessonName, key, subLessons } = mainLesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;

    let gains = questionAnalyzeData
      .filter(qData => {
        const sectionNoList = subLessons.map(subLesson => subLesson.sectionNo);
        return sectionNoList.indexOf(qData.BOLUMNO) !== -1;
      })
      .map((qData, index) => {
        return {
          index: index + 1,
          a: qData.ASIK,
          b: qData.BSIK,
          c: qData.CSIK,
          d: qData.DSIK,
          e: qData.ESIK,
          empty: qData.BOSSIK,
          correctRatio: qData.Z_DOGRU,
          gain: qData.KAZANIM
        };
      });
    gains = _.chunk(gains, 20);

    return {
      lesson: mainLessonName,
      correct: netsByLesson[correctKey],
      wrong: netsByLesson[wrongKey],
      empty: netsByLesson[emptyKey],
      net: netsByLesson[netKey],
      gains
    };
  });
}

async function getScoresAndNetsByCategory({ examIdList, branchId }) {
  let { data: nets } = await SinavAPI.KurumKiyasAnaliziTumVeriler({
    examIdList,
    branchId,
    action: 2
  });
  [nets = {}] = nets;

  let { data: averageNets } = await SinavAPI.KurumKiyasAnaliziTumVeriler({
    examIdList,
    branchId,
    action: 4
  });
  [averageNets = {}] = averageNets;

  const categories = [
    {
      category: "Sayısal",
      scoreKey: "SAYPUAN",
      netKey: "SAYNET",
      averageScoreKey: "SAYGEO",
      averageNetKey: "SAYGN"
    },
    {
      category: "Sözel",
      scoreKey: "SOZPUAN",
      netKey: "SOZNET",
      averageScoreKey: "SOZGEO",
      averageNetKey: "SOZGN"
    },
    {
      category: "Eşit A.",
      scoreKey: "EAPUAN",
      netKey: "EANET",
      averageScoreKey: "EAGEO",
      averageNetKey: "EAGN"
    }
  ];

  return categories.map(category => {
    const { category: categoryName, netKey, averageNetKey, scoreKey, averageScoreKey } = category;
    return {
      title: categoryName,
      score: nets[scoreKey],
      net: nets[netKey],
      averageNet: averageNets[averageNetKey],
      averageScore: averageNets[averageScoreKey]
    };
  });
}

function parseNetsByCategoryFromGainsTree() {
  // TODO
  const categories = [
    { title: "Sayısal Genel Başarı" },
    { title: "Eşit Ağırlık Genel Başarı" },
    { title: "Sözel Genel Başarı" }
  ];

  return categories.map(category => {
    const { title } = category;
    return {
      category: title,
      correct: 1,
      wrong: 1,
      empty: 1,
      correctnessPercent: 1,
      net: 1,
      averageNet: 1
    };
  });
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    const correctKey = "DOĞRU";
    const totalQuestionKey = "SORU SAYISI";
    const wrongKey = "YANLIŞ";
    const emptyKey = "BOŞ";
    const unitKey = "ÜNİTE";
    const topicKey = "KONU";
    const gainKey = "KAZANIM";

    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue[correctKey];
        const total = accumulator.total + currentValue[totalQuestionKey];
        const percent = Math.floor((100 * correct) / total);

        const id = `id_${currentValue[idKey]}`;
        const unitId = `unit_${currentValue[unitKey]}`;
        const topicId = `topic_${currentValue[topicKey]}`;
        const gainId = `gain_${currentValue[gainKey]}`;

        return {
          _id: md5(currentValue[idKey]).toString(),
          id: md5(id).toString(),
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue[wrongKey],
          empty: accumulator.empty + currentValue[emptyKey],
          percent,
          _unitId: md5(currentValue[unitKey]).toString(),
          unitId: md5(unitId).toString(),
          _topicId: md5(currentValue[unitKey]).toString(),
          topicId: md5(topicId).toString(),
          _gainId: md5(currentValue[gainKey]).toString(),
          gainId: md5(gainId).toString()
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(allGains) {
  const gainsMappedByLesson = _.groupBy(allGains, "DERS");

  return Object.keys(gainsMappedByLesson).map(lessonName => {
    const gainsBelongToLesson = allGains.filter(gain => gain.DERS === lessonName && !!gain.KAZANIM);

    let units = _.groupBy(gainsBelongToLesson, "ÜNİTE");
    let topics = _.groupBy(gainsBelongToLesson, "KONU");
    let _gains = _.groupBy(gainsBelongToLesson, "KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ÜNİTE",
      titleKey: "ÜNİTE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "KONU",
      titleKey: "KONU"
    });
    _gains = mergeGainsBy({
      data: _gains,
      idKey: "KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: _gains.filter(gain => gain._topicId === topic._topicId)
      };
    });
    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic._unitId === unit._id)
      };
    });

    const total = units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
    const correct = units.reduce(
      (accumulator, currentValue) => accumulator + currentValue.correct,
      0
    );
    const correctnessPercent = Math.floor((100 * correct) / total);

    return {
      lesson: lessonName,
      total,
      correct,
      wrong: units.reduce((accumulator, currentValue) => accumulator + currentValue.wrong, 0),
      empty: units.reduce((accumulator, currentValue) => accumulator + currentValue.empty, 0),
      correctnessPercent,
      units
    };
  });
}

function groupTreeDataByMainLesson(treeDataByLesson) {
  const mainLessons = [
    {
      lesson: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
      subLessons: ["Türk Dili ve Edebiyatı", "Tarih-1", "Coğrafya-1"]
    },
    {
      lesson: "Sosyal Bilimler-2",
      subLessons: ["Tarih-2", "Coğrafya-2", "Felsefe", "Din Kül. ve Ahlak Bilgisi"]
    },
    {
      lesson: "Matematik",
      subLessons: ["Matematik", "Geometri"]
    },
    {
      lesson: "Fen Bilimleri",
      subLessons: ["Fizik", "Kimya", "Biyoloji"]
    }
  ];

  return mainLessons.map(mainLesson => {
    return {
      lesson: mainLesson.lesson,
      subLessons: treeDataByLesson.filter(
        treeData => mainLesson.subLessons.indexOf(treeData.lesson) !== -1
      )
    };
  });
}

function mergeTreeDataAndAverageNets(treeDataByMainLesson, averageData) {
  return treeDataByMainLesson.map(treeData => {
    const { lesson } = treeData;
    let netKey = "";
    let averageNetKey = "";

    switch (lesson) {
      case "Türk Dili ve Edebiyatı Sosyal Bilimler - 1":
        netKey = "SNVGNLSOS_1GN";
        averageNetKey = "TRK_GN";
        break;
      case "Sosyal Bilimler-2":
        netKey = "SNVGNLSOS_2GN";
        averageNetKey = "TAR_GN";
        break;
      case "Matematik":
        netKey = "SNVGNLMATGN";
        averageNetKey = "MAT_GN";
        break;
      case "Fen Bilimleri":
        netKey = "SNVGNLFENGN";
        averageNetKey = "FEN_GN";
        break;
      default:
    }

    return {
      ...treeData,
      net: averageData[netKey],
      averageNet: averageData[averageNetKey]
    };
  });
}

function parsePeriodicTableDataByLesson(dataByLesson, averageData) {
  const types = {
    TR: "Türk Dili ve Edebiyatı Sosyal Bilimler - 1",
    SOC: "Sosyal Bilimler-2",
    MAT: "Matematik",
    SCI: "Fen Bilimleri"
  };
  const MAX_NUMBERS = 31;

  const lessonSections = {
    1: {
      type: types.TR,
      lessonName: "Türk Dil. ve Ede.",
      corpAverageKey: "EDE_Okl_Dyks",
      generalAverageKey: "EDE_Okl_Gnlyks",
      len: 24 // 31 sütunluk yer kaplayacak ve 7 sütun undefined olacak
    },
    2: {
      type: types.TR,
      lessonName: "Tarih-1",
      corpAverageKey: "TAR_1_Okl_Dyks",
      generalAverageKey: "TAR_1_Okl_Gnlyks",
      len: 10
    },
    3: {
      type: types.TR,
      lessonName: "Coğrafya-1",
      corpAverageKey: "COG_1_Okl_Dyks",
      generalAverageKey: "COG_1_Okl_Gnlyks",
      len: 6
    },
    6: {
      type: types.SOC,
      lessonName: "Tarih-2",
      corpAverageKey: "TAR_2_Okl_Dyks",
      generalAverageKey: "TAR_2_Okl_Gnlyks",
      len: 12,
      fillWithUndefined: 4 // 16 sütunluk yer kaplayacak ama son 4 sütun undefined olacak
    },
    7: {
      type: types.SOC,
      lessonName: "Coğrafya-2",
      corpAverageKey: "COG_2_Okl_Dyks",
      generalAverageKey: "COG_2_Okl_Gnlyks",
      len: 11,
      fillWithUndefined: 5
    },
    8: {
      type: types.SOC,
      lessonName: "Felsefe",
      corpAverageKey: "FEL_Okl_Dyks",
      generalAverageKey: "FEL_Okl_Gnlyks",
      len: 12,
      fillWithUndefined: 4
    },
    9: {
      type: types.SOC,
      lessonName: "Din Kül.",
      corpAverageKey: "DIN_Okl_Dyks",
      generalAverageKey: "DIN_Okl_Gnlyks",
      len: 6,
      fillWithUndefined: 10
    },
    4: {
      type: types.MAT,
      lessonName: "Matematik",
      corpAverageKey: "MAT_Okl_Dyks",
      generalAverageKey: "MAT_Okl_Gnlyks",
      len: 31
    },
    5: {
      type: types.MAT,
      lessonName: "Geometri",
      corpAverageKey: "GEO_Okl_Dyks",
      generalAverageKey: "GEO_Okl_Gnlyks",
      len: 10
    },
    10: {
      type: types.SCI,
      lessonName: "Fizik",
      corpAverageKey: "FIZ_Okl_Dyks",
      generalAverageKey: "FIZ_Okl_Gnlyks",
      len: 14
    },
    11: {
      type: types.SCI,
      lessonName: "Kimya",
      corpAverageKey: "KIM_Okl_Dyks",
      generalAverageKey: "KIM_Okl_Gnlyks",
      len: 13
    },
    12: {
      type: types.SCI,
      lessonName: "Biyoloji",
      corpAverageKey: "BIY_Okl_Dyks",
      generalAverageKey: "BIY_Okl_Gnlyks",
      len: 13
    }
  };

  const groupedDataByLesson = _.groupBy(dataByLesson, "BLNO");

  const formattedDataByLesson = Object.keys(groupedDataByLesson).map(lessonNo => {
    const lessonNoAsNumber = Number(lessonNo);
    if (!Number.isInteger(lessonNoAsNumber)) return {};

    const lessonMeta = lessonSections[lessonNoAsNumber];
    const lessonName = lessonMeta.lessonName;
    const corpAverage = averageData[lessonMeta.corpAverageKey];
    const generalAverage = averageData[lessonMeta.generalAverageKey];

    const emptyKey = `${lessonNoAsNumber}B`;
    let empty = dataByLesson.find(data => data.BLNO === emptyKey) || { NUM: 0 };
    empty = empty.NUM;

    const rawData = [...groupedDataByLesson[lessonNo]];
    // fillWithUndefined belirtilmişse, len + fillWithUndefined kadar dizi oluştur
    // ve fillWithUndefined kadar undefined ekle.
    // aksi halde MAX_NUMBERS kadar dizi oluştur ve MAX_NUMBERS - len kadar undefined ekle.
    const len = lessonMeta.fillWithUndefined
      ? lessonMeta.len + lessonMeta.fillWithUndefined
      : MAX_NUMBERS;
    const numbers = Array(len)
      .fill(0)
      .map((_, index) => {
        if (index > lessonMeta.len) {
          return undefined;
        }

        const correctValue = rawData.find(data => data.BL1D === index) || { NUM: 0 };
        return correctValue.NUM;
      });

    return {
      lesson: lessonName,
      corpAverage,
      generalAverage,
      empty,
      numbers,
      _type: lessonMeta.type
    };
  });

  return Object.keys(types).map(typeKey => {
    const type = types[typeKey];
    return {
      type,
      lessons: formattedDataByLesson.filter(data => data._type === type)
    };
  });
}

function parsePeriodicTableDataByTotalCorrect(correctCountByTotal, category) {
  const { SCIENCE, SOCIAL } = YKS_PERIODIC_TABLE_CATEGORIES;
  const key = category === SCIENCE ? "SAYISAL" : category === SOCIAL ? "SOZEL" : "EA";
  const ranges = [
    {
      range: [0, 17],
      desc: `Konu eksiği <strong>çok fazla</strong> olan ve <strong>çok az</strong> soru çözen öğrenci grubu.`
    },
    {
      range: [18, 40],
      desc: `Konu eksiği <strong>fazla</strong> olan ve <strong>az</strong> soru çözen öğrenci grubu.`
    },
    {
      range: [41, 63],
      desc: `Konu eksiği <strong>az</strong> olan ve <strong>yeterince</strong> soru çözmeyen öğrenci grubu.`
    },
    {
      range: [64, 80],
      desc: `Konu eksiği <strong>olmayan</strong> ve <strong>farklı soru tipi</strong> çözmesi gereken öğrenci grubu.`
    }
  ];

  return ranges.map((range, rangeIndex) => {
    let [start, end] = range.range;
    const prevRangeEnd = rangeIndex > 0 ? ranges[rangeIndex - 1].range[1] : 0;
    const rangeLen = end - start + 1;
    const numbers = Array(rangeLen)
      .fill(0)
      .map((_, i) => {
        const index = i + prevRangeEnd + 1;
        const obj = correctCountByTotal.find(data => data[key] === index) || { NUM: 0 };
        return obj.NUM;
      });

    return {
      range: `${start} - ${end}`,
      desc: range.desc,
      numbers,
      total: numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    };
  });
}

export default {
  async frontReport({ examId, branchId }) {
    let { data: dataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    let { data: averageDataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 3
    });
    [averageDataByLesson = {}] = averageDataByLesson;

    let { data: attendanceData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 4
    });
    [attendanceData = {}] = attendanceData;

    let { data: allCorrectData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 5
    });
    [allCorrectData = {}] = allCorrectData;

    const mainNetsByLesson = parseMainNets(dataByLesson, averageDataByLesson);
    const netsByCategory = parseNetsByCategory(dataByLesson, averageDataByLesson);
    const netsByLesson = parseNetsByLesson({ dataByLesson, averageDataByLesson, allCorrectData });
    const attendances = parseAttendances({ averageDataByLesson, attendanceData });
    const counts = parseCounts({ averageDataByLesson, attendanceData });
    const scoresAndRanks = parseScoresAndRanks(dataByLesson);
    const percents = parsePercents(dataByLesson);
    const prevExam = await getPrevExamData({ examId, branchId });

    return {
      mainNetsByLesson,
      netsByCategory,
      prevExam,
      attendances,
      counts,
      scoresAndRanks,
      percents,
      netsByLesson
    };
  },

  async questionAnalyze({ examId, branchId }) {
    let { data: questionAnalyzeData } = await SinavAPI.KurumSinavAnaliziTumVerilerArka({
      examId,
      branchId,
      action: 1
    });

    let { data: dataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    const attendance = dataByLesson.OGRSAY || 0;
    const questionAnalyzeByLesson = parseQuestionAnalyze(questionAnalyzeData, dataByLesson);

    return {
      attendance,
      questionAnalyzeByLesson
    };
  },

  async gainsTree({ examIdList, branchId }) {
    let { data: allGains } = await SinavAPI.KurumKazanimAnaliziTumVeriler({
      examIdList,
      branchId,
      action: 1,
      section: 1
    });

    let { data: netData } = await SinavAPI.KurumKiyasAnaliziTumVeriler({
      examIdList,
      branchId,
      action: 2
    });
    [netData = {}] = netData;

    let { data: averageNetData } = await SinavAPI.KurumKiyasAnaliziTumVeriler({
      examIdList,
      branchId,
      action: 4
    });

    [averageNetData = {}] = averageNetData;

    const scores = await getScoresAndNetsByCategory({ examIdList, branchId });
    const netsByCategory = parseNetsByCategoryFromGainsTree(); // TODO
    let treeDataByMainLesson = generateTreeData(allGains);
    treeDataByMainLesson = groupTreeDataByMainLesson(treeDataByMainLesson);
    treeDataByMainLesson = mergeTreeDataAndAverageNets(treeDataByMainLesson, {
      ...netData,
      ...averageNetData
    });

    treeDataByMainLesson = treeDataByMainLesson.map(lesson => {
      const subLessons = lesson.subLessons || [];
      const correct = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.correct,
        0
      );
      const wrong = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.wrong,
        0
      );
      const empty = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.empty,
        0
      );
      const total = correct + wrong + empty;
      const correctnessPercent = (correct * 100) / total;

      return {
        ...lesson,
        correct,
        wrong,
        empty,
        correctnessPercent
      };
    });

    return {
      scores,
      netsByCategory,
      treeDataByMainLesson
    };
  },

  async periodicTable({ examId, branchId, category }) {
    const { data: correctCountByLesson } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 1
    });

    let { data: averageData } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 2
    });
    [averageData] = averageData;

    const { data: correctCountByTotal } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 3,
      category
    });

    const dataByLesson = parsePeriodicTableDataByLesson(correctCountByLesson, averageData);
    const dataByTotal = parsePeriodicTableDataByTotalCorrect(correctCountByTotal, category);

    return {
      byLesson: dataByLesson,
      byTotal: dataByTotal,
      studentAttendance: averageData.OGRSAY || 0
    };
  }
};
