<template>
  <BaseReportTemplate hide-logo headerLeftTitle="BAŞARI İZLEME KARNESİ">
    <b-row>
      <b-col class="left-column">
        <b-col v-if="!mainLessonCharts.length">
          <p>Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.</p>
        </b-col>
        <b-row v-else>
          <b-col class="mb-2 mb-md-5 col-6" v-for="(chart, i) of mainLessonCharts" :key="i">
            <DoughnutChart
              :chart-data="chart.chartData()"
              :title="
                chart.title == 'Türk Dili ve Edebiyatı Sosyal Bilimler - 1'
                  ? 'TÜRK D. E. SOS-1'
                  : chart.title
              "
              :cutout-percentage="60"
              :styles="{ height: '130px', position: 'relative' }"
            />
            <NetIcon
              class="mt-1"
              :type="EXAM_TYPES.YKS"
              :student-net="chart.studentNet"
              :average-net="chart.averageNet"
            />
          </b-col>
        </b-row>

        <b-col cols="12">
          <p v-if="!netsByLesson.length">
            Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.
          </p>
          <table v-else class="table-front" rules="all">
            <thead>
              <tr>
                <th></th>
                <th>SORU<br />SAYISI</th>
                <th><fa icon="square" :style="{ color: trueColor }"></fa></th>
                <th><fa icon="square" :style="{ color: wrongColor }"></fa></th>
                <th><fa icon="square"></fa></th>
                <th><Icon :src="studentSvg" :height="19"></Icon></th>
                <th><Icon :src="studentsSvg" :height="19"></Icon></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lesson, i) of netsByLesson" :key="i">
                <td>{{ lesson.lesson }}</td>
                <td>{{ lesson.total }}</td>
                <td>{{ lesson.correct }}</td>
                <td>{{ lesson.wrong }}</td>
                <td>{{ lesson.empty }}</td>
                <td>
                  <b>{{ lesson.net }}</b>
                </td>
                <td>
                  <b>{{ lesson.averageNet }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-col>
      <b-col class="center-column">
        <template v-if="netsByCategory.length">
          <div class="mb-4" v-for="(chart, i) of netsByCategory" :key="i">
            <!-- <hr class="w-90" v-if="i > 0" /> -->
            <DoughnutChart
              :chart-data="chart.chartData()"
              :title="chart.title"
              :cutout-percentage="60"
              :styles="{ height: '150px', position: 'relative' }"
            />
            <NetIcon
              class="mt-3"
              :type="EXAM_TYPES.YKS"
              :student-net="chart.studentNet"
              :average-net="chart.averageNet"
            />
          </div>
        </template>
      </b-col>
      <b-col class="right-column">
        <b-row class="my-3 mt-lg-0">
          <b-col cols="8">
            <Label
              style="width: 230px"
              left="SINAV ADI"
              :right="examName"
              :right-style="{ fontSize: '11px' }"
              :icon-props="examNameIconProps"
            ></Label>
            <Label
              style="width: 150px; margin-top: 10px"
              left="1. Oturum"
              :right="sessionOne"
              :right-style="{ fontSize: '0.9rem' }"
              :icon-props="sessionOneIconProps"
            ></Label>
          </b-col>
          <b-col cols="4" class="my-auto">
            <Icon :src="printerSvg"></Icon>
          </b-col>
          <b-col cols="12" class="align-items-center">
            <hr class="w-90" />
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col v-if="!scoresAndRanks.length">
            <p>Puan ve sıralama bilgileri için sınav seçin.</p>
          </b-col>
          <b-col cols="4" v-else v-for="(scoreAndRank, i) in scoresAndRanks" :key="`score_${i}`">
            <Label
              class="mb-4 w-100"
              style="height: 25px"
              color="orange"
              :left="scoreAndRank.title"
              left-position="center"
              :right="scoreAndRank.score"
              right-position="right"
              :right-style="{ fontSize: '0.9rem' }"
            ></Label>
            <Rank
              class="mt-3"
              :class-rank="scoreAndRank.ranks.class"
              :corp-rank="scoreAndRank.ranks.corp"
              :general-rank="scoreAndRank.ranks.general"
            />
          </b-col>
          <b-col v-if="!attendances.length">
            <p>Katılım bilgileri için sınav seçin.</p>
          </b-col>
          <b-col cols="4" v-else v-for="(attendance, i) in attendances" :key="`att_${i}`">
            <Label
              class="mt-4 w-100"
              style="height: 25px"
              color="blue"
              :left="attendance.title"
              left-position="center"
              :right="attendance.att"
              right-position="right"
              :right-style="{ fontSize: '0.9rem' }"
            ></Label>
          </b-col>
          <b-col cols="12">
            <hr class="w-90" />
          </b-col>
        </b-row>
        <b-row class="mb-4" v-if="netComparison">
          <b-col cols="5">
            <Jumbotron class="w-100 mb-0" :background="true">
              <slot>
                <small>
                  Yandaki netler, en son girmiş olduğunuz <br> TYT sınavına ya da YKS başarınıza <br>orantılanarak belirlenmiştir.
                </small>
              </slot>
            </Jumbotron>
          </b-col>
          <b-col cols="7">
            <div class="d-flex flex-column h-100 justify-content-center">
              <div class="d-flex flex-row mb-3">
                <Label
                  class="mr-2 w-100"
                  style="height: 25px"
                  color="orange"
                  left="TÜRKÇE"
                  left-position="center"
                  :right="netComparison.turkish"
                  right-position="right"
                  :right-style="{ fontSize: '0.9rem' }"
                ></Label>
                <Label
                  class="w-100"
                  style="height: 25px"
                  color="orange"
                  left="SOSYAL BİLİMLER"
                  left-position="center"
                  :right="netComparison.social"
                  right-position="right"
                  :right-style="{ fontSize: '0.9rem' }"
                ></Label>
              </div>
              <div class="d-flex flex-row">
                <Label
                  class="mr-2 w-100"
                  style="height: 25px"
                  color="orange"
                  left="MATEMATİK"
                  left-position="center"
                  :right="netComparison.math"
                  right-position="right"
                  :right-style="{ fontSize: '0.9rem' }"
                ></Label>
                <Label
                  class="w-100"
                  style="height: 25px"
                  color="orange"
                  left="FEN BİLİMLERİ"
                  left-position="center"
                  :right="netComparison.science"
                  right-position="right"
                  :right-style="{ fontSize: '0.9rem' }"
                ></Label>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="w-90" />
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!prevExamBarChart">
            <p>Seçilen sınavdan önce kıyaslanacak sınav varsa kıyaslama grafiği görüntülenir.</p>
          </b-col>
          <b-col v-else>
            <BarChart
              :max="40"
              title="Karşılaştırma Grafiği (Net)"
              :chart-data="prevExamBarChart.chartData()"
              :styles="{ height: '500px', position: 'relative' }"
            ></BarChart>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <ProgressChart class="my-1" :type="EXAM_TYPES.YKS" :yks-percents="yksPercents"></ProgressChart>
    <hr class="my-0" />
    <template>
      <b-col class="d-flex flex-wrap " style="padding:8px 0 10px">
        <TrueFalseLegend
          class="mr-auto mr-md-4 mb-3 mb-xl-0"
          :type="EXAM_TYPES.YKS"
        ></TrueFalseLegend>
        <NetLegend class="mr-md-4 mb-3 mb-xl-0" :type="EXAM_TYPES.YKS"></NetLegend>
        <ProgressLegend class="mb-md-3 mb-xl-0" :type="EXAM_TYPES.YKS"></ProgressLegend>
      </b-col>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import YksStudentService from "@/common/services/api-parsers/yks/student.service";
import { DoughnutChartModel, BarChartModel } from "@/common/models";
import studentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";
import bookSvg from "@/assets/media/icons/svg/Karnem/lgs/book.svg";
import checkCircleSvg from "@/assets/media/icons/svg/Karnem/lgs/check-circle.svg";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import Icon from "@/components/icons/icon.component";
import Label from "@/components/label.component.vue";
import Rank from "@/components/reports/yks/rank.component";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import { EXAM_TYPES } from "@/common/config";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";

export default {
  name: "follow-up",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    DoughnutChart,
    NetIcon,
    Icon,
    Label,
    Rank,
    Jumbotron,
    BarChart,
    ProgressChart,
    TrueFalseLegend,
    NetLegend,
    ProgressLegend
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      studentSvg,
      studentsSvg,
      printerSvg,
      yksPercents: [],
      examNameIconProps: {
        src: bookSvg,
        height: 19
      },
      sessionOneIconProps: {
        src: checkCircleSvg,
        height: 19
      },
      examName: "",
      sessionOne: "",
      mainLessonCharts: [],
      netsByLesson: [],
      scoresAndRanks: [],
      attendances: [],
      netsByCategory: [],
      netComparison: null,
      prevExamBarChart: null
    };
  },
  methods: {
    async makeRequests() {
      const { exam, student } = this.form;
      if (!exam) return;

      const {
        netsByLesson,
        mainNetsByLesson,
        netsByCategory,
        percents,
        examName,
        sessionOne,
        scoresAndRanks,
        attendances,
        netComparison,
        prevExamMainNetsByLesson
      } = await YksStudentService.followUpReport({
        examId: exam,
        userId: student
      });

      this.renderMainLessonCharts(mainNetsByLesson);
      this.netsByLesson = netsByLesson;
      this.examName = examName;
      this.sessionOne = sessionOne;
      this.scoresAndRanks = scoresAndRanks;
      this.attendances = attendances;
      this.netComparison = netComparison;
      this.yksPercents = percents || [];
      this.renderPrevExamChart(mainNetsByLesson, prevExamMainNetsByLesson);
      this.renderNetsByCategory(netsByCategory);
    },
    renderMainLessonCharts(mainLessons) {
      this.mainLessonCharts = mainLessons.map(lesson => {
        return new DoughnutChartModel({
          title: lesson.lesson,
          correct: lesson.correct,
          wrong: lesson.wrong,
          empty: lesson.empty,
          correctnessPercent: lesson.correctnessPercent,
          studentNet: lesson.net,
          averageNet: lesson.averageNet
        });
      });
    },
    renderPrevExamChart(currentExamMainNetsByLesson, prevExamMainNetsByLesson) {
      if (!prevExamMainNetsByLesson) {
        this.prevExamBarChart = null;
        return;
      }

      const chartData = currentExamMainNetsByLesson.map(cExam => {
        const prevExam = prevExamMainNetsByLesson.find(pExam => pExam.lesson === cExam.lesson);
        return {
          title: cExam.lesson.substring(0, 17),
          dataset: [
            {
              label: cExam.meta.SINAVADI,
              data: cExam.net
            },
            {
              label: prevExam.meta.SINAVADI,
              data: prevExam.net
            },
            {
              label: "Genel",
              data: cExam.averageNet
            }
          ]
        };
      });
      this.prevExamBarChart = new BarChartModel(chartData);
    },
    renderNetsByCategory(netsByCategory) {
      this.netsByCategory = netsByCategory.map(netByCategory => {
        const {
          category: title,
          correct,
          wrong,
          empty,
          correctnessPercent,
          net,
          averageNet
        } = netByCategory;
        return new DoughnutChartModel({
          title,
          correct,
          wrong,
          empty,
          correctnessPercent,
          net,
          studentNet: net,
          averageNet
        });
      });
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    trueColor() {
      const corp = this.$store.getters.form.corp;
      const color =
        (corp == 1 && this.theme.colors.KULTUR_FIRST) ||
        (corp == 3 && this.theme.colors.ERA_FIRST) ||
        this.theme.colors.BLUE;
      return color;
    },
    wrongColor() {
      const corp = this.$store.getters.form.corp;
      const color =
        (corp == 1 && this.theme.colors.KULTUR_SECOND) ||
        (corp == 3 && this.theme.colors.ERA_SECOND) ||
        this.theme.colors.GRAY;
      return color;
    }
  }
};
</script>

<style scoped>
td,
thead th {
  text-align: center;
  vertical-align: middle;
}

tbody th {
  border-right: 0;
  border-left: 0;
}

thead th:not(:first-child) {
  border: 0;
  border-left: 1px solid #ebedf2;
}

thead th:first-child {
  border: 0;
  border-bottom: 1px solid #ebedf2;
}
@media (max-width: 991.99px) {
  .border-right {
    border: none !important;
  }
}
</style>
<style lang="scss" scoped>
.center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
  flex: 1 1 100%;
}
.left-column {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1 1 100%;
}
.right-column {
  max-width: 100%;
  flex: 1 1 100%;
}
@media (min-width: 768px) {
  .center-column {
    max-width: 50%;
    flex: 1 1 50%;
  }
  .left-column {
    max-width: 50%;
    flex: 1 1 50%;
  }
}
@media (min-width: 1200px) {
  .center-column {
    max-width: 30%;
    flex: 1 1 30%;
    border-right: 1px solid #d0d0d1;
    border-left: 1px solid #d0d0d1;
  }
  .left-column {
    max-width: 30%;
    flex: 1 1 30%;
  }
  .right-column {
    max-width: 40%;
    flex: 1 1 40%;
  }
}

.table {
  &-front {
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: center;
    color: #6d6f72;
    font-family: "GothamCondensedBook";
    margin-left: auto;
    margin-right: auto;
    border-color: #e9eaea;
    thead {
      font-size: 8px;
      line-height: 1;
      th {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
    tbody {
      font-size: 10.83px;
      td {
        &:first-of-type {
          text-align: left;
          min-width: 47px;
        }
        &:not(:first-of-type) {
          min-width: 33px;
        }
      }
    }

    b {
      font-weight: 400;
      font-family: "GothamCondensedBold";
    }
  }
}
</style>
