<template>
  <div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td :style="primaryTableHeadStyle" :rowspan="rowSpan">
              Toplam doğru sayısı
            </td>
          </tr>
          <template v-for="(item, itemIndex) in items">
            <tr :key="`row_${itemIndex}`" :style="getStyleByIndex(itemIndex)">
              <td rowspan="2" class="text-left text-white" style="font-size: 0.8rem">
                {{ item.range }}
                <br />
                <div v-html="item.desc"></div>
              </td>
              <td
                v-for="(questionIndex, i) in getQuestionIndex(itemIndex)"
                :key="`questionIndex_${i}`"
              >
                {{ questionIndex }}
              </td>
              <td rowspan="2" colspan="2" :style="getStyleByIndex(itemIndex)">
                {{ item.total }}
              </td>
            </tr>
            <tr :key="`value_${itemIndex}`" :style="getStyleByIndex(itemIndex)">
              <td v-for="(number, i) in item.numbers" :key="`number_${i}`">
                {{ number }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <ListButton></ListButton>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ListButton from "@/components/buttons/periodic-table-list.component.vue";
import chroma from "chroma-js";

export default {
  name: "periodic-table-by-total",
  components: {
    ListButton
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getQuestionIndex(itemIndex) {
      const { numbers } = this.items[itemIndex];
      if (itemIndex === 0) {
        return Array(numbers.length)
          .fill(true)
          .map((n, i) => i);
      }

      let total = 0;
      while (itemIndex !== 0) {
        total += this.items[itemIndex - 1].numbers.length;
        itemIndex -= 1;
      }
      return Array(numbers.length)
        .fill(true)
        .map((n, i) => total + i);
    },
    getBgColorByIndex(itemIndex) {
      switch (itemIndex) {
        case 0:
          return chroma(this.theme.colors.RED).set("lch.c", 50);
        case 1:
          return chroma(this.theme.colors.ORANGE).set("lch.c", 50);
        case 2:
          return chroma(this.theme.colors.LIGHT_ORANGE).set("lch.c", 50);
        default:
          return chroma(this.theme.colors.GREEN).set("lch.c", 50);
      }
    },
    getStyleByIndex(itemIndex) {
      const backgroundColor = this.getBgColorByIndex(itemIndex);
      return {
        backgroundColor,
        color: this.theme.colors.WHITE,
        textTransform: "none"
      };
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    rowSpan() {
      return this.items.length * 2 + 1;
    },
    primaryTableHeadStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        color: this.theme.colors.WHITE
      };
    },
    secondaryTableHeadStyle() {
      return {
        backgroundColor: this.theme.colors.GRAY_WHITE,
        color: this.theme.colors.GRAY
      };
    },
    buttonStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        color: this.theme.colors.WHITE,
        textTransform: "uppercase",
        padding: "1rem 2rem",
        fontFamily: "GothamNarrowBold"
      };
    }
  }
};
</script>

<style scoped>
td {
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 7px;
}
table {
  border-spacing: 3px;
  border-collapse: separate;
  text-transform: uppercase;
  font-family: GothamNarrowBold;
}
</style>
