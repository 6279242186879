<template>
  <div class="d-flex flex-row align-items-center justify-content-end">
    <div class="mr-2">
      <b-form-select
        size="sm"
        v-model="selected"
        :options="options"
        :style="selectStyle"
      ></b-form-select>
    </div>
    <div>
      <b-button :style="buttonStyle">Listele</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "periodic-table-list",
  data() {
    return {
      selected: "PDF",
      options: [{ value: "PDF", text: "PDF" }]
    };
  },
  computed: {
    ...mapGetters(["theme"]),
    selectStyle() {
      return {
        backgroundColor: this.theme.colors.GRAY_WHITE,
        color: this.theme.colors.GRAY
      };
    },
    buttonStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        color: this.theme.colors.WHITE,
        textTransform: "uppercase",
        padding: "1rem 2rem",
        fontFamily: "GothamNarrowBold"
      };
    }
  }
};
</script>
