<template>
  <b-tabs class="kt-hide-tabs">
    <b-tab
      title="Başarı İzleme Karnesi"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.followUp)"
      @click="() => showTab(TABS.student.followUp)"
    >
      <FollowUp v-if="isActiveTab(TABS.student.followUp)"></FollowUp>
    </b-tab>
    <b-tab
      title="Kazanımlar Karnesi"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.gains)"
      @click="() => showTab(TABS.student.gains)"
    >
      <StudentGains v-if="isActiveTab(TABS.student.gains)"></StudentGains>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.gainsTree)"
      @click="() => showTab(TABS.student.gainsTree)"
    >
      <GainsTree v-if="isActiveTab(TABS.student.gainsTree)"></GainsTree>
    </b-tab>
    <b-tab
      title="Gelişim İzleme"
      v-if="isVariant(REPORT_VARIANTS.STUDENT)"
      :active="isActiveTab(TABS.student.comparison)"
      @click="() => showTab(TABS.student.comparison)"
    >
      <Comparison v-if="isActiveTab(TABS.student.comparison)"></Comparison>
    </b-tab>
    <b-tab
      title="Sınıfsal Rapor (Ön)"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelFront)"
      @click="() => showTab(TABS.corporation.classLevelFront)"
    >
      <CFront
        v-if="isActiveTab(TABS.corporation.classLevelFront)"
        :variant="REPORT_VARIANTS.CLASS"
      ></CFront>
    </b-tab>
    <b-tab
      title="Sınıfsal Rapor (Arka)"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelQuestionAnalyze)"
      @click="() => showTab(TABS.corporation.classLevelQuestionAnalyze)"
    >
      <CQuestionAnalyze
        v-if="isActiveTab(TABS.corporation.classLevelQuestionAnalyze)"
        :variant="REPORT_VARIANTS.CLASS"
      ></CQuestionAnalyze>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelGainsTree)"
      @click="() => showTab(TABS.corporation.classLevelGainsTree)"
    >
      <GainsTree
        v-if="isActiveTab(TABS.corporation.classLevelGainsTree)"
        :variant="REPORT_VARIANTS.CLASS"
      ></GainsTree>
    </b-tab>
    <b-tab
      title="Gelişim İzleme"
      v-if="isVariant(REPORT_VARIANTS.CLASS)"
      :active="isActiveTab(TABS.corporation.classLevelComparison)"
      @click="() => showTab(TABS.corporation.classLevelComparison)"
    >
      <Comparison
        v-if="isActiveTab(TABS.corporation.classLevelComparison)"
        :variant="REPORT_VARIANTS.CLASS"
      ></Comparison>
    </b-tab>
    <b-tab
      title="Ön Karne"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.corpLevelFront)"
      @click="() => showTab(TABS.corporation.corpLevelFront)"
    >
      <CFront
        v-if="isActiveTab(TABS.corporation.corpLevelFront)"
        :variant="REPORT_VARIANTS.CORP"
      ></CFront>
    </b-tab>
    <b-tab
      title="Soru Analizi"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.corpLevelQuestionAnalyze)"
      @click="() => showTab(TABS.corporation.corpLevelQuestionAnalyze)"
    >
      <CQuestionAnalyze
        v-if="isActiveTab(TABS.corporation.corpLevelQuestionAnalyze)"
        :variant="REPORT_VARIANTS.CORP"
      ></CQuestionAnalyze>
    </b-tab>
    <b-tab
      title="Kazanım Ağacı"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.corpLevelGainsTree)"
      @click="() => showTab(TABS.corporation.corpLevelGainsTree)"
    >
      <GainsTree
        v-if="isActiveTab(TABS.corporation.corpLevelGainsTree)"
        :variant="REPORT_VARIANTS.CORP"
      ></GainsTree>
    </b-tab>
    <b-tab
      title="Periyodik Tablo"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.periodicTable)"
      @click="() => showTab(TABS.corporation.periodicTable)"
    >
      <PeriodicTable
        v-if="isActiveTab(TABS.corporation.periodicTable)"
        :variant="REPORT_VARIANTS.CORP"
      ></PeriodicTable>
    </b-tab>
    <b-tab
      title="Podyum (İlk 10)"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.podium10)"
      @click="() => showTab(TABS.corporation.podium10)"
    >
      <PodiumReport v-if="isActiveTab(TABS.corporation.podium10)" :items="10"></PodiumReport>
    </b-tab>
    <b-tab
      title="Podyum (İlk 3)"
      v-if="isVariant(REPORT_VARIANTS.CORP)"
      :active="isActiveTab(TABS.corporation.podium3)"
      @click="() => showTab(TABS.corporation.podium3)"
    >
      <PodiumReport v-if="isActiveTab(TABS.corporation.podium3)"></PodiumReport>
    </b-tab>
  </b-tabs>
</template>

<script>
import FollowUp from "@/components/reports/yks/student/follow-up.component";
import StudentGains from "@/components/reports/yks/student/gains.component";
import GainsTree from "@/components/reports/yks/common/gains-tree.component";
import Comparison from "@/components/reports/yks/common/comparison.component";
import CFront from "@/components/reports/yks/corporation/front-report.component";
import CQuestionAnalyze from "@/components/reports/yks/corporation/question-analyze.component";
import PeriodicTable from "@/components/reports/yks/corporation/periodic-table.component";
import PodiumReport from "@/components/reports/sbs/common/podium.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { REPORT_VARIANTS } from "@/common/config";

const TABS = {
  student: {
    followUp: "followUp",
    gains: "gains",
    gainsTree: "gainsTree",
    comparison: "comparison"
  },
  corporation: {
    classLevelFront: "classLevelFront",
    classLevelQuestionAnalyze: "classLevelQuestionAnalyze",
    classLevelGainsTree: "classLevelGainsTree",
    classLevelComparison: "classLevelComparison",
    corpLevelFront: "corpLevelFront",
    corpLevelQuestionAnalyze: "corpLevelQuestionAnalyze",
    corpLevelGainsTree: "corpLevelGainsTree",
    periodicTable: "periodicTable",
    podium3: "podium3",
    podium10: "podium10"
  }
};

export default {
  name: "yks-container",
  mixins: [FormStoreMixin],
  components: {
    FollowUp,
    StudentGains,
    GainsTree,
    Comparison,
    CFront,
    CQuestionAnalyze,
    PeriodicTable,
    PodiumReport
  },
  created() {
    this.resetTab();
  },
  watch: {
    formVariant() {
      setTimeout(this.resetTab, 100);
    }
  },
  data() {
    return {
      TABS,
      REPORT_VARIANTS,
      activeTab: TABS.student.followUp
    };
  },
  methods: {
    isVariant(variant) {
      return this.form.variant === variant;
    },
    resetTab() {
      const variant = this.formVariant;

      if (variant === REPORT_VARIANTS.STUDENT) {
        this.showTab(TABS.student.followUp);
      } else if (variant === REPORT_VARIANTS.CLASS) {
        this.showTab(TABS.corporation.classLevelFront);
      } else {
        this.showTab(TABS.corporation.corpLevelFront);
      }
    },
    showTab(tabId) {
      this.activeTab = tabId;
    },
    isActiveTab(tabId) {
      return tabId === this.activeTab;
    }
  },
  computed: {
    formVariant() {
      return this.form.variant;
    }
  }
};
</script>
