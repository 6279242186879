<template>
  <BaseReportTemplate
    :headerLeftTitle="leftTitle"
    :headerLeftSubTitle="leftSubTitle"
    :headerRightTitle="headerRightTitle"
  >
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col md="12" lg="3">
            <b-row>
              <b-col v-if="!doughnutModelsByLesson.length">
                <p>Derslere göre net bilgilerini görmek için sınav seçin.</p>
              </b-col>
              <b-col
                v-else
                sm="12"
                md="6"
                class="my-2 my-md-5"
                v-for="(doughnutModelInstance, i) of doughnutModelsByLesson"
                :key="i"
              >
                <DoughnutChart
                  :chart-data="doughnutModelInstance.chartData()"
                  :title="doughnutModelInstance.title"
                  :cutout-percentage="60"
                  :styles="{ height: '110px', position: 'relative' }"
                />
                <NetIcon
                  class="mt-3"
                  :corp-net="isVariant.corp ? doughnutModelInstance.studentNet : undefined"
                  :class-net="isVariant.class ? doughnutModelInstance.studentNet : undefined"
                  :average-net="doughnutModelInstance.averageNet"
                  :rank="doughnutModelInstance.rank"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" lg="4" class="border-left border-right">
            <template v-if="doughnutModelByGeneralData">
              <DoughnutChart
                :chart-data="doughnutModelByGeneralData.chartData()"
                :title="doughnutModelByGeneralData.title"
                :cutout-percentage="65"
                :inner-font-size="ptToPx(70)"
                :slice-font-size="22"
                :styles="{ height: '300px', position: 'relative' }"
              />

              <NetIcon
                class="mt-3"
                variant="large"
                :corp-net="isVariant.corp ? doughnutModelByGeneralData.studentNet : undefined"
                :class-net="isVariant.class ? doughnutModelByGeneralData.studentNet : undefined"
                :average-net="doughnutModelByGeneralData.averageNet"
                :rank="doughnutModelByGeneralData.rank"
              />
            </template>
            <template v-else>
              <p>Genel net bilgilerini görmek için sınav seçin</p>
            </template>
            <div class="d-flex justify-content-center">
              <Jumbotron class="mt-5" :background="false">
                <span class="general-info-bold text-uppercase">Dersler bazında tam yapan</span>
                <span class="text-uppercase general-info"> öğrenci sayıları</span>
                <table class="table table-responsive">
                  <tbody>
                    <tr v-for="(lesson, i) in allCorrect.byLesson" :key="i">
                      <td class="general-info border-right border-bottom text-uppercase pr-5">
                        {{ lesson.lesson }}
                      </td>
                      <td class="general-info-bold border-bottom text-center px-3">
                        {{ lesson.count }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span class="general-info-bold text-uppercase">TÜM DERSLERİ TAM YAPAN </span>
                <span class="text-uppercase general-info">
                  ÖĞRENCİ SAYISI:
                  <span class="general-info-bold general-info-count">{{ allCorrect.all }}</span>
                </span>
              </Jumbotron>
            </div>
          </b-col>
          <b-col md="12" lg="5">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-start pl-3 mt-4">
                  <Label
                    class="w-100"
                    style="height: 75px"
                    size="large"
                    color="orange"
                    left="PUAN ORTALAMASI"
                    :right="averageScore"
                  ></Label>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <div class="d-flex flex-column">
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Genel Katılım"
                    :right="attendanceAndCount.generalAttendance"
                    color="blue"
                    :icon-props="generalAttendanceIconProps"
                  ></Label>
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Kurum Sayısı"
                    :right="attendanceAndCount.corpCount"
                    color="blue"
                    :icon-props="corpCountIconProps"
                  ></Label>
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Sınıf Sayısı"
                    :right="attendanceAndCount.classCount"
                    color="blue"
                    :icon-props="classCountIconProps"
                  ></Label>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column">
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Kurum Katılım"
                    :right="attendanceAndCount.corpAttendance"
                    color="orange"
                    :icon-props="generalAttendanceIconProps"
                  ></Label>
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Kurum Sırası"
                    :right="attendanceAndCount.corpRank"
                    color="orange"
                    :icon-props="corpRankIconProps"
                  ></Label>
                  <Label
                    class="w-75 mx-auto mt-4"
                    left="Sınıf Katılım"
                    :right="attendanceAndCount.classAttendance"
                    color="orange"
                    :icon-props="classCountIconProps"
                  ></Label>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <hr class="w-100 my-5" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <BarChart
                  v-if="comparisonBarChartModel"
                  title="Karşılaştırma Grafiği (Net)"
                  :chart-data="comparisonBarChartModel.chartData()"
                  :styles="{ height: '320px', position: 'relative' }"
                ></BarChart>
                <p v-else>
                  Seçilen sınavdan önce sınav varsa karşılaştırma grafiği görüntülenir.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="my-5">
        <ProgressChart
          :percent="progressData.percent"
          :top="progressData.score"
          :variant="variant"
        ></ProgressChart>
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row class="align-items-center">
        <b-col cols="12" md="auto" class="mb-2">
          <TrueFalseLegend></TrueFalseLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <NetLegend :variant="variant"></NetLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <ProgressLegend :variant="variant"></ProgressLegend>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Label from "@/components/label.component.vue";
import studentWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/student-white.svg";
import schoolWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/school-white.svg";
import barsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/bars-white.svg";
import studentChairSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin.js";
import { REPORT_VARIANTS } from "@/common/config.js";
import FormStoreMixin from "@/components/mixins/form.mixin.js";
import SbsClassService from "@/common/services/api-parsers/sbs/class.service.js";
import SbsCorpService from "@/common/services/api-parsers/sbs/corp.service.js";
import { DoughnutChartModel, BarChartModel } from "@/common/models";

export default {
  name: "exam-report",
  mixins: [ptToPxMixin, FormStoreMixin],
  components: {
    BaseReportTemplate,
    BarChart,
    DoughnutChart,
    Jumbotron,
    Label,
    NetLegend,
    ProgressChart,
    ProgressLegend,
    NetIcon,
    TrueFalseLegend
  },
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.CORP,
      validator(value) {
        return [REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      allCorrect: {
        byLesson: [],
        all: 0
      },
      doughnutModelsByLesson: [],
      doughnutModelByGeneralData: null,
      averageScore: 0,
      attendanceAndCount: {
        generalAttendance: 0,
        classAttendance: 0,
        corpAttendance: 0,
        corpCount: 0,
        classCount: 0,
        corpRank: 0
      },
      progressData: {
        percent: 0,
        score: 0
      },
      comparisonBarChartModel: null,
      generalAttendanceIconProps: {
        src: studentWhiteSvg,
        height: 12
      },
      corpCountIconProps: {
        src: schoolWhiteSvg,
        height: 12
      },
      corpRankIconProps: {
        src: barsWhiteSvg,
        height: 12
      },
      classCountIconProps: {
        src: studentChairSvg,
        height: 12
      }
    };
  },
  watch: {
    exam() {
      this.makeRequests();
    }
  },
  created() {
    this.makeRequests();
  },
  methods: {
    renderDoughnutsByLesson(netsByLesson) {
      this.doughnutModelsByLesson = netsByLesson.map(lessonData => {
        return new DoughnutChartModel({
          title: lessonData.lesson,
          correct: lessonData.correct,
          wrong: lessonData.wrong,
          empty: lessonData.empty,
          correctnessPercent: lessonData.correctnessPercent,
          studentNet: lessonData.net,
          averageNet: lessonData.averageNet,
          rank: lessonData.rank
        });
      });
    },
    renderDoughnutByGeneralData(generalNetData) {
      this.doughnutModelByGeneralData = new DoughnutChartModel({
        title: "Genel Başarı",
        correct: generalNetData.correct,
        wrong: generalNetData.wrong,
        empty: generalNetData.empty,
        correctnessPercent: generalNetData.correctnessPercent,
        studentNet: generalNetData.net,
        averageNet: generalNetData.averageNet,
        rank: generalNetData.rank
      });
    },
    renderAverageScore(score) {
      this.averageScore = score;
    },
    renderAttendance({
      generalAttendance,
      classAttendance,
      corpAttendance,
      corpCount,
      classCount,
      corpRank
    }) {
      this.attendanceAndCount = {
        generalAttendance,
        classAttendance,
        corpAttendance,
        corpCount,
        classCount,
        corpRank
      };
    },
    renderProgress({ percent, score }) {
      this.progressData = { percent, score };
    },
    renderComparisonChart({ selectedExamByLesson, prevExamByLesson }) {
      const selectedExamFromForm = this.form.exams.find(exam => exam.ID_SINAVBILGI === this.exam);
      const chartData = selectedExamByLesson.map(selectedExam => {
        const prevExam = prevExamByLesson.find(pExam => pExam.lesson === selectedExam.lesson);
        return {
          title: selectedExam.lesson,
          dataset: [
            {
              label: selectedExamFromForm.AD,
              data: selectedExam.net
            },
            {
              label: prevExam.meta.SINAVAD,
              data: prevExam.net
            },
            {
              label: "Genel",
              data: selectedExam.averageNet
            }
          ]
        };
      });
      this.comparisonBarChartModel = new BarChartModel(chartData);
    },
    async makeRequests() {
      if (!this.form.exam) return;
      let {
        netsByLesson,
        generalNetData,
        averageScore,
        attendance,
        corpScore,
        corpScorePercent,
        prevExam,
        allCorrect
      } = {};

      if (this.variant === REPORT_VARIANTS.CLASS) {
        ({
          netsByLesson,
          generalNetData,
          averageScore,
          attendance,
          corpScore,
          corpScorePercent,
          prevExam,
          allCorrect
        } = await SbsClassService.frontExamReport({
          classId: this.form.class,
          examId: this.form.exam
        }));
      } else if (this.variant === REPORT_VARIANTS.CORP) {
        ({
          netsByLesson,
          generalNetData,
          averageScore,
          corpScore,
          corpScorePercent,
          attendance,
          prevExam,
          allCorrect
        } = await SbsCorpService.frontExamReport({
          branchId: this.form.branch,
          examId: this.form.exam
        }));
      }

      this.allCorrect = allCorrect;
      this.renderDoughnutsByLesson(netsByLesson);
      this.renderDoughnutByGeneralData(generalNetData);
      this.renderAverageScore(averageScore);
      this.renderAttendance({
        generalAttendance: attendance.generalAttendance,
        classAttendance: attendance.classAttendance,
        corpAttendance: attendance.corpAttendance,
        corpCount: attendance.corpCount,
        classCount: attendance.classCount,
        corpRank: attendance.corpRank
      });
      this.renderProgress({ percent: corpScorePercent, score: corpScore });

      if (prevExam.length) {
        this.renderComparisonChart({
          selectedExamByLesson: netsByLesson,
          prevExamByLesson: prevExam
        });
      }
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    isVariant() {
      return {
        corp: this.variant === REPORT_VARIANTS.CORP,
        class: this.variant === REPORT_VARIANTS.CLASS
      };
    },
    leftTitle() {
      return this.form.selected.branch.AD;
    },
    leftSubTitle() {
      const exam = this.form.selected.exam.AD;
      const _class = this.form.selected.class.AD;
      const info = [_class, exam];
      if (this.variant === REPORT_VARIANTS.CLASS) {
        info.push(this.form.selected.class.DERSLIK);
      }
      return info.join(" / ");
    },
    headerRightTitle() {
      return this.variant === REPORT_VARIANTS.CORP
        ? "Kurumsal Sınav Raporu"
        : "Sınıfsal Sınav Raporu";
    }
  }
};
</script>

<style scoped>
.general-info,
.general-info-bold {
  font-size: 1.1rem;
}

.general-info-bold {
  font-family: GothamCondensedBold;
}

.general-info {
  font-family: GothamCondensedBook;
}

.general-info-count {
  font-size: 1.4rem;
}
</style>
