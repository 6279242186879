<template>
  <b-tabs class="kt-hide-tabs">
    <template v-if="isVariant(REPORT_VARIANTS.STUDENT)">
      <b-tab
        title="Başarı İzleme Karnesi"
        :active="isActiveTab(TABS.student.followUp)"
        @click="() => showTab(TABS.student.followUp)"
      >
        <FollowUp v-if="isActiveTab(TABS.student.followUp)"></FollowUp>
      </b-tab>
      <b-tab
        title="Kazanımlar Karnesi"
        :active="isActiveTab(TABS.student.gains)"
        @click="() => showTab(TABS.student.gains)"
      >
        <Gains v-if="isActiveTab(TABS.student.gains)"></Gains>
      </b-tab>
      <b-tab
        title="Kazanım Ağacı"
        :active="isActiveTab(TABS.student.gainsTree)"
        @click="() => showTab(TABS.student.gainsTree)"
      >
        <GainsTree
          v-if="isActiveTab(TABS.student.gainsTree)"
          :variant="REPORT_VARIANTS.STUDENT"
        ></GainsTree>
      </b-tab>
      <b-tab
        title="Gelişim İzleme"
        :active="isActiveTab(TABS.student.comparison)"
        @click="() => showTab(TABS.student.comparison)"
      >
        <Comparison
          v-if="isActiveTab(TABS.student.comparison)"
          :variant="REPORT_VARIANTS.STUDENT"
        ></Comparison>
      </b-tab>
    </template>
    <template v-if="isVariant(REPORT_VARIANTS.CLASS)">
      <b-tab
        title="Ön Karne"
        :active="isActiveTab(TABS.corporation.classLevelFront)"
        @click="() => showTab(TABS.corporation.classLevelFront)"
      >
        <CFrontReport
          v-if="isActiveTab(TABS.corporation.classLevelFront)"
          :variant="REPORT_VARIANTS.CLASS"
        ></CFrontReport>
      </b-tab>
      <b-tab
        title="Soru Analizi"
        :active="isActiveTab(TABS.corporation.classLevelQuestionAnalyze)"
        @click="() => showTab(TABS.corporation.classLevelQuestionAnalyze)"
      >
        <CQuestionAnalyze
          v-if="isActiveTab(TABS.corporation.classLevelQuestionAnalyze)"
          :variant="REPORT_VARIANTS.CLASS"
        ></CQuestionAnalyze>
      </b-tab>
      <b-tab
        title="Kazanım Ağacı"
        :active="isActiveTab(TABS.corporation.classLevelGainsTree)"
        @click="() => showTab(TABS.corporation.classLevelGainsTree)"
      >
        <GainsTree
          v-if="isActiveTab(TABS.corporation.classLevelGainsTree)"
          :variant="REPORT_VARIANTS.CLASS"
        ></GainsTree>
      </b-tab>
      <b-tab
        title="Gelişim İzleme"
        :active="isActiveTab(TABS.corporation.classLevelComparison)"
        @click="() => showTab(TABS.corporation.classLevelComparison)"
      >
        <Comparison
          v-if="isActiveTab(TABS.corporation.classLevelComparison)"
          :variant="REPORT_VARIANTS.CLASS"
        ></Comparison>
      </b-tab>
    </template>
    <template v-if="isVariant(REPORT_VARIANTS.CORP)">
      <b-tab
        title="Ön Karne"
        :active="isActiveTab(TABS.corporation.corpLevelFront)"
        @click="() => showTab(TABS.corporation.corpLevelFront)"
      >
        <CFrontReport
          v-if="isActiveTab(TABS.corporation.corpLevelFront)"
          :variant="REPORT_VARIANTS.CORP"
        ></CFrontReport>
      </b-tab>
      <b-tab
        title="Soru Analizi"
        :active="isActiveTab(TABS.corporation.corpLevelQuestionAnalyze)"
        @click="() => showTab(TABS.corporation.corpLevelQuestionAnalyze)"
      >
        <CQuestionAnalyze
          v-if="isActiveTab(TABS.corporation.corpLevelQuestionAnalyze)"
          :variant="REPORT_VARIANTS.CORP"
        ></CQuestionAnalyze>
      </b-tab>
    </template>
  </b-tabs>
</template>

<script>
import FollowUp from "@/components/reports/tyt/student/follow-up.component";
import Gains from "@/components/reports/tyt/student/gains.component";
import Comparison from "@/components/reports/tyt/common/comparison.component";
import GainsTree from "@/components/reports/gains/gains-tree";
import CFrontReport from "@/components/reports/tyt/corporation/front-report.component";
import CQuestionAnalyze from "@/components/reports/tyt/corporation/question-analyze.component";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { REPORT_VARIANTS } from "@/common/config";

const TABS = {
  student: {
    followUp: "followUp",
    gains: "gains",
    gainsTree: "gainsTree",
    comparison: "comparison"
  },
  corporation: {
    classLevelFront: "classLevelFront",
    classLevelQuestionAnalyze: "classLevelQuestionAnalyze",
    classLevelGainsTree: "classLevelGainsTree",
    classLevelComparison: "classLevelComparison",
    corpLevelFront: "corpLevelFront",
    corpLevelQuestionAnalyze: "corpLevelQuestionAnalyze",
    corpLevelGainsTree: "corpLevelGainsTree",
    periodicTable: "periodicTable",
    podium3: "podium3",
    podium10: "podium10"
  }
};

export default {
  name: "tyt-container",
  mixins: [FormStoreMixin],
  components: {
    FollowUp,
    Gains,
    GainsTree,
    Comparison,
    CFrontReport,
    CQuestionAnalyze
  },
  created() {
    this.resetTab();
  },
  watch: {
    formVariant() {
      setTimeout(this.resetTab, 100);
    }
  },
  data() {
    return {
      TABS,
      REPORT_VARIANTS,
      activeTab: TABS.student.followUp
    };
  },
  methods: {
    isVariant(variant) {
      return this.form.variant === variant;
    },
    resetTab() {
      const variant = this.formVariant;

      if (variant === REPORT_VARIANTS.STUDENT) {
        this.showTab(TABS.student.followUp);
      } else if (variant === REPORT_VARIANTS.CLASS) {
        this.showTab(TABS.corporation.classLevelFront);
      } else {
        this.showTab(TABS.corporation.corpLevelFront);
      }
    },
    showTab(tabId) {
      this.activeTab = tabId;
    },
    isActiveTab(tabId) {
      return tabId === this.activeTab;
    }
  },
  computed: {
    formVariant() {
      return this.form.variant;
    }
  }
};
</script>
