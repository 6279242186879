<template>
  <div
    class="rounded-10 p-2 d-flex justify-content-center align-items-center text-center"
    :style="outerContainerStyle"
  >
    <div
      class="d-flex border rounded-10 align-items-end text-uppercase"
      :style="innerContainerStyle"
      ref="innerContainerRef"
    >
      <span ref="schoolContainerRef" class="mx-auto">{{ school }}</span>
    </div>
    <div class="d-flex justify-content-center rounded-10" :style="rankStyle">
      {{ rank }}
    </div>
    <div class="text-uppercase align-items-center justify-content-center" :style="nameStyle">
      <svg :style="svgStyle">
        <text
          dominant-baseline="mathematical"
          text-anchor="middle"
          alignment-baseline="middle"
          fill="white"
          font-size="2.2em"
        >
          <tspan x="50%" text-anchor="middle" dy="1em" v-for="(n, i) in fullName" :key="i">
            {{ n }}
          </tspan>
        </text>
      </svg>
    </div>
    <div :style="scoreStyle">{{ score }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "podium-item",
  props: {
    rank: {
      type: [Number, String],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    school: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      schoolContainerHeight: 0,
      innerContainerRef: 0,
      svgHeight: this.height / 2.5
    };
  },
  mounted() {
    setTimeout(() => {
      this.schoolContainerHeight = this.$refs.schoolContainerRef.getBoundingClientRect().height;
      this.innerContainerRef = this.$refs.innerContainerRef.getBoundingClientRect().height;
    }, 500);
  },
  computed: {
    ...mapGetters(["theme"]),
    fullName() {
      return this.name.split(" ");
    },
    svgStyle() {
      return {
        height: this.svgHeight,
        width: "90%",
        textAlign: "center"
      };
    },
    outerContainerStyle() {
      return {
        position: "relative",
        backgroundColor: this.theme.colors.BLUE,
        height: `${this.height}px`,
        width: `${this.width}px`
      };
    },
    innerContainerStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        height: "95%",
        width: "95%",
        color: this.theme.colors.WHITE,
        fontSize: "1.2rem",
        lineHeight: 1,
        fontFamily: "GothamCondensedBook"
      };
    },
    rankStyle() {
      return {
        position: "absolute",
        top: "-35px",
        width: "40%",
        backgroundColor: this.theme.colors.LIGHT_BLUE,
        border: `2px solid ${this.theme.colors.WHITE}`,
        padding: "5px 25px",
        color: this.theme.colors.WHITE,
        fontSize: "6rem",
        lineHeight: 1,
        fontFamily: "GothamCondensedBold"
      };
    },
    scoreStyle() {
      let { schoolContainerHeight } = this;
      schoolContainerHeight += 20;
      return {
        position: "absolute",
        bottom: `${schoolContainerHeight}px`,
        width: "100%",
        padding: "5px 0",
        backgroundColor: this.theme.colors.LIGHT_BLUE,
        color: this.theme.colors.WHITE,
        fontSize: "3rem",
        lineHeight: 1,
        fontFamily: "GothamCondensedBold"
      };
    },
    nameStyle() {
      let { schoolContainerHeight } = this;
      schoolContainerHeight += 75;
      return {
        color: this.theme.colors.WHITE,
        position: "absolute",
        bottom: `${schoolContainerHeight}px`,
        lineHeight: 1,
        fontFamily: "GothamCondensedBold"
      };
    }
  }
};
</script>

<style scoped>
.rounded-10 {
  border-radius: 10px;
}
</style>
