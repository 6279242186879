<template>
  <BaseReportTemplate
    :headerLeftTitle="leftTitle"
    :headerLeftSubTitle="leftSubTitle"
    headerRightTitle="Periyodik Doğru Tablosu"
  >
    <b-row>
      <b-col cols="12" lg="10">
        <b-row>
          <b-col cols="12" class="my-3">
            <div class="d-flex flex-row">
              <div class="flex-grow-1" :style="headerStyle">Dersler bazında doğru sayıları</div>
              <div>
                <Label
                  style="width: 170px"
                  color="orange"
                  left="Öğrenci Sayısı"
                  :right="attendance"
                ></Label>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <PeriodicTableByLesson
              :question-limit="30"
              :jumbo-col-span="15"
              :jumbo-item-index="1"
              :jumbo-lesson-index="0"
              :items="periodicTableByLesson"
            ></PeriodicTableByLesson>
          </b-col>
          <b-col cols="12">
            <div class="d-flex flex-row">
              <div :style="headerStyle" class="mr-3">toplam bazda doğru sayıları</div>
              <div class="form-group form-inline">
                <button
                  :class="categoryBtnClass(YKS_PERIODIC_TABLE_CATEGORIES.SCIENCE)"
                  @click="() => onSelectCategory(YKS_PERIODIC_TABLE_CATEGORIES.SCIENCE)"
                >
                  SAYISAL
                </button>
                <button
                  :class="categoryBtnClass(YKS_PERIODIC_TABLE_CATEGORIES.SOCIAL)"
                  @click="() => onSelectCategory(YKS_PERIODIC_TABLE_CATEGORIES.SOCIAL)"
                >
                  SÖZEL
                </button>
                <button
                  :class="categoryBtnClass(YKS_PERIODIC_TABLE_CATEGORIES.EW)"
                  @click="() => onSelectCategory(YKS_PERIODIC_TABLE_CATEGORIES.EW)"
                >
                  EŞİT A.
                </button>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <PeriodicTableByTotal :items="periodicTableByTotal"></PeriodicTableByTotal>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="2" class="border-left">
        <div :style="headerStyle">hızlı liste alma</div>
        <div class="d-flex flex-column">
          <template v-for="(filter, i) in filters">
            <div class="form-check form-check-inline" :key="i">
              <input
                class="form-check-input mr-1"
                type="radio"
                name="levelFilter"
                @change="() => {}"
                :id="i"
              />
              <label class="form-check-label" :for="i" :style="theme.styles.radioLabel">
                {{ filter.text }}
              </label>
            </div>
          </template>
        </div>
        <div class="form-group align-self-center mt-4">
          <label class="mr-2">ARALIK</label>
          <input type="text" class="mb-2 form-control form-control-sm" />
          <input type="text" class="form-control form-control-sm" />
        </div>
        <ListButton></ListButton>
      </b-col>
    </b-row>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Label from "@/components/label.component.vue";
import PeriodicTableByLesson from "@/components/charts/periodic-table-by-lesson.component.vue";
import PeriodicTableByTotal from "@/components/charts/periodic-table-by-total.component.vue";
import ListButton from "@/components/buttons/periodic-table-list.component.vue";
import YksCorpService from "@/common/services/api-parsers/yks/corp.service";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { YKS_PERIODIC_TABLE_CATEGORIES } from "@/common/config";

export default {
  name: "yks-periodic-table",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    Label,
    PeriodicTableByLesson,
    PeriodicTableByTotal,
    ListButton
  },
  data() {
    return {
      YKS_PERIODIC_TABLE_CATEGORIES,
      attendance: 0,
      periodicTableByLesson: [],
      periodicTableByTotal: [],
      selectedCategory: YKS_PERIODIC_TABLE_CATEGORIES.SCIENCE,
      filters: [
        { text: "Türk Dil. ve Ede.", value: "1" },
        { text: "Tarih-1", value: "2" },
        { text: "Tarih-2", value: "3" },
        { text: "Coğrafya-1", value: "4" },
        { text: "Coğrafya-2", value: "5" },
        { text: "Felsefe", value: "6" },
        { text: "Din Kül.", value: "7" },
        { text: "Matematik", value: "8" },
        { text: "Geometri", value: "9" },
        { text: "Fizik", value: "9" },
        { text: "Kimya", value: "9" },
        { text: "Biyoloji", value: "10" }
      ],
      selectedFilter: null
    };
  },
  created() {
    this.makeRequests();
  },
  watch: {
    exam() {
      this.makeRequests();
    }
  },
  methods: {
    async makeRequests() {
      if (!this.exam) return;

      const { byLesson, byTotal, studentAttendance } = await YksCorpService.periodicTable({
        branchId: this.form.branch,
        examId: this.exam,
        category: this.selectedCategory
      });

      this.periodicTableByLesson = byLesson;
      this.periodicTableByTotal = byTotal;
      this.attendance = studentAttendance;
    },
    onSelectCategory(category) {
      this.selectedCategory = category;
      this.makeRequests();
    },
    categoryBtnClass(category) {
      const classList = ["btn", "btn-default"];
      if (category === this.selectedCategory) {
        classList.push("active");
      }
      return classList;
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    leftTitle() {
      return this.form.selected.branch.AD;
    },
    leftSubTitle() {
      return this.form.selected.exam.AD;
    },
    headerStyle() {
      return {
        color: this.theme.colors.GRAY,
        fontSize: "21px",
        fontFamily: "GothamNarrowBook",
        textTransform: "uppercase"
      };
    }
  }
};
</script>
