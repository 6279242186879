<template>
  <BaseReportTemplate
    :headerLeftTitle="leftTitle"
    :headerLeftSubTitle="leftSubTitle"
    headerRightTitle="AYT Raporu"
  >
    <b-row>
      <b-col cols="12" md="4" class="border-right">
        <b-row>
          <b-col v-if="!mainLessonCharts.length">
            <p>Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.</p>
          </b-col>
          <b-col cols="12" md="6" v-else v-for="(chart, i) of mainLessonCharts" :key="i">
            <DoughnutChart
              :chart-data="chart.chartData()"
              :title="chart.title"
              :cutout-percentage="60"
              :styles="{ height: '130px', position: 'relative' }"
              :title-style="{ 'min-height': '80px' }"
            />
            <NetIcon
              class="mt-2"
              :type="EXAM_TYPES.YKS"
              :corp-net="variant === REPORT_VARIANTS.CORP ? chart.studentNet : undefined"
              :class-net="variant === REPORT_VARIANTS.CLASS ? chart.studentNet : undefined"
              :average-net="chart.averageNet"
              :rank="chart.rank"
            />
          </b-col>
          <b-col cols="12">
            <p v-if="!netsByLesson.length">
              Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.
            </p>
            <div class="table-responsive" v-else>
              <table class="table table-bordered table-sm table-lesson">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" class="ss">SORU<br>SAYISI</th>
                    <th scope="col">
                      <fa icon="square" :style="{ color: theme.colors.BLUE }"></fa>
                    </th>
                    <th scope="col">
                      <fa icon="square" :style="{ color: theme.colors.RED }"></fa>
                    </th>
                    <th scope="col">
                      <fa icon="square" :style="{ color: theme.colors.GRAY }"></fa>
                    </th>
                    <th scope="col">
                      <Icon
                        :src="
                          variant === REPORT_VARIANTS.CLASS
                            ? studentChairOrangeSvg
                            : schoolOrangeSvg
                        "
                        :height="25"
                      ></Icon>
                    </th>
                    <th scope="col">
                      <Icon :src="studentsSvg" :height="25"></Icon>
                    </th>
                    <th scope="col">
                      <Icon :src="barsSvg" :height="25"></Icon>
                    </th>
                    <th scope="col">
                      <!-- TODO: asset eksik olduğu için fa ikon eklendi -->
                      <fa icon="ribbon"></fa>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lesson, i) of netsByLesson" :key="i">
                    <th scope="row">{{ lesson.lesson }}</th>
                    <td>{{ lesson.total }}</td>
                    <td>{{ lesson.correct }}</td>
                    <td>{{ lesson.wrong }}</td>
                    <td>{{ lesson.empty }}</td>
                    <td>
                      <strong>{{ lesson.net }}</strong>
                    </td>
                    <td>
                      <strong>{{ lesson.averageNet }}</strong>
                    </td>
                    <td>
                      <strong>{{ lesson.rank }}</strong>
                    </td>
                    <td>
                      <strong>{{ lesson.allCorrect }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="3" class="border-right">
        <div class="d-flex justify-content-between flex-column h-100" v-if="netsByCategory.length">
          <div v-for="(chart, i) of netsByCategory" :key="i">
            <hr class="w-90" v-if="i > 0" />
            <DoughnutChart
              :chart-data="chart.chartData()"
              :title="chart.title"
              :cutout-percentage="60"
              :styles="{ height: '200px', position: 'relative' }"
            />
            <NetIcon
              class="mt-3"
              variant="medium"
              :type="EXAM_TYPES.YKS"
              :class-net="variant === REPORT_VARIANTS.CLASS ? chart.studentNet : undefined"
              :corp-net="variant === REPORT_VARIANTS.CORP ? chart.studentNet : undefined"
              :average-net="chart.averageNet"
              :rank="chart.rank"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="5">
        <b-row class="mb-3">
          <b-col>
            <div class="h-100 d-flex flex-column justify-content-center">
              <Label
                style="width: 180px"
                class="mb-2"
                v-for="(attendance, i) in attendances"
                :key="`att_${i}`"
                :color="i % 2 === 0 ? 'blue' : 'orange'"
                :left="attendance.title"
                :left-style="{ fontSize: '11px' }"
                :right="attendance.right"
                :right-style="{ fontSize: '11px' }"
                :icon-props="attendance.iconProps"
              ></Label>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex flex-column align-items-end">
              <template v-for="(score, i) in scoresAndRanks">
                <Label
                  style="width: 200px"
                  class="mb-1"
                  size="large"
                  :key="`score_${i}`"
                  :color="i % 2 === 0 ? 'orange' : 'blue'"
                  :left="score.title"
                  :right="score.score"
                  :right-style="{ fontSize: '14px' }"
                ></Label>
                <Label
                  style="width: 170px"
                  class="mb-3"
                  :key="`rank_${i}`"
                  :color="i % 2 === 0 ? 'blue' : 'orange'"
                  :left="variant === REPORT_VARIANTS.CLASS ? 'Sınıf Sırası' : 'Kurum Sırası'"
                  :left-style="{ fontSize: '13px' }"
                  :right="score.rank"
                  :right-style="{ fontSize: '11px' }"
                  :icon-props="{ src: barsWhiteSvg, height: 10 }"
                ></Label>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!prevExamBarChart">
            <p>Seçilen sınavdan önce kıyaslanacak sınav varsa kıyaslama grafiği görüntülenir.</p>
          </b-col>
          <b-col v-else>
            <BarChart
              :max="40"
              title="Karşılaştırma Grafiği (Net)"
              :chart-data="prevExamBarChart.chartData()"
              :styles="{ height: '500px', position: 'relative' }"
            ></BarChart>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <ProgressChart
          :type="EXAM_TYPES.YKS"
          :yks-percents="yksPercents"
          :variant="variant"
        ></ProgressChart>
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row class="align-items-center">
        <b-col cols="12" md="auto" class="mb-2">
          <TrueFalseLegend :type="EXAM_TYPES.YKS"></TrueFalseLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <NetLegend :variant="variant" :type="EXAM_TYPES.YKS"></NetLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <ProgressLegend :type="EXAM_TYPES.YKS" :variant="variant"></ProgressLegend>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import FormStoreMixin from "@/components/mixins/form.mixin";
import YksClassService from "@/common/services/api-parsers/yks/class.service";
import YksCorpService from "@/common/services/api-parsers/yks/corp.service";
import { DoughnutChartModel, BarChartModel } from "@/common/models";
import studentChairOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair-orange.svg";
import studentChairSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";
import studentWhite from "@/assets/media/icons/svg/Karnem/lgs/student-white.svg";
import barsSvg from "@/assets/media/icons/svg/Karnem/lgs/bars.svg";
import schoolWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/school-white.svg";
import schoolOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/school-orange.svg";
import barsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/bars-white.svg";
import Icon from "@/components/icons/icon.component";
import Label from "@/components/label.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import { EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";

export default {
  name: "yks-front",
  mixins: [FormStoreMixin],
  components: {
    BaseReportTemplate,
    DoughnutChart,
    NetIcon,
    Icon,
    Label,
    BarChart,
    ProgressChart,
    TrueFalseLegend,
    NetLegend,
    ProgressLegend
  },
  props: {
    variant: {
      type: String,
      default: REPORT_VARIANTS.CORP,
      validator(value) {
        return [REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS].indexOf(value) !== -1;
      }
    }
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      REPORT_VARIANTS,
      studentChairOrangeSvg,
      studentChairSvg,
      studentsSvg,
      barsSvg,
      barsWhiteSvg,
      studentWhite,
      schoolWhiteSvg,
      schoolOrangeSvg,
      yksPercents: [],
      examName: "",
      sessionOne: "",
      mainLessonCharts: [],
      netsByLesson: [],
      scoresAndRanks: [],
      attendances: [],
      netsByCategory: [],
      netComparison: null,
      prevExamBarChart: null
    };
  },
  methods: {
    async makeRequests() {
      const { exam, class: classId, branch } = this.form;
      if (!exam) return;

      let {
        mainNetsByLesson,
        netsByLesson,
        netsByCategory,
        percents,
        attendances,
        counts,
        scoresAndRanks,
        prevExam
      } = {};

      if (this.variant === REPORT_VARIANTS.CLASS) {
        ({
          mainNetsByLesson,
          netsByLesson,
          netsByCategory,
          percents,
          attendances,
          counts,
          scoresAndRanks,
          prevExam
        } = await YksClassService.frontReport({
          examId: exam,
          classId: classId
        }));
      } else if (this.variant === REPORT_VARIANTS.CORP) {
        ({
          mainNetsByLesson,
          netsByLesson,
          netsByCategory,
          attendances,
          counts,
          scoresAndRanks,
          percents,
          prevExam
        } = await YksCorpService.frontReport({
          examId: exam,
          branchId: branch
        }));
      }

      this.renderMainLessonCharts(mainNetsByLesson);
      this.renderNetsByCategory(netsByCategory);
      this.renderAttendances(attendances, counts);
      this.renderPrevExamChart(mainNetsByLesson, prevExam);
      this.scoresAndRanks = scoresAndRanks;
      this.netsByLesson = netsByLesson;
      this.yksPercents = percents;
    },
    renderMainLessonCharts(mainLessons) {
      this.mainLessonCharts = mainLessons.map(lesson => {
        return new DoughnutChartModel({
          title: lesson.lesson,
          correct: lesson.correct,
          wrong: lesson.wrong,
          empty: lesson.empty,
          correctnessPercent: lesson.correctnessPercent,
          studentNet: lesson.net,
          averageNet: lesson.averageNet,
          rank: lesson.rank
        });
      });
    },
    renderPrevExamChart(currentExamMainNetsByLesson, prevExamMainNetsByLesson) {
      if (!prevExamMainNetsByLesson) {
        this.prevExamBarChart = null;
        return;
      }

      const chartData = currentExamMainNetsByLesson.map(cExam => {
        const prevExam = prevExamMainNetsByLesson.find(pExam => pExam.lesson === cExam.lesson);
        let title = "";
        switch (cExam.lesson) {
          case "Türk Dili ve Edebiyatı Sosyal Bilimler - 1":
            title = "Türkçe";
            break;
          case "Sosyal Bilimler-2":
            title = "Sosyal Bilgiler";
            break;
          default:
            title = cExam.lesson;
        }
        return {
          title: title,
          dataset: [
            {
              label: this.variant === REPORT_VARIANTS.CLASS ? cExam.meta.AD : cExam.meta.SINAVAD,
              data: cExam.net
            },
            {
              label: prevExam.meta.SINAVAD,
              data: prevExam.net
            },
            {
              label: "Genel",
              data: cExam.averageNet
            }
          ]
        };
      });
      this.prevExamBarChart = new BarChartModel(chartData);
    },
    renderNetsByCategory(netsByCategory) {
      this.netsByCategory = netsByCategory.map(netByCategory => {
        const {
          category: title,
          correct,
          wrong,
          empty,
          correctnessPercent,
          net,
          averageNet,
          rank
        } = netByCategory;
        return new DoughnutChartModel({
          title,
          correct,
          wrong,
          empty,
          correctnessPercent,
          studentNet: net,
          averageNet,
          rank
        });
      });
    },
    renderAttendances(attendances, counts) {
      const iconHeight = 13;
      this.attendances = [
        {
          title: "Sınıf Sayısı",
          right: counts.class,
          iconProps: {
            src: studentChairSvg,
            height: iconHeight
          }
        },
        {
          title: "Sınıf Katılım",
          right: attendances.class,
          iconProps: {
            src: studentChairSvg,
            height: iconHeight
          }
        },
        {
          title: "Kurum Sayısı",
          right: counts.corp,
          iconProps: {
            src: schoolWhiteSvg,
            height: iconHeight
          }
        },
        {
          title: "Kurum Katılım",
          right: attendances.corp,
          iconProps: {
            src: studentWhite,
            height: iconHeight
          }
        },
        {
          title: "Genel Katılım",
          right: attendances.general,
          iconProps: {
            src: studentWhite,
            height: iconHeight
          }
        }
      ];
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    leftTitle() {
      return this.form.selected.branch.AD;
    },
    leftSubTitle() {
      const exam = this.form.selected.exam.AD;
      const _class = this.form.selected.class.AD;
      const info = [_class, exam];
      if (this.variant === REPORT_VARIANTS.CLASS) {
        info.push(this.form.selected.class.DERSLIK);
      }
      return info.join(" / ");
    }
  }
};
</script>

<style scoped>
td,
thead th {
  text-align: center;
  vertical-align: middle;
}

tbody th {
  border-right: 0;
  border-left: 0;
}

thead th:not(:first-child) {
  border: 0;
  border-left: 1px solid #ebedf2;
}

thead th:first-child {
  border: 0;
  border-bottom: 1px solid #ebedf2;
}
</style>
