<template>
  <div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <td :style="primaryTableHeadStyle" colspan="2">
              doğru sayısı
            </td>
            <td :style="primaryTableHeadStyle" v-for="(question, i) in totalQuestions" :key="i">
              {{ i }}
            </td>
            <td style="font-size: 0.6rem" :style="secondaryTableHeadStyle">
              kurum doğru ortalaması
            </td>
            <td style="font-size: 0.6rem" :style="secondaryTableHeadStyle">
              genel doğru ortalaması
            </td>
            <td style="font-size: 0.6rem" :style="secondaryTableHeadStyle">
              tAMAMINI BOŞ BIRAKAN
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, itemIndex) in items">
            <tr :key="`row_${itemIndex}`">
              <td :style="primaryTableHeadStyle" :rowspan="calculateRowSpanForType(itemIndex)">
                {{ item.type }}
              </td>
            </tr>
            <tr
              v-for="(lessonObj, lessonIndex) in getLessonList(itemIndex)"
              :key="`lesson_${lessonIndex}_${itemIndex}`"
            >
              <td
                style="width: 120px; display: inline-block; overflow: hidden; font-size: 0.8rem"
                :style="secondaryTableHeadStyle"
                class="text-left"
              >
                {{ lessonObj.lesson }}
              </td>
              <td
                v-for="(n, numberIndex) in lessonObj.numbers"
                :key="`number_${numberIndex}_${itemIndex}`"
                :style="getBgColorByPercentage(numberIndex, lessonObj.numbers)"
              >
                {{ n !== undefined ? n : "" }}
              </td>
              <td
                v-if="isTipVisible(itemIndex, lessonIndex)"
                :rowspan="jumboRowSpan"
                :colspan="jumboColSpan"
              >
                <div class="d-flex align-items-center">
                  <Jumbotron
                    class="text-left w-100 m-0"
                    style="text-transform: none"
                    :background="false"
                  >
                    Periyodik doğru tablosunda, öğrenci sayısı üzerine geldiğinizde hangi öğrenci
                    olduğunu görebilirsiniz. Seçtiğiniz grupları listeleyebilirsiniz.
                  </Jumbotron>
                </div>
              </td>
              <td :style="secondaryTableHeadStyle">{{ lessonObj.corpAverage }}</td>
              <td :style="secondaryTableHeadStyle">{{ lessonObj.generalAverage }}</td>
              <td :style="secondaryTableHeadStyle">{{ lessonObj.empty }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <ListButton></ListButton>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chroma from "chroma-js";

import { getColorForPercentage } from "@/common/utils";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import ListButton from "@/components/buttons/periodic-table-list.component.vue";

export default {
  name: "periodic-table-by-lesson",
  props: {
    items: {
      type: Array,
      required: true
    },
    questionLimit: {
      type: Number,
      default: 20
    },
    jumboColSpan: {
      type: Number,
      default: 10
    },
    jumboItemIndex: {
      // Hangi row grup içinde gösterileceği. Örn;
      // sbs için sözel bölüm içinde gözükecek çünkü 0. item indexi sözel
      type: Number,
      default: 0
    },
    jumboLessonIndex: {
      // Row grup içinde hangi dersten sonra gösterileceği. Örn;
      // sbs için türkçe sütunları tamamlandıktan sonra gözükecek.
      // türkçe'nin indexi 0. sosyal bilgiler 1. sosyal bilgilerle aynı satırda gösterilmeye başlayacak.
      type: Number,
      default: 1
    }
  },
  components: {
    Jumbotron,
    ListButton
  },
  data() {
    return {
      selected: "PDF",
      options: [{ value: "PDF", text: "PDF" }]
    };
  },
  methods: {
    getLessonList(itemIndex) {
      const { lessons } = this.items[itemIndex];
      return lessons;
    },
    isTipVisible(itemIndex, lessonIndex) {
      return itemIndex === this.jumboItemIndex && lessonIndex === this.jumboLessonIndex;
    },
    calculateRowSpanForType(itemIndex) {
      const lessons = this.getLessonList(itemIndex);
      return lessons.length + 1;
    },
    getBgColorByPercentage(i, numbers) {
      const number = numbers[i];
      if (number === undefined) {
        return { backgroundColor: this.theme.colors.GRAY_WHITE };
      }

      let percentage = (i * 100) / numbers.length / 100;
      if (percentage >= 0.45 && percentage <= 0.6) {
        percentage = 0.4;
      }
      const backgroundColor = getColorForPercentage(percentage);
      return {
        backgroundColor: chroma(backgroundColor).set("lch.c", 50),
        color: this.theme.colors.WHITE
      };
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    jumboRowSpan() {
      const lessons = this.getLessonList(this.jumboItemIndex);
      return lessons.length;
    },
    totalQuestions() {
      return Array(this.questionLimit + 1).fill(1);
    },
    primaryTableHeadStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        color: this.theme.colors.WHITE
      };
    },
    secondaryTableHeadStyle() {
      return {
        backgroundColor: this.theme.colors.GRAY_WHITE,
        color: this.theme.colors.GRAY
      };
    },
    buttonStyle() {
      return {
        backgroundColor: this.theme.colors.BLUE,
        color: this.theme.colors.WHITE,
        textTransform: "uppercase",
        padding: "1rem 2rem",
        fontFamily: "GothamNarrowBold"
      };
    }
  }
};
</script>

<style scoped>
td {
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 7px;
}
table {
  border-spacing: 3px;
  border-collapse: separate;
  text-transform: uppercase;
  font-family: GothamNarrowBold;
}
</style>
