<template>
  <BaseReportTemplate
    :headerLeftTitle="leftTitle"
    :headerLeftSubTitle="leftSubTitle"
    headerRightTitle="Sınıfsal TYT Raporu"
  >
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col md="6" lg="4">
            <b-row v-if="mainNetsByLesson.length">
              <b-col
                sm="12"
                md="6"
                class="mb-2 mb-md-5"
                v-for="(result, i) of mainNetsByLesson"
                :key="i"
              >
                <DoughnutChart
                  :chart-data="result.chartData()"
                  :title="result.title"
                  :cutout-percentage="60"
                  :styles="{ height: '130px', position: 'relative' }"
                />
                <NetIcon
                  class="mt-2"
                  :type="EXAM_TYPES.TYT"
                  :class-net="result.studentNet"
                  :average-net="result.averageNet"
                  :rank="result.rank"
                />
              </b-col>
            </b-row>
            <b-row v-else>
              <h4 class="px-3">
                Derslere ait netlerin listelenmesi için yukarıdaki formdan sınav seçimi yapmalısnız
              </h4>
            </b-row>
            <b-row>
              <b-col cols="12">
                <p v-if="!netsByLesson.length">
                  Ders seçimi yaptıktan sonra derslere ait netleri görebilirsiniz.
                </p>
                <div class="table-responsive" v-else>
                  <table class="table table-bordered table-sm table-lesson text-center">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" class="ss">SORU<br>SAYISI</th>
                        <th scope="col">
                          <fa icon="square" :style="{ color: theme.colors.RED }"></fa>
                        </th>
                        <th scope="col">
                          <fa icon="square" :style="{ color: theme.colors.RED }"></fa>
                        </th>
                        <th scope="col">
                          <fa icon="square" :style="{ color: theme.colors.GRAY }"></fa>
                        </th>
                        <th scope="col">
                          <Icon
                            :src="
                              variant === REPORT_VARIANTS.CLASS
                                ? studentChairOrangeSvg
                                : schoolOrangeSvg
                            "
                            :height="25"
                          ></Icon>
                        </th>
                        <th scope="col">
                          <Icon :src="studentsSvg" :height="25"></Icon>
                        </th>
                        <th scope="col">
                          <Icon :src="barsSvg" :height="25"></Icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(lesson, i) of netsByLesson" :key="i">
                        <th scope="row" class="text-left">{{ lesson.lesson }}</th>
                        <td>{{ lesson.total }}</td>
                        <td>{{ lesson.correct }}</td>
                        <td>{{ lesson.wrong }}</td>
                        <td>{{ lesson.empty }}</td>
                        <td>
                          <strong>{{ lesson.net }}</strong>
                        </td>
                        <td>
                          <strong>{{ lesson.averageNet }}</strong>
                        </td>
                        <td>
                          <strong>{{ lesson.rank }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" lg="4" class="border-left border-right">
            <p v-if="!generalDoughnutModel">Önce sınav seçimi yapın</p>
            <template v-else>
              <DoughnutChart
                :chart-data="generalDoughnutModel.chartData()"
                :title="generalDoughnutModel.title"
                :cutout-percentage="65"
                :inner-font-size="ptToPx(70)"
                :slice-font-size="22"
                :styles="{ height: '300px', position: 'relative' }"
              />
              <NetIcon
                class="mt-5"
                variant="large"
                :type="EXAM_TYPES.TYT"
                :class-net="
                  variant === REPORT_VARIANTS.CLASS ? generalDoughnutModel.studentNet : undefined
                "
                :corp-net="
                  variant === REPORT_VARIANTS.CORP ? generalDoughnutModel.studentNet : undefined
                "
                :average-net="generalDoughnutModel.averageNet"
                :rank="generalDoughnutModel.rank"
              />
              <div class="d-flex justify-content-center">
                <Jumbotron class="mt-5" :background="false">
                  <span class="general-info-bold text-uppercase">Dersler bazında tam yapan</span>
                  <span class="text-uppercase general-info"> öğrenci sayıları</span>
                  <b-row cols="2">
                    <b-col v-for="(lessons, i) in allCorrect.lessons" :key="`c_${i}`">
                      <table class="table table-responsive table-sm">
                        <tbody>
                          <tr v-for="(lesson, i) in lessons" :key="i">
                            <td class="general-info border-right border-bottom text-uppercase">
                              {{ lesson.lesson }}
                            </td>
                            <td class="general-info-bold border-bottom text-center px-3">
                              {{ lesson.count }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                  <span class="general-info-bold text-uppercase">TÜM DERSLERİ TAM YAPAN </span>
                  <span class="text-uppercase general-info">
                    ÖĞRENCİ SAYISI:
                    <span class="general-info-bold general-info-count">{{ allCorrect.all }}</span>
                  </span>
                </Jumbotron>
              </div>
            </template>
          </b-col>
          <b-col cols="12" class="d-none d-md-block d-lg-none">
            <hr class="w-90 mb-5 mt-3" />
          </b-col>
          <b-col md="12" lg="4" class="px-5">
            <b-row>
              <b-col md="6" lg="12">
                <b-row>
                  <b-col>
                    <Label
                      class="mb-3 w-100"
                      :style="styles.labels.scores.container"
                      size="large"
                      color="orange"
                      left="PUAN ORTALAMASI"
                      left-position="center"
                      :left-style="styles.labels.scores.text"
                      :right="score"
                      :right-style="styles.labels.scores.text"
                      right-position="center"
                    ></Label>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="6">
                    <div class="d-flex flex-column">
                      <Label
                        v-for="(attendance, i) in attendances"
                        :key="`att_${i}`"
                        :class="attendance.class"
                        :left="attendance.left"
                        :left-style="attendance.leftStyle"
                        :right="attendance.right"
                        :right-style="attendance.rightStyle"
                        :right-position="attendance.rightPosition"
                        :color="attendance.color"
                        :icon-props="attendance.iconProps"
                      ></Label>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="d-flex flex-column">
                      <Label
                        v-for="(rank, i) in ranks"
                        :key="`rank_${i}`"
                        :class="rank.class"
                        :left="rank.left"
                        :left-style="rank.leftStyle"
                        :right="rank.right"
                        :right-style="rank.rightStyle"
                        :right-position="rank.rightPosition"
                        :color="rank.color"
                        :icon-props="rank.iconProps"
                      ></Label>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" lg="12">
                <b-row class="d-none d-lg-block d-xl-none">
                  <b-col>
                    <hr class="w-90 mt-3" />
                  </b-col>
                </b-row>
                <b-row class="h-100">
                  <template v-if="prevExamBarModel">
                    <b-col class="h-100 d-flex align-items-end">
                      <BarChart
                        title="Karşılaştırma Grafiği (Net)"
                        :chart-data="prevExamBarModel.chartData()"
                        :styles="styles.comparisonChart"
                      ></BarChart>
                    </b-col>
                  </template>
                  <p v-else>
                    Önceki sınav bilgisi varsa karşılaştırma grafiği görüntülenir.
                  </p>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="my-5">
        <ProgressChart
          :type="EXAM_TYPES.TYT"
          :variant="variant"
          :percent="percent"
          :top="score"
        ></ProgressChart>
      </b-col>
    </b-row>
    <template slot="legend">
      <b-row class="align-items-center">
        <b-col cols="12" md="auto" class="mb-2">
          <TrueFalseLegend></TrueFalseLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <NetLegend :type="EXAM_TYPES.TYT"></NetLegend>
        </b-col>
        <b-col cols="12" md="auto" class="mb-2">
          <ProgressLegend :variant="variant" :type="EXAM_TYPES.TYT"></ProgressLegend>
        </b-col>
      </b-row>
    </template>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import * as _ from "lodash";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Jumbotron from "@/components/reports/sbs/jumbotron.component.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import BarChart from "@/components/charts/bar.component.vue";
import ProgressChart from "@/components/charts/progress.component.vue";
import TrueFalseLegend from "@/components/legends/true-false.component.vue";
import NetLegend from "@/components/legends/net.component.vue";
import ProgressLegend from "@/components/legends/progress.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Icon from "@/components/icons/icon.component.vue";
import Label from "@/components/label.component.vue";
import printerSvg from "@/assets/media/icons/svg/Karnem/lgs/printer.svg";
import bookSvg from "@/assets/media/icons/svg/Karnem/lgs/book.svg";
import checkCircleSvg from "@/assets/media/icons/svg/Karnem/lgs/check-circle.svg";
import studentChairSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair.svg";
import studentChairOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair-orange.svg";
import schoolWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/school-white.svg";
import schoolOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/school-orange.svg";
import studentWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/student-white.svg";
import barsWhiteSvg from "@/assets/media/icons/svg/Karnem/lgs/bars-white.svg";
import barsSvg from "@/assets/media/icons/svg/Karnem/lgs/bars.svg";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { DoughnutChartModel, BarChartModel } from "@/common/models";
import TytClassService from "@/common/services/api-parsers/tyt/class.service";
import TytCorpService from "@/common/services/api-parsers/tyt/corp.service";
import { byDeviceWidth } from "@/common/utils";
import { EXAM_TYPES, REPORT_VARIANTS } from "@/common/config";
import studentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";

export default {
  name: "tyt-front",
  mixins: [ptToPxMixin, FormStoreMixin],
  props: {
    variant: {
      type: String,
      default: "student",
      validator(value) {
        const variants = [REPORT_VARIANTS.STUDENT, REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS];
        return variants.indexOf(value) !== -1;
      }
    }
  },
  components: {
    BaseReportTemplate,
    BarChart,
    DoughnutChart,
    Jumbotron,
    Label,
    NetLegend,
    ProgressChart,
    ProgressLegend,
    NetIcon,
    TrueFalseLegend,
    Icon
  },
  watch: {
    async exam() {
      await this.makeRequests();
    }
  },
  async created() {
    await this.makeRequests();
  },
  data() {
    return {
      EXAM_TYPES,
      REPORT_VARIANTS,
      studentSvg,
      studentsSvg,
      printerSvg,
      studentChairSvg,
      studentChairOrangeSvg,
      barsSvg,
      schoolOrangeSvg,
      netsByLesson: [],
      mainNetsByLesson: [],
      allCorrect: { lessons: [], all: 0 },
      score: 0,
      percent: 1,
      attendances: [],
      ranks: [],
      examName: "",
      session: "",
      prevExamBarModel: null,
      generalDoughnutModel: null
    };
  },
  methods: {
    renderMainNetsByLesson(mainNetsByLesson) {
      this.mainNetsByLesson = mainNetsByLesson.map(data => {
        return new DoughnutChartModel({
          title: data.lesson,
          correct: data.correct,
          wrong: data.wrong,
          empty: data.empty,
          correctnessPercent: data.correctnessPercent,
          studentNet: data.net,
          averageNet: data.averageNet,
          rank: data.rank
        });
      });
    },
    renderGeneralData(generalData) {
      this.generalDoughnutModel = new DoughnutChartModel({
        title: "Genel Başarı",
        correct: generalData.correct,
        wrong: generalData.wrong,
        empty: generalData.empty,
        studentNet: generalData.net,
        averageNet: generalData.averageNet,
        correctnessPercent: generalData.correctnessPercent,
        rank: generalData.rank
      });
    },
    renderAttendance(attendance) {
      const commonProps = {
        class: "my-2",
        left: "",
        leftStyle: this.styles.labels.common.font,
        right: "",
        rightStyle: this.styles.labels.attendance.rightText,
        rightPosition: "right",
        color: "blue",
        iconProps: null
      };

      this.attendances = [
        {
          ...commonProps,
          left: "Genel Katılım",
          right: attendance.general,
          iconProps: this.iconProps.generalAttendance
        },
        {
          ...commonProps,
          left: "Kurum Sayısı",
          right: attendance.corp,
          iconProps: this.iconProps.corpAttendance
        },
        {
          ...commonProps,
          left: "Sınıf Sayısı",
          right: attendance.class,
          iconProps: this.iconProps.classAttendance
        }
      ];
    },
    renderRank(rank) {
      const commonProps = {
        class: "my-2",
        left: "",
        leftStyle: this.styles.labels.common.font,
        right: "",
        rightStyle: this.styles.labels.attendance.rightText,
        rightPosition: "right",
        color: "orange",
        iconProps: this.iconProps.ranks
      };

      this.ranks = [
        { ...commonProps, left: "Kurum Katılım", right: rank.corpAttendance },
        { ...commonProps, left: "Kurum Sırası", right: rank.corpRank },
        { ...commonProps, left: "Sınıf Katılım", right: rank.classAttendance }
      ];
    },
    renderNetComparison(netsByLesson, prevExamByLesson) {
      if (!prevExamByLesson) return;

      const barData = netsByLesson.map(selectedExamResult => {
        const prevExamResult = prevExamByLesson.find(
          lesson => lesson.lesson === selectedExamResult.lesson
        );

        return {
          title: selectedExamResult.lesson,
          dataset: [
            {
              label: selectedExamResult.meta.SINAVADI,
              data: selectedExamResult.net
            },
            {
              label: prevExamResult.meta.SINAVAD,
              data: prevExamResult.net
            },
            {
              label: "Genel",
              data: selectedExamResult.averageNet
            }
          ]
        };
      });
      this.prevExamBarModel = new BarChartModel(barData);
    },
    renderAllCorrect(allCorrectData) {
      const [left, right] = _.chunk(allCorrectData.lessons, 5);
      this.allCorrect = {
        lessons: [left, right],
        all: allCorrectData.all
      };
    },
    async makeRequests() {
      const { exam, class: classId, branch } = this.form;
      if (!exam) {
        return;
      }

      let {
        mainNetsByLesson,
        generalNet,
        netsByLesson,
        allCorrect,
        score,
        attendances,
        ranks,
        percent,
        prevExam
      } = {};

      if (this.variant === REPORT_VARIANTS.CLASS) {
        ({
          mainNetsByLesson,
          generalNet,
          netsByLesson,
          allCorrect,
          score,
          attendances,
          ranks,
          percent,
          prevExam
        } = await TytClassService.frontReport({
          examId: exam,
          classId: classId
        }));
      } else if (this.variant === REPORT_VARIANTS.CORP) {
        ({
          mainNetsByLesson,
          generalNet,
          netsByLesson,
          allCorrect,
          score,
          attendances,
          ranks,
          percent,
          prevExam
        } = await TytCorpService.frontReport({
          examId: exam,
          branchId: branch
        }));
      }

      this.renderMainNetsByLesson(mainNetsByLesson);
      this.renderGeneralData(generalNet);
      this.netsByLesson = netsByLesson;
      this.score = score;
      this.percent = percent;
      this.renderAllCorrect(allCorrect);
      this.renderAttendance(attendances);
      this.renderRank(ranks);
      this.renderNetComparison(mainNetsByLesson, prevExam);
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    exam() {
      return this.form.exam;
    },
    leftTitle() {
      return this.form.selected.branch.AD;
    },
    leftSubTitle() {
      const exam = this.form.selected.exam.AD;
      const _class = this.form.selected.class.AD;
      const info = [_class, exam];
      if (this.variant === REPORT_VARIANTS.CLASS) {
        info.push(this.form.selected.class.DERSLIK);
      }
      return info.join(" / ");
    },
    styles() {
      return {
        labels: {
          common: {
            font: byDeviceWidth(
              {
                desktop: {
                  fontSize: "9px"
                }
              },
              "desktop"
            )
          },
          examName: byDeviceWidth({
            desktop: {
              width: "200px"
            },
            tablet: {
              width: "170px"
            }
          }),
          session: byDeviceWidth({
            desktop: {
              width: "160px"
            },
            tablet: {
              width: "130px"
            }
          }),
          attendance: {
            rightText: byDeviceWidth({
              desktop: {
                fontSize: "13px"
              }
            })
          },
          scores: {
            container: byDeviceWidth({
              desktop: {
                height: "55px"
              }
            }),
            text: byDeviceWidth({
              desktop: {
                fontSize: "15px"
              },
              tablet: {
                fontSize: "14px"
              }
            })
          }
        },
        comparisonChart: byDeviceWidth({
          desktop: {
            height: "320px",
            width: "330px",
            position: "relative"
          },
          tablet: {
            height: "360px",
            width: "320px",
            position: "relative"
          }
        }),
        printIcon: byDeviceWidth({
          tablet: 30
        })
      };
    },
    iconProps() {
      const heights = {
        desktop: 15
      };
      return {
        examName: byDeviceWidth(
          {
            desktop: {
              src: bookSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        session: byDeviceWidth(
          {
            desktop: {
              src: checkCircleSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        classAttendance: byDeviceWidth(
          {
            desktop: {
              src: studentChairSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        corpAttendance: byDeviceWidth(
          {
            desktop: {
              src: schoolWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        generalAttendance: byDeviceWidth(
          {
            desktop: {
              src: studentWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        ),
        ranks: byDeviceWidth(
          {
            desktop: {
              src: barsWhiteSvg,
              height: heights.desktop
            }
          },
          "desktop"
        )
      };
    }
  }
};
</script>

<style scoped>
.general-info,
.general-info-bold {
  font-size: 1.1rem;
}

.general-info-bold {
  font-family: GothamCondensedBold;
}

.general-info {
  font-family: GothamCondensedBook;
}

.general-info-count {
  font-size: 1.4rem;
}
</style>
