<template>
  <div class="d-flex flex-row">
    <div class="d-flex flex-row align-items-center mr-2" v-for="(item, i) in items" :key="i">
      <div :style="item.boxStyle" class="rounded"></div>
      <span :style="item.textStyle" class="ml-2">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "true-false",
  props: {
    variant: {
      type: String,
      default: "true-false",
      validator(value) {
        return ["true-false", "comparison"].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    items() {
      let colors = ["#005db9", "#de1c24", "#808184"];
      let texts;
      if (this.variant === "true-false") {
        if (this.$store.getters.form.corp == 1) {
          colors = ["#01b7cd", "#ed008c", "#808184"];
        } else if (this.$store.getters.form.corp == 3) {
          colors = ["#8cc63f", "#faa21a", "#808184"];
        }
        texts = ["DOĞRU", "YANLIŞ", "BOŞ"];
      } else {
        colors = this.theme.charts.comparisonBar.colors;
        texts = ["Öğrenciye ait değerler", "Genele ait değerler"];
      }

      const style = {
        width: "17px",
        height: "17px"
      };
      return colors.map((color, i) => {
        return {
          text: texts[i],
          textStyle: {
            color,
            fontFamily: "GothamCondensedBook",
            fontSize: "7pt",
            textTransform: "uppercase",
            lineHeight: 1
          },
          boxStyle: { ...style, backgroundColor: color }
        };
      });
    }
  }
};
</script>
